// assets
import { Dashboard, Assignment, Work, AssignmentInd, Paid } from '@mui/icons-material';

// constant
const icons = {
    Assignment,
    Dashboard,
    Work,
    AssignmentInd,
    Paid
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pagesInvestor = {
    id: 'pages',
    title: '',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/inversor/dashboard',
            icon: icons.Dashboard,
            breadcrumbs: false
        },
        {
            id: 'cuentas',
            title: 'Cuentas',
            type: 'item',
            url: '/inversor/cuentas',
            icon: icons.Assignment,
        },
        {
            id: 'retiros',
            title: 'Historial de retiros',
            type: 'item',
            url: '/inversor/retiros',
            icon: icons.Assignment,
        },
        {
            id: 'inventario',
            title: 'Inventario de inversiones',
            type: 'item',
            url: '/backoffice/inventario',
            icon: icons.Assignment,
        }
        
        
    ]
};

export default pagesInvestor;
