import React, { useState, useEffect } from 'react';
import { Typography, Select, MenuItem, SelectChangeEvent, TextField, Box } from '@mui/material';
import { AccountService } from 'business/AccountService';

interface PeriodsByScheduleProps {
    id: number;
    period: string;
    onSelectPeriod: (period: string) => void;
}

const PeriodsBySchedule: React.FC<PeriodsByScheduleProps> = ({ id, period, onSelectPeriod }) => {
    const [periods, setPeriods] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        AccountService.getInstance().getPeriodsBySchedule({ id })
            .then(periods => {
                setPeriods(periods);
                setError(null);
            })
            .catch(error => {
                console.error("Error al obtener fechas:", error);
                setError("Hubo un error al obtener los fechas.");
            });
    }, [id]);
    
    
    //const handlePeriodSelect = (event: SelectChangeEvent<string>) => {
    //    onSelectPeriod(event.target.value); 
    //    console.log(event.target.value);
    //};

    const handlePeriodSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        onSelectPeriod(event.target.value as string); 
        console.log(event.target.value);
    };

    return (
        <>
          {error && <Typography color="error">{error}</Typography>}
            <TextField
                select
                InputLabelProps={{ shrink: true }} 
                label="Periodo"
                name="periodo"
                value={period}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                onChange={handlePeriodSelect}
                fullWidth
                className="advance"
            >
                <option value="" disabled>
                    Seleccione un periodo
                </option>
                {periods && periods.map((period: any, index: number) => ( 
                    <option key={`${period.id}-${period.name}`} value={period.name}>
                        {period.name}
                    </option>
                ))}
            </TextField>        
        </>
    );
    
};

export default PeriodsBySchedule;
