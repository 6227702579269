
import { CookieTwoTone } from '@mui/icons-material';
import color from './colors';
const components = {
   
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: '4px',
                    
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: color.grey900,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: color.darkTextPrimary,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&.Mui-selected': {
                        color: color.secondaryDark,
                        backgroundColor: color.secondaryLight,
                        '&:hover': {
                            backgroundColor: color.secondaryLight
                        },
                        '& .MuiListItemIcon-root': {
                            color: color.secondaryDark
                        }
                    },
                    '&:hover': {
                        backgroundColor: color.secondaryLight,
                        color: color.secondaryDark,
                        '& .MuiListItemIcon-root': {
                            color: color.secondaryDark
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: color.darkTextPrimary,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: color.grey900
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: '#000',
                    '&::placeholder': {
                        color: color.darkTextSecondary,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: color.grey300
                    }
                },
                mark: {
                    backgroundColor: color.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: color.primaryLight
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: color.grey200,
                    opacity: 1
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: color.primaryDark,
                    background: color.primary200
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: color.paper,
                    background: color.grey700
                }
            }
        }
    };

export default components;