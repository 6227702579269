import { NumberLiteralType } from "typescript";

export interface Data {
    idCommission: number;
    client:string;
    product: string;
    dischargeDate: string;
    amount: NumberLiteralType;
    paymentDate: string;
    commission: number;
  }
  
 export function createData(
    idCommission: number,
    client:string,
    product: string,
    dischargeDate: string,
    amount: NumberLiteralType,
    paymentDate: string,
    commission: number
  ): Data {
    return {
      idCommission,
      client,
      product,
      dischargeDate,
      amount,
      paymentDate,
      commission
    };
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  interface BodyCell {
    id: string;
  }

  interface Identity{
    id: string
  }

  interface Action {
    disable: boolean;
    type: string;
    tooltip: string;
  }
  
  export const headCells: readonly HeadCell[] = [
    {
      id: 'client',
      numeric: false,
      disablePadding: false,
      label: 'Cliente',
    },
    {
      id: 'product',
      numeric: false,
      disablePadding: false,
      label: 'Producto',
    },
    {
      id: 'dischargeDate',
      numeric: false,
      disablePadding: false,
      label: 'Fecha de alta',
    },
    {
      id: 'amount',
      numeric: false,
      disablePadding: false,
      label: 'Monto USD',
    },
    {
      id: 'paymentDate',
      numeric: false,
      disablePadding: false,
      label: 'Fecha de pago',
    },
    {
      id: 'commission',
      numeric: false,
      disablePadding: false,
      label: 'Comisión USD',
    }
  ];

  export const bodyCells: readonly BodyCell[] = [
    {
      id: 'client',
    },
    {
      id: 'product',
    },
    {
      id: 'dischargeDate',
    },
    {
      id: 'amount',
    },
    {
      id: 'paymentDate',
    },
    {
      id: 'commission',
    }
  ];

  export const identity: Identity = 
    {
      id: 'idCommission',
    }
  ;

  

  export const actions: readonly Action[] = [
    
  ];

  
  