
import MainLayout from "../../../../src/layout/MainLayout";
import CustomizedTables from "../../../../src/components/CustomizedTables";
import {identity, headCells, bodyCells, actions} from "../../../../src/components/data/tramites";
import React, { useState, useEffect } from 'react';
import ProcedureMenu from "../../../../src/components/tramites/ProcedureMenu";
import {ProcedureService} from '../../../../src/business/ProcedureService';
import {ProductService} from '../../../../src/business/ProductService';

import moment from 'moment';
import Cookies from 'universal-cookie';

const statusList = [
  {
    id: '1',
    name: 'En proceso',
  },
  {
    id: '2',
    name: 'En revisión',
  }
];
const cookies = new Cookies();

const Tramites = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [xlsData, setXlsData] = useState([]);
  
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);

  let linkTramites = '';
  let canEdit = false;

  let role=cookies.get('rol');
  let idStatus = 1;
    switch(role)
    {
        case '1': linkTramites='/backoffice/tramites/';
              canEdit = true;
              idStatus = 2;
            break;
        case '2': linkTramites='/asesores/tramites/';
              canEdit = true;
              idStatus = 1;
            break;
    }
    const [procedureStatus, setProcedureStatus] = useState(idStatus);

    const getProductsList = async () => { 
    
      const productInstance =  ProductService.getInstance();
                          
      await productInstance.getProductsList()
      .then((data:any) => {
          if(data){
            setProducts(data);
          }   
          
      })
      .catch((err) => {
        console.log(err);
      });
    }
  
  const getTramites = async () => { 
    
    const tramiteInstance = ProcedureService.getInstance();
    setLoading(true);
                        
    await tramiteInstance.getProcedures({idStatus:procedureStatus})
    .then((data:any) => {
        if(data){
          data = data.map((row:any) => ({...row, createdAt: moment(row.createdAt).format("DD/MM/YYYY HH:mm")}))
          let  clonexlsData = data.map((col:any, index:number) => { return {
            "Nro.": index + 1,
            "Trámite": col.code,
            "Inversionista": col.investor,
            "Producto": col.product,
            "Estado": col.status,
            "Asesor": col.consultant,
            "Fecha de actualización":col.createdAt
          }});
          setXlsData(clonexlsData);
          setData(data);
          setLoading(false);
        }   
        
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }

  const insertProcedure = async (id:number, idAccount:number) => { 
    
    const insertInstance =  ProcedureService.getInstance();
                        
    await insertInstance.insertProcedure({idProduct: id, idAccount: idAccount})
    .then((data:any) => {
        if(data){
          window.location.href=linkTramites+data;
        }   
        
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const deleteProcedure = async (ids:any) => { 
    
    const deleteInstance =  ProcedureService.getInstance();
                        
    await deleteInstance.deleteProcedures(ids)
    .then((data:any) => {
        getTramites();
        
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    getTramites();
  }, [procedureStatus]);

  useEffect(() => {
    getProductsList();
  },[])

  const handleCreateProcedure = (id:number, idAccount:number) => {
    insertProcedure(id, idAccount);
  };

  const handleDelete = () => {
    if(selected){
      deleteProcedure(selected);
      setSelected([]);
    }
    
  }; 

  const handleSearch= (event: any) => {
    const { value} = event.target;
    setPage(0);
    setProcedureStatus(parseInt(value));
   
    
  };

  const handleSelected = (ids: any) => {
    setSelected(ids);
  }

  

  return (
    <MainLayout>
      
      <ProcedureMenu canEdit={canEdit} statusList={statusList} idStatus={idStatus} handleSearch={handleSearch} handleCreateProcedure={handleCreateProcedure} 
        products={products}/>
      <br></br>
      <CustomizedTables rows={data} 
        heads={headCells} 
        bodyCells={bodyCells} 
        identity={identity} 
        actions={actions} 
        editLink={linkTramites} 
        canEdit={canEdit}
        selected={selected}
        handleDelete={handleDelete}
        handleSelected={handleSelected}
        apiData={xlsData} 
        fileName='Tramites'
        export={true}
        multiselect={true}
        loading={loading}
        />
        
  
    </MainLayout>
  )
}



export default Tramites
