import color from './colors';
const typography = {
        fontFamily: "'DM Sans', sans-serif",
        h6: {
            fontWeight: 500,
            color: color.grey900,
            fontSize: '0.75rem'
        },
        h5: {
            fontSize: '0.875rem',
            color: '#000',
            fontWeight: 500
        },
        h4: {
            fontSize: '1rem',
            color: '#000',
            fontWeight: 600
        },
        h3: {
            fontSize: '1.25rem',
            color: '#000',
            fontWeight: 600
        },
        h2: {
            fontSize: '1.5rem',
            color: color.grey900,
            fontWeight: 700
        },
        h1: {
            fontSize: '2.125rem',
            color: color.grey900,
            fontWeight: 700
        },
        dark: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: '#000'
        },
        standar:{
            color: '#000'
        },
        darkh3: {
            fontSize: '1.25rem',
            color: '#000',
            fontWeight: 600
        },
        subtitle1: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: color.grey900
        },
        subtitle2: {
            fontSize: '0.75rem',
            fontWeight: 400,
            color: color.grey500
        },
        caption: {
            fontSize: '0.75rem',
            color: color.grey500,
            fontWeight: 400
        },
        body1: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.334em'
        },
        body2: {
            letterSpacing: '0em',
            fontWeight: 400,
            lineHeight: '1.5em',
            color: color.grey700
        },
        button: {
            textTransform: 'capitalize'
        },
        customInput: {
            marginTop: 1,
            marginBottom: 1,
            '& > label': {
                top: 23,
                left: 0,
                color: color.grey500,
                '&[data-shrink="false"]': {
                    top: 5
                }
            },
            '& > div > input': {
                padding: '30.5px 14px 11.5px !important'
            },
            '& legend': {
                display: 'none'
            },
            '& fieldset': {
                top: 0
            }
        },
        mainContent: {
            backgroundColor: color.primaryLight,
            width: '100%',
            minHeight: 'calc(100vh - 88px)',
            flexGrow: 1,
            padding: '20px',
            marginTop: '88px',
            marginRight: '20px',
            borderRadius: '15px'
        },
        menuCaption: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: color.grey900,
            padding: '6px',
            textTransform: 'capitalize',
            marginTop: '10px'
        },
        subMenuCaption: {
            fontSize: '0.6875rem',
            fontWeight: 500,
            color: color.grey500,
            textTransform: 'capitalize'
        },
        commonAvatar: {
            cursor: 'pointer',
            borderRadius: '8px'
        },
        smallAvatar: {
            width: '22px',
            height: '22px',
            fontSize: '1rem'
        },
        mediumAvatar: {
            width: '34px',
            height: '34px',
            fontSize: '1.2rem'
        },
        largeAvatar: {
            width: '44px',
            height: '44px',
            fontSize: '1.5rem'
        }
    };

export default typography;
