import React from 'react';
import { Link } from "react-router-dom";
import '../../src/App.css';
import {
  Divider, 
  Grid, 
  Stack, 
  Typography, 
  useMediaQuery
} from '@mui/material';

// material-ui
import { useTheme } from '@mui/material/styles';




/**
 * Login Screen Component
 * @packageDocumentation
 * @module Index
 */
const Index: React.FC<{}> = () => {
  
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  
  return  (
    <div className="App">
      <header className="App-header">
       
        <p>
         Sitio web en construcción
        </p>
       
      </header>
    </div>
  );
}


export default Index;
