import React, { useState, useEffect, useRef } from 'react';
import EditIcon from '@mui/icons-material/Edit';


import {
    TextField,
    FormControlLabel,
    FormControl,
    Box,
    Button,
    Typography,
    FormGroup,
    Checkbox,
    Divider,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableBody,
    Tooltip,
    Select
} from '@mui/material';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import parsers from '../../utils/parsers';
import ScheduleForm from "./ScheduleForm";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { PortableWifiOff } from '@mui/icons-material';
import CertificateComponent from 'components/certificate/CertificateComponent';

const currencyList = [
    {
        id: '',
        name: ''
    },
    {
      id: '1',
      name: 'Nuevo sol (S/)'
    },
    {
      id: '2',
      name: 'Dólares USD ($)'
    }
  ];

  const frecuencyList = [
    {
        id: '',
        name: ''
    },
    {
      id: '1',
      name: 'Mensual'
    },
    {
      id: '2',
      name: 'Trimestral'
    },
    {
      id: '3',
      name: 'Semestral'
    },
    {
      id: '4',
      name: 'Anual'
    }
  ];

  const endYearsList = [
    {
        id: '',
        name: ''
    },
    {
      id: '5',
      name: '5 años'
    },
    {
      id: '6',
      name: '6 años'
    },
    {
        id: '7',
        name: '7 años'
    },
    {
        id: '8',
        name: '8 años'
    },
    {
        id: '9',
        name: '9 años'
    },
    {
        id: '10',
        name: '10 años'
    },
    {
        id: '11',
        name: '11 años'
    },
  ];
  
  const days = [
    { id: '', name: ''},
    { id: '1', name: '1'},{ id: '2', name: '2'},
    { id: '3', name: '3'},{ id: '4', name: '4'},
    { id: '5', name: '5'},{ id: '6', name: '6'},
    { id: '7', name: '7'},{ id: '8', name: '8'},
    { id: '9', name: '9'},{ id: '10', name: '10'},
    { id: '11', name: '11'},{ id: '12', name: '12'},
    { id: '13', name: '13'},{ id: '14', name: '14'},
    { id: '15', name: '15'},{ id: '16', name: '16'},
    { id: '17', name: '17'},{ id: '18', name: '18'},
    { id: '19', name: '19'},{ id: '20', name: '20'},
    { id: '21', name: '21'},{ id: '22', name: '22'},
    { id: '23', name: '23'},{ id: '24', name: '24'},
    { id: '25', name: '25'},{ id: '26', name: '26'},
    { id: '27', name: '27'},{ id: '28', name: '28'},
    { id: '29', name: '29'},{ id: '30', name: '30'},
    { id: '31', name: '31'},
   
  ];

  const periodos = [
    {
        id: '1',
        name: '2022'
    },
    {
        id: '2',
        name: '2023'
    },
    {
        id: '3',
        name: '2024'
    },
    {
        id: '4',
        name: '2025'
    },
    {
        id: '5',
        name: '2026'
    },
    {
        id: '6',
        name: '2027'
    },
    {
        id: '7',
        name: '2028'
    },
    {
        id: '8',
        name: '2029'
    },
    {
        id: '9',
        name: '2030'
    }
  ]

export interface FinantialFormProps {
    data: {
        investorNames: string;
        product: string;
        code: string;
        finantialContribution:{
            idContributionCurrency: number;
            contributionCurrency: string;
            amount: number;
            administrativeCost: number;
            idFrecuency: number;
            idSerialNumber: number;
            serialNumber: string;
            dueDate: string;
            endYears: number;
            coupon: number;
            paymentDay: number;
            idInvestorType: number;
            investorType: string;
            originWealth1: boolean;
            originWealth2: boolean;
            originWealth3: boolean;
            originWealth4: boolean;
            originWealth5: boolean;
            originWealth6: boolean;
            originWealth7: boolean;
            originWealth8: boolean;
            originWealth9: boolean;
            originWealth10: boolean;
            originWealth11: boolean;
            specification:string;
            idBank: number;
            bank: string;
            cci: string;
            bankAccountNumber: string;
            bankAccountTitular: string;
            bankAccountComments: string;
            depositDate: string;
            investmentDate: string;
            approxPaymentDay: number;
            generated: boolean;
        }
    };

    paramsForm: {
        idProcedure: number;
        editable: boolean;
        rol:number;
      };

    handleChange: any;
    handleClose: any;
    handleClickShedule: any;
    scheduleList: any;
    handleChangePayment: any;
    handleSaveShedule: any;
    handleDownloadPdf: any;
    open: boolean;
    scheduleProcedure: any;
    
    
  }

  const StyledTableCell = styled(TableCell)(({ theme} :any ) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const formatMoney = (amount:any) => {
    if(amount!=null && amount!=undefined)
    {
      return amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else{
      return 0;
    }
    
  }

  const FinantialForm = (props: FinantialFormProps) => {
    const { paramsForm} = props;

    let finantial = props.data.finantialContribution;
    
    const isFormDisabled = () =>
        !paramsForm.editable ;
    const disableForm = isFormDisabled();   
    
    return (
      <>
        <Box
                sx={{
                    display: 'grid',
                    columnGap: 3,
                    rowGap: 1,
                    gridTemplateColumns: 'repeat(3, 1fr)',
                }}
                >
             
            <TextField
                label="Aporte USD"
                size="small"
                variant="standard"
                value={finantial.amount}
                name="finantial.amount"
                type="number"
                disabled={disableForm}
                onChange={props.handleChange}
                className="advance"
                />

            <TextField
                label="% Costo administrativo"
                size="small"
                variant="standard"
                value={finantial.administrativeCost}
                name="finantial.administrativeCost"
                type="number"
                disabled={disableForm}
                onChange={props.handleChange}
                className="advance"
                />
            {paramsForm.rol ===1 && (
                <>
                <TextField
                    InputLabelProps={{ shrink: true }} 
                    id="fechadeposito"
                    name="finantial.depositDate"
                    size="small"
                    variant="standard"
                    type="date"
                    label="Fecha de Depósito"
                    value={finantial.depositDate !== null && finantial.depositDate !==undefined ? parsers.formatDateAlternative(
                        moment(finantial.depositDate),
                    ) : ""}
                    disabled={disableForm || paramsForm.rol !==1}
                    onChange={props.handleChange}
                />
             
                <TextField
                        InputLabelProps={{ shrink: true }} 
                        id="fechainversion"
                        name="finantial.investmentDate"
                        size="small"
                        variant="standard"
                        type="date"
                        label="Fecha de Inversión"
                        value={finantial.investmentDate !== null && finantial.investmentDate !==undefined ? parsers.formatDateAlternative(
                            moment(finantial.investmentDate),
                        ) : ""}
                        disabled={disableForm || paramsForm.rol !==1}
                        onChange={props.handleChange}
                    />
                    
                     <TextField
                        select
                        InputLabelProps={{ shrink: true }} 
                        label="Día de pago aproximado"
                        name="finantial.approxPaymentDay"
                        value={finantial.approxPaymentDay}
                        SelectProps={{
                            native: true,
                        }}
                        variant="standard"
                        size="small"
                        disabled={!paramsForm.editable}
                        onChange={props.handleChange}
                        className="advance"
                        >
                        {days.map((option:any) => (
                            <option key={option.id} value={option.id}>
                            {option.name}
                            </option>
                        ))}
                        </TextField>
                    </>)}


            
            {paramsForm.rol ===1 && finantial.investmentDate !== null && (
                <ScheduleForm investmentDate={finantial.investmentDate} 
                    amount={finantial.amount} 
                    administrativeCost={finantial.administrativeCost}
                    approxPaymentDay={finantial.approxPaymentDay}
                    idProcedure={paramsForm.idProcedure}
                    scheduleList={props.scheduleList}
                    handleClickShedule={props.handleClickShedule}
                    handleChangePayment={props.handleChangePayment}
                    handleSaveShedule={props.handleSaveShedule}
                    handleClose={props.handleClose}
                    open={props.open}
                    investor={props.data.investorNames}
                    product={props.data.product}
                    coupon={finantial.coupon}
                    code={props.data.code}
                />
            )}
       </Box>
       <Box>
        {finantial.generated == true  && (
            <TableContainer >
                <Typography  variant="h4" sx={{pt: '30px', pb: '15px'}}>Cronograma
                    <Tooltip title="Descargar cronograma">
                        <Button variant="text" 
                            onClick={(e) => props.handleDownloadPdf(e)}
                            
                            >
                            <PictureAsPdfOutlinedIcon /> 
                        </Button>
                    </Tooltip>
                </Typography>
               
               
            <Table sx={{ minWidth: 600 }} aria-label="customized table">
                <TableHead>
                <TableRow>
                    <StyledTableCell align="center">Fecha de pago</StyledTableCell>
                    <StyledTableCell align="center">Ingreso neto</StyledTableCell>
                    <StyledTableCell align="center">Retención IR SUNAT 5% </StyledTableCell>
                    <StyledTableCell align="center">Pago de Cupón</StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {props.scheduleProcedure.items.map((row:any, index:any) => (
                    
                    <TableRow key={index}>
                    <StyledTableCell align="center">
                        {row.datePayment != null && row.datePayment != undefined ? parsers.formatDateAlternative(
                        moment(row.datePayment),
                    ) : ''}
                    </StyledTableCell>
                    <StyledTableCell align="center">{formatMoney(row.net)}</StyledTableCell>
                    <StyledTableCell align="center" sx={{color:"#FF0000"}}>{formatMoney(row.retention)}</StyledTableCell>
                    <StyledTableCell align="center">{formatMoney(row.paymentCoupon)}</StyledTableCell>
                    
                    
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        )}
       </Box>    
       <br></br>
       <br></br>
        <CertificateComponent idTramit={paramsForm.idProcedure}/>
        <br></br>
       <br></br>
        <Typography variant="h4">
            Origen de la riqueza
        </Typography>
        <Typography > Esta sección hace referencia a las actividades de las que proviene el patrimonio total de una persona, tanto dentro como fuera de una relación, es decir, aquellas actividades de las que derivan los fondos y propiedades del inversionista.</Typography>
       <br></br>
       <FormGroup>
            <FormControlLabel
                control={
                <Checkbox size="small" 
                    checked={finantial.originWealth1} 
                    onChange={props.handleChange} 
                    name='finantial.originWealth1'
                    disabled={disableForm}
                    key='originWealth1'
                    />
                }
                label='Ingresos por empleo'
                
            />
            <FormControlLabel
                control={
                <Checkbox size="small" 
                    checked={finantial.originWealth2} 
                    onChange={props.handleChange} 
                    name='finantial.originWealth2'
                    disabled={disableForm}
                    key='originWealth2'
                    />
                }
                label='Ahorros'
                
            />
            <FormControlLabel
                control={
                <Checkbox size="small" 
                    checked={finantial.originWealth3} 
                    onChange={props.handleChange} 
                    name='finantial.originWealth3'
                    disabled={disableForm}
                    key='originWealth3'
                    />
                }
                label='Rescate o retiros de otras inversiones'
                
            />
            <FormControlLabel
                control={
                <Checkbox size="small" 
                    checked={finantial.originWealth4} 
                    onChange={props.handleChange} 
                    disabled={disableForm}
                    name='finantial.originWealth4'
                    key='originWealth4'
                    />
                }
                label='Venta de la propiedad'
                
            />
            <FormControlLabel
                control={
                <Checkbox size="small" 
                    checked={finantial.originWealth5} 
                    onChange={props.handleChange} 
                    disabled={disableForm}
                    name='finantial.originWealth5'
                    key='originWealth5'
                    />
                }
                label='Venta de acciones o inversiones'
                
            />
            <FormControlLabel
                control={
                <Checkbox size="small" 
                    checked={finantial.originWealth6} 
                    onChange={props.handleChange} 
                    disabled={disableForm}
                    name='finantial.originWealth6'
                    key='originWealth6'
                    />
                }
                label='Herencia'
                
            />
            <FormControlLabel
                control={
                <Checkbox size="small" 
                    checked={finantial.originWealth7} 
                    onChange={props.handleChange} 
                    disabled={disableForm}
                    name='finantial.originWealth7'
                    key='originWealth7'
                    />
                }
                label='Venta de la empresa'
                
            />
            <FormControlLabel
                control={
                <Checkbox size="small" 
                    checked={finantial.originWealth8} 
                    onChange={props.handleChange} 
                    disabled={disableForm}
                    name='finantial.originWealth8'
                    key='originWealth8'
                    />
                }
                label='Ganancias/dividendos de la empresa'
                
            />
            <FormControlLabel
                control={
                <Checkbox size="small" 
                    checked={finantial.originWealth9} 
                    onChange={props.handleChange} 
                    disabled={disableForm}
                    name='finantial.originWealth9'
                    key='originWealth9'
                    />
                }
                label='Acuerdo de divorcio'
                
            />
            <FormControlLabel
                control={
                <Checkbox size="small" 
                    checked={finantial.originWealth10} 
                    onChange={props.handleChange} 
                    disabled={disableForm}
                    name='finantial.originWealth10'
                    key='originWealth10'
                    />
                }
                label='Lotería/Juegos de azar/Regalos'
                
            />
             <FormControlLabel
                control={
                <Checkbox size="small" 
                    checked={finantial.originWealth11} 
                    onChange={props.handleChange} 
                    disabled={disableForm}
                    name='finantial.originWealth11'
                    key='originWealth11'
                    />
                }
                label='Otros'
                
            />
            <TextField
                label="Especifíque la opción marcada"
                size="small"
                variant="standard"
                value={finantial.specification}
                name="finantial.specification"
                disabled={disableForm}
                onChange={props.handleChange}
                />
        </FormGroup>
        <br></br>
        <Divider/>
        <br></br>
        <Typography variant="h4">
            < SavingsOutlinedIcon/> Información de pagos - Transferencia bancaria
        </Typography>
       <br></br>
       <Box
                sx={{
                    display: 'grid',
                    columnGap: 1,
                    rowGap: 1,
                    gridTemplateColumns: 'repeat(1, 1fr)',
                }}
                >
            
            <TextField
                label="Nombre del banco o institución financiera"
                size="small"
                variant="standard"
                value={finantial.bank}
                name="finantial.bank"
                disabled={disableForm}
                onChange={props.handleChange}
                className="advance"
                />
            <TextField
                label="CCI / Swift"
                size="small"
                variant="standard"
                value={finantial.cci}
                name="finantial.cci"
                disabled={disableForm}
                onChange={props.handleChange}
                className="advance"
                />
            <TextField
                label="Número de la cuenta"
                size="small"
                variant="standard"
                value={finantial.bankAccountNumber}
                name="finantial.bankAccountNumber"
                disabled={disableForm}
                onChange={props.handleChange}
                className="advance"
                />
             <TextField
                label="Titular de la cuenta"
                size="small"
                variant="standard"
                value={finantial.bankAccountTitular}
                name="finantial.bankAccountTitular"
                disabled={disableForm}
                onChange={props.handleChange}
                className="advance"
                />
             <TextField
                label="Comentarios"
                size="small"
                variant="standard"
                value={finantial.bankAccountComments}
                name="finantial.bankAccountComments"
                disabled={disableForm}
                onChange={props.handleChange}
                />
           
            
       </Box>
      </>
    );
  };
  
  export default FinantialForm; 
  