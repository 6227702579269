
import MainLayout from "../../../layout/MainLayout";
import CustomizedTables from "../../../components/CustomizedTables";
import {identity, headCells, bodyCells, actions} from "../../../components/data/cuentasinvestor";
import React, { useState, useEffect } from 'react';
import {AccountService} from '../../../business/AccountService';
import {ProcedureService} from '../../../business/ProcedureService';
import moment from 'moment';
import Cookies from 'universal-cookie';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {ProductService} from '../../../business/ProductService';
import { 
  Button,
} from '@mui/material';
import { isInteger } from "formik";

const statusList = [
  {
    id: '0',
    name: 'Todas',
  },
  {
    id: '1',
    name: 'Pendientes de aporte',
  }
];

const filtersInitial = {
  investor:null, 
  idStatus: 0,
  consultant:null, 
}
const cookies = new Cookies();

const CuentasInvestor = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilterStatus] = useState(filtersInitial);
  const [xlsData, setXlsData] = useState([]);
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [products, setProducts] = useState([]);

  let linkTramites = '';
  let canEdit = false;

  let role=cookies.get('rol');
    switch(role)
    {
        case '1': linkTramites='/backoffice/tramites/';
              canEdit = true;
            break;
        case '2': linkTramites='/asesores/tramites/';
              canEdit = true;
            break;
        default:
            linkTramites = '/inversor/cuentas/';
            canEdit = false;
    }

    const getProductsList = async () => { 
    
      const productInstance =  ProductService.getInstance();
                          
      await productInstance.getProductsList()
      .then((data:any) => {
          if(data){
            setProducts(data);
          }   
          
      })
      .catch((err) => {
        console.log(err);
      });
    }
 
  const getAccounts = async () => { 

    const accountInstance = AccountService.getInstance();
    setLoading(true);
    await accountInstance.getAccounts(filter)
    .then((data:any) => {
        if(data){
          data = data.map((row:any) => ({...row, 
              createdAt: moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
              approvalDate: row.approvalDate !== null ? moment(row.approvalDate).format("DD/MM/YYYY HH:mm") : '',
            }))
          
          let  clonexlsData = data.map((col:any, index:number) => { return {
            "Nro.": index + 1,
            "Cuenta": col.code,
            "Inversionista": col.investor,
            "Nro. Serie": col.serialNumber,
            "Producto": col.product,
            "Fecha de creación": col.createdAt,
            "Fecha de aprobación": col.approvalDate,
            "Cuotas/Bonos": col.quotas,
            "Aporte anual": col.annualContribution,
            "Termino": col.endYears,
            "Asesor": col.consultant,
            "Juridicción":col.juridistion
          }});
          setXlsData(clonexlsData);
          console.log(xlsData)
          setData(data);
          setLoading(false);
        }   
        
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });

    
  }

  const insertProcedure = async (id:number, idAccount:number) => { 
    
    const insertInstance =  ProcedureService.getInstance();
                        
    await insertInstance.insertProcedure({idProduct: id, idAccount: idAccount})
    .then((data:any) => {
        if(data){
          window.location.href=linkTramites+data;
        }   
        
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    getAccounts();
    getProductsList();
  }, []);
 

  const handleSearch= (event: any) => {
    getAccounts()
   
    
  };
  const handleSelected = (ids: any) => {
    setSelected(ids);
  }

  const handleDelete = (ids: any) => {
    //
  }

  const handleCreateProcedure = (id:number, idAccount:number) => {
    insertProcedure(id, idAccount);
  };

  const handleChange = (event:any) => {
    const {name, value} = event.target
    setFilterStatus({
      ...filter,
      [name]: name==='idStatus' ? parseInt(value) : value
    });
     
   };

  


  return (
    <MainLayout>
      {role!=='3' && (
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'left' }}>

      <TextField
              select
              label="Estado"
              name="idStatus"
              onChange={handleChange}
              SelectProps={{
                  native: true,
              }}
              variant="outlined"
              size="small"
              value={filter.idStatus}
              sx={{
                pl:'8px',
                pr:'8px'
              }}
              
              >
              {statusList.map((option:any) => (
                  <option key={option.id} value={option.id}>
                  {option.name}
                  </option>
              ))}
              </TextField>
      

      <TextField
              label="Asesor"
              name="consultant"
              value={filter.consultant}
              onChange={handleChange}
              variant="outlined"
              size="small"
              sx={{
                pl:'8px',
                pr:'8px'
              }}
              >
            
              </TextField>
        
      <TextField
              label="Inversionista"
              name="investor"
              value={filter.investor}
              onChange={handleChange}
              variant="outlined"
              size="small"
              sx={{
                pl:'8px',
                pr:'8px'
              }}
              >
            
              </TextField>
        <Button 
                variant="contained"
                onClick={handleSearch}
                sx={{
                  pl:'8px',
                  pr:'8px'
                }}
          >
            <ManageSearchIcon/>Buscar</Button>

      </Box>
      )}
      <br></br>
      <CustomizedTables rows={data} 
        heads={headCells} 
        bodyCells={bodyCells} 
        identity={identity} 
        actions={actions} 
        editLink={linkTramites} 
        canEdit={canEdit}
        selected={selected}
        handleDelete={handleDelete}
        handleSelected={handleSelected}
        handleInsert={handleCreateProcedure}
        apiData={xlsData} 
        fileName='Cuentas'
        export={true}
        multiselect={false}
        loading={loading}
        products={products}
      />
  
    </MainLayout>
  )
}


export default CuentasInvestor
