import { createTheme, ThemeOptions } from '@mui/material/styles';
import { esES } from '@mui/material/locale';

import color from './colors';
import components from './compStyleOverride';
import typography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */


const theme = createTheme({
    direction: 'ltr',
    palette: {
        common: {
            black: color.darkPaper
        },
        primary: {
            light: color.primaryLight,
            main: color.primaryMain,
            dark: color.darkPrimaryDark,
            200: color.primary200,
            800: color.primary800
        },
        secondary: {
            light: color.secondaryLight,
            main: color.secondaryMain,
            dark: color.secondaryDark,
            200: color.secondary200,
            800: color.secondary800
        },
        error: {
            light: color.errorLight,
            main: color.errorMain,
            dark: color.errorDark
        },
        warning: {
            light: color.warningLight,
            main: color.warningMain,
            dark: color.warningDark
        },
        success: {
            light: color.successLight,
            200: color.success200,
            main: color.successMain,
            dark: color.successDark
        },
        grey: {
            50: color.grey50,
            100: color.grey100,
            500: color.grey500,
            600: color.grey900,
            700: color.grey700,
            900: color.grey900
        },
        text: {
            primary: color.grey700,
            secondary: color.darkTextSecondary,
        },
        background: {
            default: color.paper,
        }
    },
    mixins: {
        toolbar: {
            minHeight: '48px',
            padding: '16px',
            '@media (min-width: 600px)': {
                minHeight: '48px'
            }
        }
    },
    components,
    typography
  });



export default theme;
