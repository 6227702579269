
import MainLayout from "../../../../src/layout/MainLayout";
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Cookies from 'universal-cookie';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {ProductService} from '../../../../src/business/ProductService';
import { useParams } from "react-router-dom";
import ReactPlayer from 'react-player';
import { 
  Button,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  Avatar,
  Tabs,
  Tab,
  List,
  ListItem,
  Collapse,
 CardContent,
 Divider,
 Link
} from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { TryRounded } from "@mui/icons-material";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const initialValues = {
    id: 0,
    annualCoupon: 0,
    image : "",
    name: "",
    description: "",
    features: "",
    lastUpdate: "",
    expired: "",
    paymentFrequency: "",
    paymentMonths: "",
    documents : [],
    faq: [],
    videos: []
}

const StyledTableCell = styled(TableCell)(({ theme} :any ) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

const cookies = new Cookies();
let link = "";

let role=cookies.get('rol');
    switch(role)
    {
        case '1': link='/backoffice/simulador/';
            break;
        case '2': link='/asesores/simulador/';
            break;
        default:
            link = '/inversor/simulador/';
    }

const ProductoDetalle = () => {

  let params = useParams();
  let id = params.productId;
  let states = { settings: [{ id: 1, open: false, title:"Información para ventas" }
    , { id: 2, open: false, title:"Sales Aids" }
    , { id: 3, open: false, title:"Due Diligence" }
    , { id: 4, open: false, title:"Formatos y Solicitudes" }
    , { id: 5, open: false, title:"Información del Sector" }
  ] };


  const [product, setProduct] = useState(initialValues);  
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [isOpenCollapse, setIsOpenCollapse] = useState(null);
  const [docStates, setDocStates] = React.useState(states);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  
  const getProductDetails= async () => { 
    let idProduct = id !== undefined ? parseInt(id): 0;
    const instance = ProductService.getInstance();
    setLoading(true);
    await instance.getProductDetails({idProduct: idProduct})
    .then((data:any) => {
        if(data){
          
          setProduct(data);
          setLoading(false);
        }   
        
    })
    .catch((err:any) => {
      console.log(err);
      setLoading(false);
    });

    
  }
  
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  
  

  const handleOpen = (clickedIndex:any) => {
    if (isOpenCollapse === clickedIndex) {
      setIsOpenCollapse(null);
    } else {
      setIsOpenCollapse(clickedIndex);
    }
  };

  const handleClick = (clickedIndex:any) => {
    setDocStates((states:any) => ({
      ...docStates,
      settings: docStates.settings.map((item:any) =>
        item.id === clickedIndex ? { ...item, open: !item.open } : item
      )
    }));
    console.log('states', docStates, clickedIndex)
  };

 

  

  useEffect(() => {
    getProductDetails();
  }, []);

  


   

  


  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center'
        ,bgcolor: 'background.paper'
        , textAlign: 'center'
        , justifyContent: 'left'
        , padding: '15px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center'
          ,bgcolor: 'background.paper'
          , textAlign: 'center'
          , justifyContent: 'left'
          , flexDirection: 'column'
          , padding: '15px' 
          , border: '1px solid #eee'
          , borderRadius: '15px'
          , margin: '8px'
          , boxShadow: '5px 10px 18px #eee'
          }}>
        <img src={process.env.PUBLIC_URL + product.image}  width="300" height="auto"/>
        <br></br>
        <Typography>{product.name}</Typography>
        <br></br>
        {role!=='3' && (
        <Link underline="none" href={link}>
              <Button variant="outlined">Simulador</Button> 
        </Link>
        )}
        </Box>
      
        <TableContainer >
          <Table sx={{ minWidth: 300 }} aria-label="customized table">
              <TableHead>
              <TableRow>
                  <StyledTableCell align="center">Cupón Anual</StyledTableCell>
                  <StyledTableCell align="center">Plazo / Fecha de vencimiento</StyledTableCell>
                  <StyledTableCell align="center">Frecuencia de pago</StyledTableCell>
                  <StyledTableCell align="center">Meses de pago</StyledTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              
                  
                  <TableRow key={product.id}>
                  <StyledTableCell align="center">{product.annualCoupon} %</StyledTableCell>
                  <StyledTableCell align="center">{moment(product.expired).format("YYYY-MM-DD")}</StyledTableCell>
                  <StyledTableCell align="center">{product.paymentFrequency}</StyledTableCell>
                  <StyledTableCell align="center">{product.paymentMonths}</StyledTableCell>
                  </TableRow>
            
              </TableBody>
          </Table>
          </TableContainer>
      </Box>
      <br></br>
     
        <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flexDirection:'column', whiteSpace: 'pre-line' }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="maininfo"
      >
        <Tab {...a11yProps(0)} label="Resumen" disabled={product.description==null ? true: false}/>
        <Tab {...a11yProps(1)} label="Características" disabled={product.features==null ? true: false}/>
        <Tab {...a11yProps(2)} label="Actualización" disabled={product.lastUpdate==null ? true: false}/>
        <Tab {...a11yProps(3)} label="Biblioteca" disabled={product.documents.length==0 ? true: false}/>
        <Tab {...a11yProps(4)} label="Preguntas frecuentes" disabled={product.faq.length==0 ? true: false}/>
        <Tab {...a11yProps(5)} label="Videos" disabled={product.videos.length==0 ? true: false}/>
      </Tabs>
      <br></br>
      <TabPanel value={value} index={0} >
      <div className="content" dangerouslySetInnerHTML={{__html: product.description}}></div>
      </TabPanel>
      <TabPanel value={value} index={1} >
      <div className="content" dangerouslySetInnerHTML={{__html: product.features}}></div>
      </TabPanel>
      <TabPanel value={value} index={2} >
      <div className="content" dangerouslySetInnerHTML={{__html: product.lastUpdate}}></div>
      </TabPanel>
      <TabPanel value={value} index={3} >
      <Box sx={{flexFlow: "column wrap",
    gap: "0 30px",display: "flex", flexGrow: 1}}>
        {docStates.settings.map((elm:any, index:number) => {
          return (
            <>
            {product.documents.filter((item:any) => item.idCategory == elm.id).length > 0 ? 
             
            <>
            <Divider></Divider>
              <ListItem key={elm.id} onClick={() => handleClick(elm.id)}  sx={{justifyContent: 'space-between', background: elm.open===true ? '#eee':'#fff'}}>
              {elm.open===true ?
                <>
                  <Box>
                    <Typography sx={{fontWeight:'bold'}}>{elm.title}</Typography>                  
                  </Box>
                  <ExpandLess />
                 </> : 
                 <>
                 <Box>
                   <Typography>{elm.title}</Typography>                  
                 </Box>
                 <ExpandMore />
                </>
                 }
                
              </ListItem>
              <Divider></Divider>
              
              <Collapse in={elm?.open } timeout="auto" unmountOnExit >
              {product.documents.map((doc:any, index:number) => {
                return (
                  <>
                  {doc.idCategory == elm.id ? 
                  
                  <Link href={doc.url} target="_blank" sx={{lineHeight:'30px', pl:'30px'}}>
                  {doc.name}<br></br>
                  </Link> : <></>}
                  </>
                  )
                })}
              </Collapse><br></br>
            </> : <></>}
            </>
          );
        })}
      </Box>
      </TabPanel>
      <TabPanel value={value} index={4} >
      <List>
        {product.faq.map((elm:any, index:number) => {
          return (
            <>
              <ListItem key={elm.ID} onClick={() => handleOpen(index)} id="listItem" sx={{justifyContent: 'space-between', width:'auto'}}>
              {isOpenCollapse === index ?
                <>
                  <Box>
                    <Typography sx={{fontWeight:'bold'}}>{index+1}. &nbsp;{elm.question}</Typography>                  
                  </Box>
                  <ExpandLess />
                 </> : 
                 <>
                 <Box>
                   <Typography>{index+1}. &nbsp;{elm.question}</Typography>                  
                 </Box>
                 <ExpandMore />
                </>
                 }
                
              </ListItem>
              <Divider></Divider>
              <Collapse in={isOpenCollapse === index} timeout="auto" unmountOnExit sx={{background: '#eee'}}>
                <CardContent >
                <div className="content" dangerouslySetInnerHTML={{__html: elm.answer}}></div>
                </CardContent>
              </Collapse>
            </>
          );
        })}
      </List>
      </TabPanel>
      <TabPanel value={value} index={5} >
      <Box
      sx={{ bgcolor: 'background.paper', display: 'flex' }}>
        {product.videos.map((v:any) => (
          <Box
          sx={{ padding: '15px' }}>
            <ReactPlayer width="100%" height="276px" url={v.url}  />
          </Box>
        ))}
        </Box>
      </TabPanel>
      
    </Box>
  
  
    </MainLayout>
  )
}


export default ProductoDetalle
