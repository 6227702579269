import React from 'react';
import {
    TextField,
    Box,
    FormGroup,
    Typography,
    FormControlLabel,
    Checkbox

} from '@mui/material';

const genders = [
    {
      id: 'F',
      name: 'Femenino',
    },
    {
      id: 'M',
      name: 'Masculino',
    }
  ];

  const docTypes = [
    {
      id: '1',
      name: 'DNI',
    },
    {
      id: '2',
      name: 'RUC',
    }
  ];

  const languages = [
    {
      id: '1',
      name: 'Español',
    },
    {
      id: '2',
      name: 'Inglés',
    }
  ];
 
  
export interface AddInvestorFormProps {
    data: {
            additionalInfo:[
              {
                  idQuestion: number;
                  title: string;
                  question: string;
                  idTypeItem: number;
                  answer: number;
                  options: [
                      {
                          idOption: number;
                          option: string;
                          answer: number;
                          type: string;
                      }
                  ]                    
              }
            ];
          }
    

    paramsForm: {
        idProcedure: number;
        editable: boolean;
      };

      handleChange: any
    
   
  }


  const AddInvestorForm = (props: AddInvestorFormProps) => {
    const { data: infoProps , paramsForm} = props;
    let info = props.data.additionalInfo;

  
    const isFormDisabled = () =>
        !paramsForm.editable;
    const disableForm = isFormDisabled();
  
    return (
      <>
      <Box
                sx={{
                    display: 'grid',
                    columnGap: 1,
                    rowGap: 1,
                    gridTemplateColumns: 'repeat(1, 1fr)',
                }}
                >
                {info.map((question:any, index:number) => {
                    return (                    
                    
                    question.idTypeItem == '5' ? 
                    <div key={`divt${question.idQuestion}'-'${index}`}>
                    <br></br>
                    <Typography variant="h4" key={`title${question.idQuestion}'-'${index}`}>
                        {question.title}
                    </Typography>
                    <Typography variant="h5" key={`subtitle${question.idQuestion}'-'${index}`}>
                        {question.question}
                    </Typography>
                    </div>
                    : ( question.idTypeItem == '3' ?
                            <>
                                <FormGroup key={`formgroup${question.idQuestion}'-'${index}`}>
                                    <FormControlLabel
                                        control={
                                        <Checkbox size="small" 
                                            checked={question.answer === '1' ? true : false} 
                                            onChange={props.handleChange} 
                                            disabled={disableForm}
                                            name={`question${question.idQuestion.toString()}`} 
                                            key={`${question.idQuestion}'-'${index}`}
                                            inputProps={{
                                                // @ts-ignore
                                                'questionindex': index, 'typequestion': question.idTypeItem
                                                }}
                                            />
                                        }
                                        label={question.question}
                                        
                                    />
                                </FormGroup>
                            </>
                            : (
                                question.idTypeItem == '4' ? 
                                <TextField
                                select
                                name={`question${question.idQuestion.toString()}`}
                                inputProps={{'questionindex': index, 'typequestion': question.idTypeItem}}
                                label={question.question}
                                value={question.answer}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="standard"
                                size="small"
                                onChange={props.handleChange}
                                disabled={disableForm}
                                key={`${question.idQuestion}'-'${index}`}
                                >
                                <option key='1' value='0'>
                                    No
                                </option>
                                <option key='2' value='1'>
                                    Sí
                                </option>
                                </TextField>
                            :
                            <TextField
                                name={`question${question.idQuestion.toString()}`}
                                inputProps={{'questionindex': index, 'typequestion': question.idTypeItem}}
                                label={question.question}
                                defaultValue={question.answer}
                                size="small"
                                variant="standard"
                                onChange={props.handleChange}
                                disabled={disableForm}
                                key={`${question.idQuestion}'-'${index}`}
                            />
                            )
                    )
                    
                  
                )}
                )}
             
        </Box>
        </> 
    );
  };
  
  export default AddInvestorForm;
  