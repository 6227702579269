import { api } from "../../src/api";
import ActionType from "../../src/utils/customTypes";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class ProcedureService extends api {

    private static classInstance?: ProcedureService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
     
    public static getInstance() {
        let token = cookies.get('stkc');
        if (!this.classInstance) {
            this.classInstance = new ProcedureService(token!==undefined?token:'', 'GET');
        }
        
        return this.classInstance;
    }   
    //#section GET
    public getProcedures = (data:any) => this.instance.post('/procedures/get-procedures',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getProcedure = (data:any) => this.instance.post('/procedures/get-procedure',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getMyPayments = (data:any) => this.instance.post('/procedures/get-my-payments',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getPayments = (data:any) => this.instance.post('/procedures/get-payments',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getTimeline = () => this.instance.post('/procedures/get-timeline').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getMyActiveProcedures = () => this.instance.post('/procedures/get-my-active-procedures').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getScheduleProcedure = (data:any) => this.instance.post('/procedures/get-schedule-procedure', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getPaymentSchedule = (data:any) => this.instance.post('/procedures/get-payment-schedule',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getCommissions = (data:any) => this.instance.post('/procedures/get-commissions',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    //#end section GET
    //#section INSERT
    public insertProcedure = (data:any) => this.instance.post('/procedures/insert-procedure',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });   

    public insertPaymentSchedule = (data:any) => this.instance.post('/procedures/insert-payment-schedule',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });
    //#end section INSERT

    //#section UPDATE
    public UpdateProcedure = (data:any) => this.instance.post('/procedures/update-procedure',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public UpdateDocument = (data:any) => this.instance.post('/procedures/update-procedure-document',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public UpdateStatusProcedure = (data:any) => this.instance.post('/procedures/update-status-procedure',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public updatePaymentSchedule = (data:any) => this.instance.post('/procedures/update-payment-schedule',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });
    //#end section UPDATE

    //#end section DELETE
    public deleteProcedure = (data:any) => this.instance.post('/procedures/delete-procedure',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public deleteProcedures = (data:any) => this.instance.post('/procedures/delete-procedures',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });
    //#end section DELETE
    public downloadDocument = (data:any) => this.instance.post('/procedures/download-document',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });
    




}