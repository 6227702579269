


import MainLayout from "../../../layout/MainLayout";
import AmountForm from "../../../components/simulador/AmountForm";
import TimeForm from "../../../components/simulador/TimeForm";
import BonosForm from "../../../components/simulador/BonosForm";
import ResultForm from "../../../components/simulador/ResultForm";
import React, { useState, useEffect } from 'react';
import {SimulatorService} from '../../../business/SimulatorService';
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
const cookies = new Cookies();

const months = ['Ene','Feb','Mar','Abr','May','Jun', 'Jul','Ago','Sep','Oct','Nov','Dic'];

const data = {
  generatedAt: '',
  capital:0.00,
  projectionTime:2,
  initialCommision:0.00,
  inversionAvailable:0.00,
  administrativeCost:2.0,
  fundExpense:0.018,
  totalNet:0,
  annualIncomePer:0,
  totalGross:0,
  grossPer:0,  
  products:[
     {
        idProduct:1,
        name:"Pardus Fixed Income Bond",
        image:"/pardus.jpg",
        icon:null,
        abstract:"",
        factsheetLink:"",
        couponLink:"",
        brochureLink:"",
        annualCoupon:12.00,
        investedAmount:19600.00,
        assignment:33.33,
        annualGrossIncome:2352.00,
        paymentFrequency:"Trimestral",
        partialCouponPercentage:3.00,
        months:" Ene, Abr, Jul, Oct",
        expirationDate:"2024-06-30T00:00:00",
        status:false
     }
  ],
  years:[
  ],
  pieChart:[
     {
        name:"Pardus Fixed Income Bond",
        value:0
     }
  ],
  calendars:[
     {
        totalGross:2421.33,
        totalNet:1943.20,
        totalReturned:0.00,
        products:[
           {
              idProduct:1,
              year:2022,
              color: "",
              product:"Pardus Fixed Income Bond",
              ene:0,
              feb:0,
              mar:0,
              abr:0,
              may:0,
              jun:0,
              jul:0,
              ago:0,
              sep:0,
              oct:588.00,
              nov:0,
              dic:0,
              eneReturn:false,
              febReturn:false,
              marReturn:false,
              abrReturn:false,
              mayReturn:false,
              junReturn:false,
              julReturn:false,
              agoReturn:false,
              sepReturn:false,
              octReturn:false,
              novReturn:false,
              dicReturn:false
           }
        ],
        year: 0
     }
  ],
  graphic:{
     totalNet:0,
     items:[
        {
           year: 2022,
           month: 9,
           name: "Sep 2022",
           product1:0,
           product2:0,
           product3:0,
           product4:0,
           total: 0,
           totalCumulated: 0
        }
     ]
  }
}

const simulatorData = {
  amount : 0,
  totalAssigned: 0,
  years:1,
  type: 1,
  last: false,
  includeRetention:true,
  includeExpenses:true,
  products: [
      {
          idProduct:0,
          investedAmount: 0,
          assignment: 0,
          administrativeCost: 0
      }
    ]
}


const listBonos = [
    {
      idProduct: 1,
      title: "PARDUS FIXED INCOME BOND",
      annual: 12,
      redditus: 10,
      entranceCost: 2,
      initialCommision: 0,
      bonnus: 3,
      expired: "2024-06-30",
      returnYear: 2024,
      returnMoth: 6,
      period: "Trimestral",
      couponsPerYear: 4,
      image: process.env.PUBLIC_URL + "/pardus.jpg",
      abstract: "Pardus ofrece un título de deuda de alto rendimiento que ofrece a los inversores un ingreso fijo durante un plazo de dos años. El bono actúa como una garantía para que GRMA-Pardus Wealth pueda realizar operaciones de arbitraje de instrumentos financieros y generar retornos de inversión. Capital del bono esta administrado en fidecomiso con Woodside Corporate Services (regulado por el FCA del reino Unido). El capital nunca sale de la cuenta en Lloyds Bank de Reino Unido en Londres. Druces LLP, el bufet de abogados de Londres, desde 1767, realiza el trabajo legal para garantizar que todas las operaciones de arbitraje cumplan los estrictos criterios establecido para las inversiones de nivel 1 y también que cumplan con la Sharia. GRMA-Pardus Wealth garantiza la indemnización de cualquier pérdida en la que incurra el emisor y sus tenedores de bonos. Renovable después de dos años. desde abril de 2020 administra más de €360 millones en activos.",
      factsheetLink: "https://www.dropbox.com/s/qooynt4zphru9nj/PFIB%20Fact%20Sheet%20-%20%24%20Dollar%20es-419.pdf?dl=0",
      couponLink: "https://www.londonstockexchange.com/news-article/market-news/january-coupon-payment-to-bondholders/15280562",
      brochureLink: "https://www.dropbox.com/s/a1ywtxh4o3s2wxa/PARDUS%20Fixed%20Income%20Bond%20-%20Spanish.pdf?dl=0",
      annualCoupon: 0,
      investedAmount: 0,
      net: 0,
      redditusCoupon: 0,
      administrativeCost: 0,
      fundExpense: 0,
      assignment: 0,
      annualGrossIncome: 0,
      paymentFrequency: 0,
      partialCouponPercentage: 0,
      months: "1,4,7,10",
      paymentMonths: "Ene-Abr-Jul-Oct",
      expirationDate: "2022-06-12"
    }
  
]

function closest(needle:any, haystack:any) {
  return haystack.reduce((a:any, b:any) => {
      let aDiff = Math.abs(a - needle);
      let bDiff = Math.abs(b - needle);

      if (aDiff == bDiff) {
          return a > b ? a : b;
      } else {
          return bDiff < aDiff ? b : a;
      }
  });
}

const SimuladorAsesor = () => {
    const [step, setStep] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [simulator, setSimulator] = useState(data);
    const [simulatorRequest, setSimulatorRequest] = useState(simulatorData);
    const [bonos, setBonos] = useState(listBonos);
    const [last, setLast] = useState(false);
    const [pdfGenerated, setPdfGenerated] = useState(0);
    const getProducts = async () => { 
    
      const simulatorInstance = SimulatorService.getInstance();
                                
      await simulatorInstance.getProducts()
      .then((data:any) => {
          if(data){
            data = data.map((row:any) => ({...row, expired: moment(row.expired).format("YYYY-MM-DD")}));
            
            setBonos(data);
          }   
          
      })
      .catch((err:any) => {
      });
    }

    const getSimulation = async () => { 
    
      const simulatorInstance = SimulatorService.getInstance();
      let request = {...simulatorRequest};
      for(var i=0; i<bonos.length; i++)
      {
        if(bonos[i].investedAmount>0)
        {
          request.products.push({
            idProduct: bonos[i].idProduct,
            investedAmount: bonos[i].investedAmount,
            assignment: bonos[i].assignment,
            administrativeCost: bonos[i].administrativeCost
          })
        }
        
      }
                                
      await simulatorInstance.getSimulation(request)
      .then((data:any) => {
          if(data){
            console.log(data);
            console.log(data.calendars.filter((obj:any) => obj.year == 2022 ));
            setSimulator(data);
            setLast(true);
          }   
          
      })
      .catch((err:any) => {
      });
    }

    useEffect(() => {
      getProducts();
    }, []);
    
    useEffect(()=>{
      console.log(pdfGenerated,'pdf');
    }, [pdfGenerated])
  
    const handleNextStep= () => {

      if(step==0)
      {
        if(simulatorRequest.amount<=0)
        {
          toast.warning("Por favor ingrese un monto a invertir.", {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            });  
        }
        else{
          setStep(step+1);
        }
      }
      else{
        setStep(step+1);
      }
      
     
      
    };
    const handlePrevStep= () => {
      setStep(step-1);
     
      
    };  
    
    const handleRePlay = () =>{
      
      setLast(false);
      setSimulator(data);
      setPdfGenerated(0);
      setSimulatorRequest({
        amount : 0,
        totalAssigned: 0,
        years:3,
        type: 1,
        last: false,
        includeRetention:true,
        includeExpenses:true,
        products: [
            {
                idProduct:0,
                investedAmount: 0,
                assignment: 0,
                administrativeCost: 0
            }
          ]
      });      
      getProducts();  
      setPercentage(0);
      setStep(0);
      
      
      
    }

    useEffect(() => {
      if(last == true)
      {
        window.scrollTo(0, 0)
        setStep(3);
      }
      
    }, [last]);

    useEffect(() => {
      if(simulatorRequest.amount > 0)
      {
        setPercentage(30);
      }
      
    }, [simulatorRequest.amount]);

    useEffect(() => {
      if(step==1)
      {
        setPercentage(60);
      }
      
      
    }, [simulatorRequest.years]);

    useEffect(() => {
      if(step==1)
      {
        setPercentage(60);
      }
      
      
    }, [step]);

    useEffect(() => {
      if(simulatorRequest.totalAssigned==simulatorRequest.amount && simulatorRequest.amount > 0)
      {
        setPercentage(100);
      }
      else{
        if(simulatorRequest.totalAssigned > 0)
        {
          setPercentage((simulatorRequest.totalAssigned * 40 /  simulatorRequest.amount) + 60);
        }
        ;
      }
      
      
    }, [simulatorRequest.totalAssigned]);
    
   const handlePdfGenerated = (val:number) => {
    setPdfGenerated(val);
    console.log(val);
    console.log(pdfGenerated, 'valorpdf')
   }

    const handleChange= (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value} = event.target;
      
      let newData;

      if(event.target.hasAttribute('idproduct')){
        let idProduct = event.target.getAttribute('idproduct');
        let copyBonos = [...bonos];
        let index= copyBonos.findIndex(x => x.idProduct === parseInt(idProduct!=null ? idProduct:'0'));
        let amount = parseFloat(value);

        if(isNaN(amount))
        {
          amount = 0;
        }

        if(name.includes('investedAmount')){
          
          newData = {
            ...copyBonos[index],
            investedAmount: amount
          };
          copyBonos[index] = newData;

          
        }
        else{
          if(name.includes('assignment')){
            newData = {
              ...copyBonos[index],
              assignment: amount
            };
            copyBonos[index] = newData;
          }
          else {
            if(name.includes('administrativeCost')){
              newData = {
                ...copyBonos[index],
                administrativeCost: amount
              };
              copyBonos[index] = newData;
            }
          }
        }
        
       
        
        setBonos(copyBonos);
      }
      else{
           setSimulatorRequest({
            ...simulatorRequest,
            [name]: event.target.type==='checkbox' ? event.target.checked : parseFloat(value)
          });
        
       
      }
      
    };

    const handleChangeProductAmount= (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value} = event.target;

        let copyBonos = [...bonos];
        let copy = {...simulatorRequest};
        let idProduct = event.target.getAttribute('idproduct');
        let newData;
        let index= bonos.findIndex(x => x.idProduct === parseInt(idProduct!=null ? idProduct:'0'));
              
        let amount = parseFloat(value);

        let totalAssigned = 0;
        copyBonos[index].investedAmount = 0;
        

        for(let i=0; i < copyBonos.length; i++){
          totalAssigned = totalAssigned + copyBonos[i].investedAmount;
          
        }
        
        if(isNaN(amount)){
          amount = 0;
        }

        totalAssigned = totalAssigned + amount;
    
        if(totalAssigned > copy.amount)
        {
          amount = amount - (totalAssigned - copy.amount);
          totalAssigned = copy.amount;
      
        }
        
        newData = {
          ...copyBonos[index],
          investedAmount: amount,
          assignment : parseFloat((amount * 100 / copy.amount).toFixed(2))
        };
        copyBonos[index] = newData;
        copy.totalAssigned = totalAssigned;
      
        setSimulatorRequest(copy);
     
        setBonos(copyBonos);
       
    };

    const handleChangeProductAssignment= (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value} = event.target;

      let copyBonos = [...bonos];
      let copy = {...simulatorRequest};
        let idProduct = event.target.getAttribute('idproduct');
        let newData;
        let index= copyBonos.findIndex(x => x.idProduct === parseInt(idProduct!=null ? idProduct:'0'));
  
      
        let assignment = parseFloat(value);
        let amount = parseFloat((copy.amount * assignment / 100).toFixed(2));

        let totalAssigned = 0;
        copyBonos[index].investedAmount = 0;

        for(let i=0; i < copyBonos.length; i++){
          totalAssigned = totalAssigned + copyBonos[i].investedAmount;
          
        }
        
        if(isNaN(amount)){
          amount = 0;
        }
          totalAssigned = totalAssigned + amount;
      
          if(totalAssigned > copy.amount)
          {          
            
            amount = amount - (totalAssigned - copy.amount);
            totalAssigned = copy.amount;

        
          }
          
          newData = {
            ...copyBonos[index],
            investedAmount: amount,
            net: amount,
            assignment : parseFloat((amount * 100 / copy.amount).toFixed(2))
          };
          copyBonos[index] = newData;
          copy.totalAssigned = totalAssigned;
          
          setSimulatorRequest(copy);
     
          setBonos(copyBonos);
        
      
      
      
    };

  const removeEmpty = (array:any) => {
   
      for(let i = 0; i < array.length; i ++)
      {
        
          if(array[i].acumulado ==0 )
          {
              array.splice(i, 1);
          }
      }
      
      return array;
  }

  const handleIncludeRetentionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let copy = {...simulatorRequest};
    copy.includeRetention = event.target.checked;
    setSimulatorRequest(copy);
  };
  const handleIncludeExpensesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let copy = {...simulatorRequest};
    copy.includeExpenses = event.target.checked;
    setSimulatorRequest(copy);
  };

  const simulate = () => {
    if(simulatorRequest.totalAssigned == simulatorRequest.amount)
    {
      getSimulation();
    }
    else{
      toast.warning("Por favor asigne el monto a invertir.", {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        });  
    }
   
  }

    const showStep = () => {
        switch(step)
        {
            case 0:
                return (   
                     <AmountForm step={step} data={simulatorRequest} percentage={percentage}
                     handleNextStep={handleNextStep} handleChange={handleChange} 
                     handlePrevStep={handlePrevStep}/>
                  
                  )
            case 1:
                return (   
                        <TimeForm step={step} data={simulatorRequest} percentage={percentage}
                        handleNextStep={handleNextStep} handleChange={handleChange}  
                        handlePrevStep={handlePrevStep}/>
                    
                    )
            case 2:
                return (   
                        <BonosForm step={step} data={simulatorRequest} percentage={percentage} products={bonos}
                        handleNextStep={handleNextStep} handleChange={handleChange} handleIncludeRetentionChange={handleIncludeRetentionChange} handleIncludeExpensesChange={handleIncludeExpensesChange}
                        handlePrevStep={handlePrevStep} handleChangeProductAmount={handleChangeProductAmount} handleChangeProductAssignment={handleChangeProductAssignment} simulate={simulate}/>
                    
                    )
            case 3:
              return (   
                      <ResultForm step={step} handleRePlay={handleRePlay} handlePdfGenerated={handlePdfGenerated} pdfGenerated={pdfGenerated}
                      data={simulator} handleNextStep={handleNextStep} handleChange={handleChange}  handlePrevStep={handlePrevStep}
                      includeRetention={simulatorRequest.includeRetention}
                      includeExpenses={simulatorRequest.includeExpenses}
                      />
                  
                  )
        }
    }
    return (
        <MainLayout>    
                   {showStep()}
        </MainLayout>
    );


  



  
 }


export default SimuladorAsesor
