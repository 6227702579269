import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';



export default function ProcedureMenu(props:any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>

      {props.canEdit===true && <TextField
                select
                label="Estado"
                name="statusList"
                onChange={props.handleSearch}
                SelectProps={{
                    native: true,
                }}
                variant="outlined"
                size="small"
                value={props.idStatus}
                >
                {props.statusList.map((option:any) => (
                    <option key={option.id} value={option.id}>
                    {option.name}
                    </option>
                ))}
                </TextField>
}
        {props.canEdit===true && <Tooltip title="Agregar">
            <Button variant="outlined" 
            onClick={handleClick}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            >
                <AddIcon /> Nuevo Trámite
            </Button>
        </Tooltip>}
        
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="new-procedure"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'hidden',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        {
          props.products.map((item:any)=>
          (
            <MenuItem onClick={() => props.handleCreateProcedure(item.idProduct, 0) }>
              {item.name}
            </MenuItem>
          )
          )
        }
        
        
      </Menu>
    </React.Fragment>
  );
}