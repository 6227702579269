import { api } from "../../src/api";
import ActionType from "../../src/utils/customTypes";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class UserService extends api {

    private static classInstance?: UserService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
     
    public static getInstance() {
        let token = cookies.get('stkc');
        if (!this.classInstance) {
            this.classInstance = new UserService(token!==undefined?token:'', 'GET');
        }
        
        return this.classInstance;
    }   
     
    public getUsers = (data:any) => this.instance.post('/users/get-users',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    
    
    public getUser = (data:any) => this.instance.post('/users/get-user',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getInvestor = (data:any) => this.instance.post('/users/get-investor',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public insertUser = (data:any) => this.instance.post('/users/insert-user',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public UpdateUser = (data:any) => this.instance.post('/users/update-user',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

}