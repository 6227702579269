import dashboard from './dashboard';
import pages from './pages';
import pagesAdmin from './pagesAdmin';
import pagesInvestor from './pagesInvestor';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [ pages],
    admin: [pagesAdmin],
    inves: [pagesInvestor]
};

export default menuItems;
