
import MainLayout from "../../../layout/MainLayout";
import React, { useState, useEffect } from 'react';
import {UserService} from '../../../business/UserService';
import moment from 'moment';
import parsers from '../../../utils/parsers';
import {ListService} from '../../../../src/business/ListService';

import {
    Box,
    Button,
    FormControl,
    Grid,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    Input,
    InputLabel
    
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// third party
import * as Yup from 'yup';
import { Formik,ErrorMessage } from 'formik';

import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';

const statusList = [
  {
    id: 1,
    name: 'Activo',
  },
  {
    id: 0,
    name: 'Desactivado',
  }
];

const rolesList = [
  {
    id: 1,
    name: 'Administrador',
  },
  {
    id: 2,
    name: 'Asesor',
  },
  {
    id: 3,
    name: 'Inversionista',
  }
];

const genders = [
    {
        id: '',
        name: 'Seleccione',
    },
    {
      id: 'F',
      name: 'Femenino',
    },
    {
      id: 'M',
      name: 'Masculino',
    }
  ];

  const docTypes = [
    {
        id: '',
        name: 'Seleccione',
    },
    {
      id: 1,
      name: 'DNI',
    },
    {
      id: 2,
      name: 'RUC',
    }
  ];


const initialValues: any = {
    names: null,
    lastName: null,
    secondLastName: null,
    gender: null,
    email: null,
    idDocType: null,
    docNumber: null,
    birthday:null,
    nationality:null,
    cellphone:null,
    idResidenceCountry: null,
    city:null,
    idRol:1,
    idStatus:1,
    commission:null
   
};


const userSchema = Yup.object().shape({
    idDocType: Yup.number().required("*Campo obligatorio").nullable(),  
    docNumber: Yup.string().required("*Campo obligatorio").nullable(),
    names: Yup.string().required("*Campo obligatorio").nullable(),
    lastName: Yup.string().required("*Campo obligatorio").nullable(),
    secondLastName: Yup.string().required("*Campo obligatorio").nullable(),
    email: Yup.string().email("Debe ingresar un email válido").required("*Campo obligatorio").nullable(),
    idRol: Yup.string().required("*Campo obligatorio").nullable(),
    idStatus: Yup.string().required("*Campo obligatorio").nullable(),
    password: Yup.string().min(6, "La contraseña es muy corta - Debe contener al menos 6 caracteres"),
  });

const NuevoUsuario = () => {
  const [data, setData] = useState(initialValues);

  const [showPassword, setShowPassword] = useState(false);
  const [countries, setCountries] = useState([]);

  const getUbigeo = async (id:number, level:string) => {

    const ubigeoInstance = ListService.getInstance();
                        
    await ubigeoInstance.getUbigeo({idParent:id})
    .then((data:any) => {
        if(data){
          switch(level)
          {
            case 'countries':
              setCountries(data);
              break;
          }            
        }  
    })
    .catch((err) => {
        console.log(err);
    });
 
    
  }

  const insertUser = async () => {

    const insertInstance = UserService.getInstance();
                      
    await insertInstance.insertUser(data)
    .then((data:any) => {
        window.location.href='/backoffice/usuarios';   
        
    })
    .catch((err) => {
      
        console.log(err);   
    })
 
    
  }

  useEffect(() => {
    getUbigeo(0, 'countries');
  }, [])

  const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event:any) => {
      event.preventDefault();
  };

  const handleChangeData= (event: any) => {
    const { name, value} = event.target;
   
    let val = value;
    if(name==='idDocType' || name==='idRol' || name==='idResidenceCountry' || name==='idStatus')
    {
        val = parseInt(value);
    }
    if(name==='commission')
    {
      val = parseFloat(value);
    }
    setData({
        ...data,
        [name]: event.target.type==='checkbox' ? event.target.checked : val
      });
      
    
    
  };

  const handleSave= (event: any) => {
    console.log('intento guardar')
  };

  const handleSubmit= (event: any) => {
    console.log('ahora si enro al submit')
  };

  

  return (
    <MainLayout>    
         <Formik
                initialValues={initialValues}
                validationSchema={userSchema}                 
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    console.log(values,'entro al submit')
                    setSubmitting(true);
                    insertUser();
                                      
                        
                       
                }}
            >
        {({ errors, handleChange, handleSubmit, isSubmitting, touched }) => (
            <>
            <form noValidate onSubmit={handleSubmit}>
         <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>

     
            <Typography  variant="h3">Creación de usuario</Typography>
            <Button
                            variant="outlined"
                            key='btnsave2'
                            type="submit"
                            disabled={isSubmitting}
                            >
                            <SaveAsOutlinedIcon/> Guardar
                            
            </Button>     
        </Box>
     <br></br>
     <Grid container
      sx={{  bgcolor: 'background.paper', display: 'flex', height: 'auto', p:'25px' }}
    >   
        <Grid item xs={12} md={6}>
        <FormControl error={Boolean(touched.idDocType && errors.idDocType)} sx={{ mb:"15px" , minWidth:'300px'}}>
                         
            <TextField
                InputLabelProps={{ shrink: true }} 
                select
                label="Tipo de documento*"
                name="idDocType"
                value={data.idDocType}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                onChange={e => {handleChange(e); handleChangeData(e)}}
                >
                {docTypes.map((option:any) => (
                    <option key={option.id} value={option.id}>
                    {option.name}
                    </option>
                ))}
                </TextField>
                <ErrorMessage
                    component="div"
                    name="idDocType"
                    className="invalid-feedback"
                    />
        </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.docNumber && errors.docNumber)} sx={{ mb:"15px" , minWidth:'300px'}}>
                            
                <TextField
                    
                    label="Número de documento*"
                    name="docNumber"
                    value={data.docNumber}
                    SelectProps={{
                        native: true,
                    }}
                    variant="standard"
                    size="small"
                    onChange={e => {handleChange(e); handleChangeData(e)}}
                    >
                    {data.docNumber}
                    </TextField>
                    <ErrorMessage
                        component="div"
                        name="docNumber"
                        className="invalid-feedback"
                        />
            </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.names && errors.names)} sx={{ mb:"15px" , minWidth:'300px'}}>
                            
                <TextField
                    label="Nombres*"
                    name="names"
                    value={data.names}
                    SelectProps={{
                        native: true,
                    }}
                    variant="standard"
                    size="small"
                    onChange={e => {handleChange(e); handleChangeData(e)}}
                    >
                    {data.names}
                    </TextField>
                    <ErrorMessage
                        component="div"
                        name="names"
                        className="invalid-feedback"
                        />
            </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.lastName && errors.lastName)} sx={{ mb:"15px" , minWidth:'300px'}}>
                            
                <TextField
                    label="Primer Apellido*"
                    name="lastName"
                    value={data.lastName}
                    SelectProps={{
                        native: true,
                    }}
                    variant="standard"
                    size="small"
                    onChange={e => {handleChange(e); handleChangeData(e)}}
                    >
                    {data.lastName}
                    </TextField>
                    <ErrorMessage
                        component="div"
                        name="lastName"
                        className="invalid-feedback"
                        />
            </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.secondLastName && errors.secondLastName)} sx={{ mb:"15px" , minWidth:'300px'}}>
                            
                <TextField
                    label="Segundo Apellido*"
                    name="secondLastName"
                    value={data.secondLastName}
                    SelectProps={{
                        native: true,
                    }}
                    variant="standard"
                    size="small"
                    onChange={e => {handleChange(e); handleChangeData(e)}}
                    >
                    {data.secondLastName}
                    </TextField>
                    <ErrorMessage
                        component="div"
                        name="secondLastName"
                        className="invalid-feedback"
                        />
            </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.gender && errors.gender)} sx={{ mb:"15px" , minWidth:'300px'}}>
              
              <TextField
                InputLabelProps={{ shrink: true }} 
                select
                label="Género"
                name="gender"
                value={data.gender}
                onChange={e => {handleChange(e); handleChangeData(e)}}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                >
                {genders.map((option:any) => (
                    <option key={option.id} value={option.id}>
                    {option.name}
                    </option>
                ))}
                </TextField>
                <ErrorMessage
                        component="div"
                        name="secondLastName"
                        className="invalid-feedback"
                        />
         </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.email && errors.email)} sx={{ mb:"15px" , minWidth:'300px'}}>
            
            <TextField
                label="Email"
                name="email"
                value={data.email}
                size="small"
                variant="standard"
                onChange={e => {handleChange(e); handleChangeData(e)}}
                />
                <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                        />
         </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.birthday && errors.birthday)} sx={{ mb:"15px" , minWidth:'300px'}}>
            
           
                <TextField
                    InputLabelProps={{ shrink: true }} 
                    id="birthday"
                    name="birthday"
                    size="small"
                    variant="standard"
                    type="date"
                    label="Fecha de nacimiento"
                    value={parsers.formatDateAlternative(
                        moment(data.birthday),
                    )}
                    onChange={e => {handleChange(e); handleChangeData(e)}}
                />
              <ErrorMessage
                        component="div"
                        name="birthday"
                        className="invalid-feedback"
                        />
         </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.ocupation && errors.ocupation)} sx={{ mb:"15px" , minWidth:'300px'}}>
            
                
            <TextField
                label="Ocupación"
                name="ocupation"
                value={data.ocupation}
                size="small"
                variant="standard"
                onChange={e => {handleChange(e); handleChangeData(e)}}
            />
            <ErrorMessage
                        component="div"
                        name="ocupation"
                        className="invalid-feedback"
                        />
         </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.cellphone && errors.cellphone)} sx={{ mb:"15px" , minWidth:'300px'}}>
            
            
            <TextField
                label="Celular"
                name="cellphone"
                value={data.cellphone}
                size="small"
                variant="standard"
                onChange={e => {handleChange(e); handleChangeData(e)}}
            />
            <ErrorMessage
            component="div"
            name="cellphone"
            className="invalid-feedback"
            />
</FormControl>
</Grid>
<Grid item xs={12} md={6}>
<FormControl error={Boolean(touched.idResidenceCountry && errors.idResidenceCountry)} sx={{ mb:"15px" , minWidth:'300px'}}>

            <TextField
                select
                label="País de residencia"
                name="idResidenceCountry"
                value={data.idResidenceCountry}
                onChange={e => {handleChange(e); handleChangeData(e)}}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                >
                {countries.map((option:any) => (
                    <option key={option.id} value={option.id}>
                    {option.name}
                    </option>
                ))}
                </TextField>
                <ErrorMessage
                        component="div"
                        name="idResidenceCountry"
                        className="invalid-feedback"
                        />
         </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.city && errors.city)} sx={{ mb:"15px" , minWidth:'300px'}}>
            
              <TextField
                label="Ciudad"
                name="city"
                value={data.city}
                onChange={e => {handleChange(e); handleChangeData(e)}}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                >               
                </TextField>
                <ErrorMessage
                        component="div"
                        name="city"
                        className="invalid-feedback"
                        />
         </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.idRol && errors.idRol)} sx={{ mb:"15px" , minWidth:'300px'}}>
            
                <TextField
                select
                label="Rol"
                name="idRol"
                value={data.idRol}
                onChange={e => {handleChange(e); handleChangeData(e)}}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                >
                {rolesList.map((option) => (
                    <option key={option.id} value={option.id}>
                    {option.name}
                    </option>
                ))}
                </TextField>
                <ErrorMessage
                        component="div"
                        name="idRol"
                        className="invalid-feedback"
                        />
         </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.commission && errors.commission)} sx={{ mb:"15px" , minWidth:'300px'}}>
            
            <TextField
                label="Porcentaje comisión"
                name="commission"
                value={data.commission}
                size="small"
                variant="standard"
                onChange={e => {handleChange(e); handleChangeData(e)}}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
                <ErrorMessage
                        component="div"
                        name="commission"
                        className="invalid-feedback"
                        />
         </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.idStatus && errors.idStatus)} sx={{ mb:"15px" , minWidth:'300px'}}>
            
            <TextField
                select
                label="Estado"
                name="idStatus"
                value={data.idStatus}
                onChange={e => {handleChange(e); handleChangeData(e)}}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                >
                {statusList.map((option) => (
                    <option key={option.id} value={option.id}>
                    {option.name}
                    </option>
                ))}
                </TextField>
                <ErrorMessage
                        component="div"
                        name="idStatus"
                        className="invalid-feedback"
                        />
         </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.password && errors.password)} sx={{ mb:"15px" , minWidth:'300px'}} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Contraseña</InputLabel>
            
              <Input
                id="standard-adornment-password"
                name="password"
                value={data.password}
                onChange={e => {handleChange(e); handleChangeData(e)}}
                type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Typography sx={{fontSize:'10px'}}>*Si no ingresa una contraseña, se asignará por defecto<br></br> el nro de documento del usuario.</Typography>
                <ErrorMessage
                        component="div"
                        name="password"
                        className="invalid-feedback"
                        />
         </FormControl>
        </Grid>
       
            
           
            
    </Grid>
                       
                    
               
            
        </form>
        </>
         )}
        </Formik>
    </MainLayout>
  )
}

export default NuevoUsuario
