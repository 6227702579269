
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Avatar,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Box
} from '@mui/material';

// assets
import {KeyOutlined} from '@mui/icons-material';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import moment from 'moment';

const CardStyle = styled(Card)(({ theme }) => ({
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative',
    
}));



// ==============================|| SIDEBAR MENU Card ||============================== //

const ProcedureCard = (props) => {
    const theme = useTheme();

    return (
        <CardStyle>
            <CardContent sx={{ p: 2 }}>
            <Box
                sx={{
                    display: 'grid',
                    columnGap: 3,
                    rowGap: 1,
                    gridTemplateColumns: 'repeat(3, 1fr)',
                }}
                >
                        <List alignitems="flex-start" sx={{ p: 0, m: 0 }}>
                            <ListItem alignitems="flex-start" disableGutters sx={{ p: 0 }}>
                                <ListItemAvatar sx={{ mt: 0 }}>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            color: theme.palette.grey[500],
                                            border: 'none',
                                            borderColor: theme.palette.primary.main,
                                            background: '#fff',
                                            marginRight: '12px'
                                        }}
                                    >
                                        <KeyOutlined fontSize="inherit" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ mt: 0 }}
                                    primary={
                                        <Typography variant="subtitle1" sx={{ color: theme.palette.primary.dark }}>
                                        Código de trámite
                                        </Typography>
                                    }
                                    secondary={<Typography variant="caption"> {props.code}</Typography>}
                                />
                           </ListItem>
                        </List>
                  
                        <List alignitems="flex-start" sx={{ p: 0, m: 0 }}>
                            <ListItem alignitems="flex-start" disableGutters sx={{ p: 0 }}>
                                <ListItemAvatar sx={{ mt: 0 }}>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            color: theme.palette.grey[500],
                                            border: 'none',
                                            borderColor: theme.palette.primary.main,
                                            background: '#fff',
                                            marginRight: '12px'
                                        }}
                                    >
                                        <DiscountOutlinedIcon fontSize="inherit" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ mt: 0 }}
                                    primary={
                                        <Typography variant="subtitle1" sx={{ color: theme.palette.primary.dark }}>
                                        Producto
                                        </Typography>
                                    }
                                    secondary={<Typography variant="caption"> {props.product}</Typography>}
                                />
                            </ListItem>
                        </List>
                   
                        <List alignitems="flex-start" sx={{ p: 0, m: 0 }}>
                            <ListItem alignitems="flex-start" disableGutters sx={{ p: 0 }}>
                                <ListItemAvatar sx={{ mt: 0 }}>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            color: theme.palette.grey[500],
                                            border: 'none',
                                            borderColor: theme.palette.primary.main,
                                            background: '#fff',
                                            marginRight: '12px'
                                        }}
                                    >
                                        <AccountBalanceOutlinedIcon fontSize="inherit" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ mt: 0 }}
                                    primary={
                                        <Typography variant="subtitle1" sx={{ color: theme.palette.primary.dark }}>
                                        Número de cuenta
                                        </Typography>
                                    }
                                    secondary={<Typography variant="caption"> {props.account}</Typography>}
                                />
                            </ListItem>
                        </List>
                   
                        <List alignitems="flex-start" sx={{ p: 0, m: 0 }}>
                            <ListItem alignitems="flex-start" disableGutters sx={{ p: 0 }}>
                                <ListItemAvatar sx={{ mt: 0 }}>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            color: theme.palette.grey[500],
                                            border: 'none',
                                            borderColor: theme.palette.primary.main,
                                            background: '#fff',
                                            marginRight: '12px'
                                        }}
                                    >
                                        <FaceOutlinedIcon fontSize="inherit" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ mt: 0 }}
                                    primary={
                                        <Typography variant="subtitle1" sx={{ color: theme.palette.primary.dark }}>
                                        Titular de cuenta
                                        </Typography>
                                    }
                                    secondary={<Typography variant="caption"> {props.investorNames}</Typography>}
                                />
                            </ListItem>
                        </List>
                   
                        <List alignitems="flex-start" sx={{ p: 0, m: 0 }}>
                            <ListItem alignitems="flex-start" disableGutters sx={{ p: 0 }}>
                            <ListItemAvatar sx={{ mt: 0 }}>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            color: theme.palette.grey[500],
                                            border: 'none',
                                            borderColor: theme.palette.primary.main,
                                            background: '#fff',
                                            marginRight: '12px'
                                        }}
                                    >
                                        <SupervisorAccountOutlinedIcon fontSize="inherit" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ mt: 0 }}
                                    primary={
                                        <Typography variant="subtitle1" sx={{ color: theme.palette.primary.dark }}>
                                        Asesor
                                        </Typography>
                                    }
                                    secondary={<Typography variant="caption"> {props.consultant}</Typography>}
                                />
                            </ListItem>
                        </List>
                   
                        <List alignitems="flex-start" sx={{ p: 0, m: 0 }}>
                            <ListItem alignitems="flex-start" disableGutters sx={{ p: 0 }}>
                                <ListItemAvatar sx={{ mt: 0 }}>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            color: theme.palette.grey[500],
                                            border: 'none',
                                            borderColor: theme.palette.primary.main,
                                            background: '#fff',
                                            marginRight: '12px'
                                        }}
                                    >
                                        <EventOutlinedIcon fontSize="inherit" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ mt: 0 }}
                                    primary={
                                        <Typography variant="subtitle1" sx={{ color: theme.palette.primary.dark }}>
                                        Fecha de actualización
                                        </Typography>
                                    }
                                    secondary={<Typography variant="caption"> {moment(props.updatedAt).format("DD/MM/yyyy HH:mm")}</Typography>}
                                />
                            </ListItem>
                        </List>
                   </Box>
             
                
            </CardContent>
            
        </CardStyle>
    );
};

export default ProcedureCard;
