
export interface Data {
    idPaymen:number;
    code: string;
    investorName: string;
    amount: number;
    applicationDate: string;
    confirmationDate: string;
    voucher: string;
    path: string;
    status: string;
    editable: boolean;
  }
  
  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  interface BodyCell {
    id: string;
  }

  interface Identity{
    id: string
  }

  interface Action {
    disable: boolean;
    type: string;
    tooltip: string;
  }
  
  export const headCells: readonly HeadCell[] = [
    {
      id: 'applicationDate',
      numeric: false,
      disablePadding: false,
      label: 'Fecha de solicitud',
    },
    {
      id: 'amount',
      numeric: false,
      disablePadding: false,
      label: 'Monto',
    },
    {
      id: 'confirmationDate',
      numeric: false,
      disablePadding: false,
      label: 'Fecha de tranferencia',
    },
   
  ];

  export const bodyCells: readonly BodyCell[] = [
    {
      id: 'applicationDate',
    },
    {
      id: 'amount',
    },
    {
      id: 'confirmationDate',
    }
    
  ];

  export const identity: Identity = 
    {
      id: 'idPaymen',
    }
  ;

  

  export const actions: readonly Action[] = [
    {
      disable: false,
      type: 'downloadVoucher',
      tooltip: 'Descargar voucher'
    },
  ];

  
  