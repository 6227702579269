import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

function getMonthName(monthIndex: number) {
  const monthNames = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];
  return monthNames[monthIndex];
}

export class CertificatePDFGenerator {
  static generateAndDownload(certificateData: any) {
    const doc = new jsPDF('portrait', 'pt', 'a4');

    autoTable(doc, {
      styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 14, fontStyle: 'bold' },
      columnStyles: { 0: { halign: 'center', fillColor: [255, 255, 255] } }, 
      margin: { top: 100 },
      body: [
        ['CERTIFICADO DE RETENCIÓN DE RENTAS DE TERCERA CATEGORÍA']
      ],
    });

    autoTable(doc, {
      styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 10, fontStyle: 'bold' },
      columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, 
      margin: { top: 100 },
      body: [
        ['DATOS DE CONTRIBUYENTE']
      ],
    });

    autoTable(doc, {
      styles: { overflow: "linebreak" },
      body: [
        ['Apellidos y nombres', certificateData.fullName],
        ['Tipo de documento', certificateData.documentType],
        ['N° de documento', certificateData.documentNumber],
      ],
    });

    autoTable(doc, {
      styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 10, fontStyle: 'bold' },
      columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, 
      margin: { top: 100 },
      body: [
        ['DATOS DEL AGENTE DE RETENCIÓN']
      ],
    });

    autoTable(doc, {
      styles: { overflow: "linebreak" },
      body: [
        ['Razón social', certificateData.companyName],
        ['N° de R.U.C.', certificateData.rucNumber],
        ['Fondo administrado', certificateData.managedFund],
        ['Domicilio fiscal', certificateData.taxResidence],
        ['Periodo tributario', certificateData.taxPeriod],
        ['Categoría', certificateData.taxCategory],
      ],
    });

    autoTable(doc, {
      styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 10, fontStyle: 'bold' },
      columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, 
      margin: { top: 100 },
      body: [
        ['DETALLE DE LA RETENCIÓN']
      ],
    });
    
    autoTable(doc, {
      pageBreak: 'avoid',
      headStyles: { fillColor: [0, 58, 78] },
      styles: { overflow: "linebreak" },
      margin: { top: 100 },
      columnStyles: { 0: { halign: 'left' } },
      columns: [
        { header: 'Fecha', dataKey: 'withholdingDate' },
        { header: 'Retención USD', dataKey: 'withholdingAmount' }
      ],
      body: certificateData.withholdingItems.map((item: any) => [
        item.withholdingDate,
        item.withholdingAmount.toFixed(2)
      ])
    });

    const today = new Date();
    const formattedDate = `${today.getDate()} de ${getMonthName(today.getMonth())} de ${today.getFullYear()}`;

    autoTable(doc, {
      styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 10, fontStyle: 'normal' },
      columnStyles: { 0: { halign: 'right', fillColor: [255, 255, 255] } }, 
      margin: { top: 100 },
      body: [
        [`Lima, ${formattedDate}`]
      ],
    });

    this.addFootersCertificate(doc);
    doc.save("certificado-retencion.pdf");
  }

  static addFootersCertificate(doc: any) {
    const pageCount = doc.internal.getNumberOfPages();
    const img = new Image();
    img.src = process.env.PUBLIC_URL + '/logocolorh.png';

    doc.setFont('helvetica', 'italic');
    doc.setFontSize(8);

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      if (i === 1) {
        doc.addImage(img, 'PNG', 30, 30, 140, 40);
      } else {
        doc.addImage(img, 'PNG', 30, 30, 100, 30);
      }
      doc.text(`Redditus Capital © Todos los derechos reservados | Página ${i} de ${pageCount}`, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 30, { align: 'center' });

      doc.saveGraphicsState();
      doc.setFontSize(100);
      const gstate = doc.GState({ opacity: 0.1 });
      doc.setGState(gstate);
      doc.setTextColor(200, 150, 92);
      doc.text('Redditus Capital', doc.internal.pageSize.height - 400, doc.internal.pageSize.width + 100, { align: 'center', baseline: 'middle', angle: 50 });
      doc.setTextColor(0, 0, 0);
      doc.restoreGraphicsState();
    }
  }
}
