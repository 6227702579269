import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { 
  Button,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TextField,
  Grid
} from '@mui/material';
import moment from 'moment';
import parsers from '../../utils/parsers';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface ScheduleFormProps {
    investmentDate: string;
    amount: number;
    administrativeCost:number;
    approxPaymentDay: number;
    idProcedure: number;
    scheduleList: any;
    handleClickShedule: any;
    handleChangePayment: any;
    handleSaveShedule: any;
    handleClose: any;
    open: boolean;
    investor: string;
    product: string;
    coupon: number;
    code: string;
  }

  const StyledTableCell = styled(TableCell)(({ theme} :any ) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const formatMoney = (amount:any) => {
    if(amount!=null && amount!=undefined)
    {
      return amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else{
      return 0;
    }
    
  }

export default function ScheduleForm(props:ScheduleFormProps) {
  

  const handleClickOpen = () => {
    props.handleClickShedule(props.idProcedure, props.amount, props.investmentDate, props.approxPaymentDay, props.administrativeCost);
  };
  const handleClose = () => {
    props.handleClose();
    
  };
  const handleSave = (event:any) => {
    props.handleSaveShedule(props.idProcedure, props.amount, props.investmentDate);
    
  };

  return (
    <div>
      <Button variant="outlined" 
        onClick={handleClickOpen}
        >
            <EditIcon /> Generar cronograma
        </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="ScheduleForm"
        open={props.open}
        fullScreen
      >
        <BootstrapDialogTitle id="ScheduleForm" onClose={handleClose}>
          <Typography sx={{color: "#000", fontSize: '1.2rem'}}>Generar Cronograma</Typography>
           
        </BootstrapDialogTitle>
        <DialogContent dividers>
        <TableContainer sx={{mb: '1rem'}}>
            <Table>
              <TableBody>
              <TableRow>
                  <StyledTableCell sx={{ color: "#000", lineHeight: '0.5rem'}}>Código de Trámite:</StyledTableCell>
                  <StyledTableCell sx={{ color: "#000", lineHeight: '0.5rem'}}>{props.code}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell sx={{ color: "#000", lineHeight: '0.5rem'}}>Cliente:</StyledTableCell>
                  <StyledTableCell sx={{ color: "#000", lineHeight: '0.5rem'}}>{props.investor}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell sx={{ color: "#000", lineHeight: '0.5rem'}}>Producto:</StyledTableCell>
                  <StyledTableCell sx={{ color: "#000", lineHeight: '0.5rem'}}>{props.product}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell sx={{ color: "#000", lineHeight: '0.5rem'}}>Aporte:</StyledTableCell>
                  <StyledTableCell sx={{ color: "#000", lineHeight: '0.5rem'}}>{formatMoney(props.amount)}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell sx={{ color: "#000", lineHeight: '0.5rem'}}>Cupón Neto (%):</StyledTableCell>
                  <StyledTableCell sx={{ color: "#000", lineHeight: '0.5rem'}}>{formatMoney(props.coupon)}</StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        <TableContainer >
          <Table sx={{ minWidth: 600 }} aria-label="customized table">
              <TableHead>
              <TableRow>
                  <StyledTableCell align="center">Fecha de pago</StyledTableCell>
                  <StyledTableCell align="center">Monto</StyledTableCell>
                  <StyledTableCell align="center">Costo Administrativo ({props.administrativeCost}%)</StyledTableCell>
                  {/* <StyledTableCell align="center">Gastos del fondo</StyledTableCell> */}
                  <StyledTableCell align="center">Retención IR SUNAT 5%</StyledTableCell>
                  <StyledTableCell align="center">Pago de cupón</StyledTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {props.scheduleList.map((row:any, index:any) => (
                  
                  <TableRow key={index}>
                  <StyledTableCell align="center">
                    
                    <TextField
                    InputLabelProps={{ shrink: true }} 
                    id="fechainversion"
                    name={`datePayment${index.toString()}`}
                    inputProps={{'datePayment': index}}                    
                    size="small"
                    variant="standard"
                    type="date"
                    label=""
                    value={row.datePayment !== null && row.datePayment !==undefined ? parsers.formatDateAlternative(
                        moment(row.datePayment),
                    ) : ""}
                    onChange={props.handleChangePayment}
                />
                  </StyledTableCell>
                  <StyledTableCell align="center">{formatMoney(row.amount)}</StyledTableCell>
                  <StyledTableCell align="center" sx={{color:"#FF0000"}}>{formatMoney(row.administrativeCost)}</StyledTableCell>
                  {/* <StyledTableCell align="center" sx={{color:"#FF0000"}}>{formatMoney(row.expenses)}</StyledTableCell> */}
                  <StyledTableCell align="center" sx={{color:"#FF0000"}}>{formatMoney(row.retention)}</StyledTableCell>
                  <StyledTableCell align="center">{formatMoney(row.totalAvailable)}</StyledTableCell>
                  
                  
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={(e) => handleSave(e)}>
            Guardar cambios
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}