
import MainLayout from "../../../../src/layout/MainLayout";
import TextMobileStepper from '../../../../src/components/TextMobileStepper';
import { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import {ProcedureService} from '../../../../src/business/ProcedureService';
import {ReportService} from '../../../../src/business/ReportService';
import {ProductService} from '../../../../src/business/ProductService';
import {AnnouncementService} from '../../../../src/business/AnnouncementService';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
 
} from "recharts";


import {
  Grid, 
  Typography, 
  Button
} from '@mui/material';

import Cookies from 'universal-cookie';

const buttonStyles = {
  label: {
    flexDirection: "column"
  }
};

const data = [
  {
    consultant: "",
    amount: 0,
    accounts: 0
  }
];

const productsData = [
  {
    name: "",
    product1: 0,
    product2: 0,
    product3: 0,
    product4: 0,
    product5: 0,
    product6: 0,
    product7: 0,
    product8: 0,
  },
]

const videos = [
  {
   _id: '5fd025a181e2c80897c14ae1',
   videoUrl: 'https://www.youtube.com/watch?v=KWYsOS4w53g'
  },
  {
    _id: '5fd025a181e2c80897c14ae1',
    videoUrl: 'https://www.youtube.com/watch?v=KWYsOS4w53g'
   },
   {
    _id: '5fd025a181e2c80897c14ae1',
    videoUrl: 'https://www.youtube.com/watch?v=KWYsOS4w53g'
   }
];

const useStyles = makeStyles({
  buttonIcon: {
    marginLeft: "6px" 
  },
  addProduct: {
   
    flexDirection: 'column', 
    color:'#fff', 
    width: '100%',
    '&:hover' : {
      backgroundColor: '#C8965C!important',
      "& $productName": {
        color: "#fff!important"
      }
    },
  },
  productName: {
    color:'#C8965C', 
    fontSize: '10px!important'
  }
});

const cookies = new Cookies();

const DashboardAsesor = () => {
  const [opacity, setOpacity] = useState({
    product1: 1,
    product2: 1,
    product3: 1,
    product4: 1,
    product5: 1,
    product6: 1,
    product7: 1,
    product8: 1
  });

  const [ranking, setRanking] = useState(data);
  const [productsRanking, setRankingProducts] = useState(productsData);
  const [products, setProducts] = useState([]);
  const [announcements, setAnnouncements] = useState([]);

  let nameUser=cookies.get('names');

  const classes = useStyles();


  const insertProcedure = async (id:number, idAccount:number) => { 
    
    const insertInstance =  ProcedureService.getInstance();
                        
    await insertInstance.insertProcedure({idProduct: id, idAccount: idAccount})
    .then((data:any) => {
        if(data){
          window.location.href='/asesores/tramites/'+data;
        }   
        
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const getProductsList = async () => { 
    
    const productInstance =  ProductService.getInstance();
                        
    await productInstance.getProductsList()
    .then((data:any) => {
        if(data){
          setProducts(data);
        }   
        
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const getAnnouncements = async () => { 
    
    const announcementInstance =  AnnouncementService.getInstance();
                        
    await announcementInstance.getActiveAnnoucements()
    .then((data:any) => {
        if(data){
          setAnnouncements(data);
        }   
        
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const getRankingConsultants = async (year:number, month:number) => { 
    
    const reportInstance =  ReportService.getInstance();
                        
    await reportInstance.getRankingConsultants({year: year, month: month, type: 1})
    .then((data:any) => {
        if(data){
          setRanking(data);
        }   
        
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const getRankingProducts = async (year:number) => { 
    
    const reportInstance =  ReportService.getInstance();
                        
    await reportInstance.getRankingProducts({year: year})
    .then((data:any) => {
        if(data){
          setRankingProducts(data);
        }   
        
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const handleMouseEnter = useCallback(
    (o:any) => {
      const { dataKey } = o;

      setOpacity({ ...opacity, [dataKey]: 0.5 });
    },
    [opacity, setOpacity]
  );

  const handleMouseLeave = useCallback(
    (o:any) => {
      const { dataKey } = o;
      setOpacity({ ...opacity, [dataKey]: 1 });
    },
    [opacity, setOpacity]
  );

  const handleCreateProcedure = (id:number, idAccount:number) => {
    insertProcedure(id, idAccount);
  };

  useEffect(() => {
    getRankingConsultants(2022,0);
    getRankingProducts(2022);
    getProductsList();
    getAnnouncements();
  }, []);

  

  return (
    <MainLayout> 
     
        <Grid container  >
          <Grid item xs={12} sx={{ 
                  padding: '15px'
              }}>
             <Grid container  sx={{ 
                backgroundColor: '#fff', padding: '15px', borderRadius: '15px',
                flexDirection: 'column',
                alignItems: 'space-between'}}>
              <h1>¡Bienvenido {nameUser}!</h1>
              <TextMobileStepper items={announcements} />
              
              
                <Grid container  sx={{ 
                justifyContent: 'space-around'}}>
                  {products.map((item:any) => 
                  (
                    <Grid item  sx={{ 
                      textAlign:'center', color:'#fff',border: '1px solid #C8965C',
                        padding: '0px', borderRadius: '10px',width: '150px',margin:'8px'
                      }}
                      >
                        <Button 
                        className={classes.addProduct}
                          onClick={() => handleCreateProcedure(item.idProduct, 0) } 
                          startIcon={<img src={process.env.PUBLIC_URL + '/'+ item.image} width='100%' className={classes.buttonIcon}/>}
                          >
                        
                        <Typography 
                          className={classes.productName}
                          >{ item.name.trim().length < 25 ? item.name : item.name.trim().slice(0, 15).concat('...')}</Typography>
                        </Button>
                      </Grid>
                      
                  ))
                }
                   
                
                </Grid>
              </Grid>
          </Grid>
        
            <Grid item xs={12} md={6} sx={{ 
                padding: '15px'
            }}>
               <Grid container  sx={{ 
                backgroundColor: '#fff', padding: '15px'}}>
              <h3>Ranking por cartera</h3>
              <ResponsiveContainer width="95%" height={300}>
            <BarChart
                  width={400}
                  height={300}
                  data={ranking}
                  layout="vertical"
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type='number' />
                  <YAxis type='category' dataKey='consultant' />
                  <Tooltip />
                  <Legend />
                  <Bar name="Monto" dataKey="amount" fill="#8884d8" />
                </BarChart>
                </ResponsiveContainer>
                </Grid>
        
          </Grid>
          <Grid item xs={12} md={6} sx={{ 
                padding: '15px'
            }}>
              <Grid container  sx={{  
                backgroundColor: '#fff', padding: '15px'}}>
              <h3>Ranking por cantidad de cuentas</h3>
              <ResponsiveContainer width="95%" height={300}>
            <BarChart
                  data={ranking}
                  layout="vertical"
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type='number' />
                  <YAxis type='category' dataKey='consultant' />
                  <Tooltip />
                  <Legend />
                  <Bar name="Total cuentas" dataKey="accounts" fill="#82ca9d" />
                </BarChart>
                </ResponsiveContainer>
              
              </Grid>
          </Grid>
          <Grid item xs={12} md={12} sx={{ 
                padding: '15px'
            }}>
              <Grid container  sx={{  
                backgroundColor: '#fff', padding: '15px'}}>
              <h3>Productos colocados</h3>
              <ResponsiveContainer width="95%" height={300}>
              <LineChart
                height={300}
                data={productsRanking}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
                <Line
                  type="monotone"
                  dataKey="product1"
                  name="Pardus Fixed Income Bond"
                  strokeOpacity={opacity.product1}
                  stroke="#0088FE"
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="product2"
                  name="Woodville Ate Loan Note"
                  strokeOpacity={opacity.product2}
                  stroke="#00C49F"
                />
                 <Line
                  type="monotone"
                  dataKey="product3"
                  name="Linklease Finance PLC"
                  strokeOpacity={opacity.product3}
                  stroke="#FFBB28"
                />
                 <Line
                  type="monotone"
                  dataKey="product5"
                  name="Godwin-Series 1"
                  strokeOpacity={opacity.product5}
                  stroke="#1a7e9a"
                />
                 <Line
                  type="monotone"
                  dataKey="product6"
                  name="Godwin-Series 2"
                  strokeOpacity={opacity.product6}
                  stroke="#00dcce"
                />
                 <Line
                  type="monotone"
                  dataKey="product7"
                  name="Ceminvest"
                  strokeOpacity={opacity.product7}
                  stroke="#ff6c67"
                />
                 <Line
                  type="monotone"
                  dataKey="product8"
                  name="Propifi"
                  strokeOpacity={opacity.product8}
                  stroke="#ffbb43"
                />
              </LineChart>
                </ResponsiveContainer>
              
              </Grid>
          </Grid>
        </Grid>
        </MainLayout>
  )
}


export default DashboardAsesor
