import { api } from "../api";
import ActionType from "../utils/customTypes";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class CommissionService extends api {

    private static classInstance?: CommissionService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
     
    public static getInstance() {
        let token = cookies.get('stkc');
        if (!this.classInstance) {
            this.classInstance = new CommissionService(token!==undefined?token:'', 'GET');
        }
        
        return this.classInstance;
    }   
     
    public getMyCommissions = (data:any) => this.instance.post('/commissions/get-my-commissions',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getCommissions = (data:any) => this.instance.post('/commissions/get-commissions',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getCommission = (data:any) => this.instance.post('/commissions/get-commission',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public updateCommission = (data:any) => this.instance.post('/commissions/update-commission',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });


}