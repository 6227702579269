import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  ComposedChart
} from "recharts";

const formatMoney = (amount:any) => {
    return amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }



export default function MixedBarChart(props:any) {
    const data = props.data;

    const checkProduct1 = data.some((obj:any) => obj.product1 > 0);
    const checkProduct2 = data.some((obj:any) => obj.product2 > 0);
    const checkProduct3 = data.some((obj:any) => obj.product3 > 0);
    const checkProduct4 = data.some((obj:any) => obj.product4 > 0);
    const checkProduct5 = data.some((obj:any) => obj.product5 > 0);
    const checkProduct6 = data.some((obj:any) => obj.product6 > 0);
    const checkProduct7 = data.some((obj:any) => obj.product7 > 0);
    const checkProduct8 = data.some((obj:any) => obj.product8 > 0);
    const checkProduct9 = data.some((obj:any) => obj.product9 > 0);
    const checkProduct10 = data.some((obj:any) => obj.product10 > 0);
    const checkProduct11 = data.some((obj:any) => obj.product11 > 0);
    const checkProduct12 = data.some((obj:any) => obj.product12 > 0);
    const checkProduct14 = data.some((obj:any) => obj.product14 > 0);
    const checkProduct15 = data.some((obj:any) => obj.product15 > 0);
    const checkProduct16 = data.some((obj:any) => obj.product16 > 0);
    const checkProduct19 = data.some((obj:any) => obj.product19 > 0);
  return (
    <ResponsiveContainer width="95%" height={400}>
    <ComposedChart
      data={data}
      ref={props.reference}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip formatter={(value:any) => {
                return `USD: ${formatMoney(value)}`;
            }} />
      <Legend />
      {/* <Area type="monotone" dataKey="totalCumulated" fill="#8884d8" stroke="#8884d8" name="Ingreso Acumulado" /> */}
      {checkProduct1 && (<Bar dataKey="product1" fill="#03516c" name="Pardus Fixed Income Bond"/>)}
      {checkProduct2 && (<Bar dataKey="product2" fill="#00C49F" name="Woodville Ate Loan Note (2 años)" />)}
      {checkProduct3 && (<Bar dataKey="product3" fill="#C8965C" name="Linklease Finance PLC" />)}
      {checkProduct4 && (<Bar dataKey="product4" fill="#FF8042" name="Bodysmart Finance Limited" />)}
      {checkProduct5 && (<Bar dataKey="product5" fill="#1a7e9a" name="Godwin-Series 1" />)}
      {checkProduct6 && (<Bar dataKey="product6" fill="#00dcce" name="Godwin-Series 2" />)}
      {checkProduct7 && (<Bar dataKey="product7" fill="#ff6c67" name="Ceminvest" />)}
      {checkProduct8 && (<Bar dataKey="product8" fill="#ffbb43" name="Propifi" />)}
      {checkProduct9 && (<Bar dataKey="product9" fill="#d99e37" name="Propifi" />)}
      {checkProduct10 && (<Bar dataKey="product10" fill="#c33e39" name="RedHat Capital" />)}
      {checkProduct11 && (<Bar dataKey="product11" fill="#256e87" name="Woodville Ate Loan Note (1 año)" />)}
      {checkProduct12 && (<Bar dataKey="product12" fill="#9B9B9B" name="Linklease Finance PLC (3 años)" />)}
      {checkProduct14 && (<Bar dataKey="product14" fill="#7BAEF6" name="Zenith Energy (1 año)" />)}
      {checkProduct15 && (<Bar dataKey="product15" fill="#611F69" name="Zenith Energy (2 años)" />)}
      {checkProduct16 && (<Bar dataKey="product16" fill="#3C6298" name="London Bonds" />)}
      {checkProduct19 && (<Bar dataKey="product19" fill='#a6a6a6' name="William John Bond" />)}
      
    </ComposedChart>
    </ResponsiveContainer>
  );
}
