import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { 
  Button,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Checkbox,
  FormGroup,

} from '@mui/material';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import CardProduct from "../simulador/CardProduct";
import LinearProgressWithLabel from '../LinearProgressWithLabel';
import { Label } from 'recharts';

const formatMoney = (amount:any) => {
  return amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const BonosForm = (props:any) => {
  const products = props.products;

  const [includeRetention, setIncludeRetention] = useState(true); // Estado del checkbox de retención
  const [includeExpenses, setIncludeExpenses] = useState(true); // Estado del checkbox de cupón neto
  

  return (
    <Grid container  sx={{ 
        backgroundColor: '#fff', padding: '15px', borderRadius: '15px',
        flexDirection: 'column',
        textAlign: 'center'}}>
          <LinearProgressWithLabel value={props.percentage} oldvalue={0} text ={props.percentage + "%"}/>
             
        <h1>Bonos corporativos e inversión</h1>
        <br></br>
        <p>Abajo hay un resumen muy breve de cada bono: Nombre del bono, porcentaje del cupón de interés anual, frecuencia del pago del cupón y fecha de vencimiento del bono.
Seleccione los bonos corporativos y cuánto quiere invertir en cada uno.
</p><p>Escoger por:</p>
      <FormControl sx={{justifyContent: 'center'}}>
        <RadioGroup
            aria-labelledby="options"
            name="type"
            value={props.data.type}
            onChange={props.handleChange}
            sx={{flexDirection: 'row', justifyContent: 'center'}}
        >
            <FormControlLabel value={0} control={<Radio />} label="Monto exacto (USD)" />
            <FormControlLabel value={1} control={<Radio />} label="Asignación (%)" />
        </RadioGroup>
        </FormControl>
        <br></br>
        <Grid item xs={12}>
          <Grid container  sx={{ 
          backgroundColor: '#fff', padding: '15px', borderRadius: '15px',
          flexDirection: 'row', justifyContent: 'space-between',
          textAlign: 'center'}}>
            <Typography sx={{fontWeight: 'bold'}}>
            Monto a invertir: USD {formatMoney(props.data.amount)}
            </Typography>
            <Typography sx={{color:'#f44336', fontWeight: 'bold'}}>
            Monto pendiente de asignación: USD {formatMoney(props.data.amount - props.data.totalAssigned)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={12}>
        <Grid container  sx={{ 
            backgroundColor: '#f3f3f3', padding: '15px', borderRadius: '15px',
            flexDirection: 'row',
            textAlign: 'center', justifyContent: 'space-around', alignItems: 'center'}}>
            {products.map((item:any, index:number) => (
                <Grid item md={6} sx ={{boder: '1px solid #000', padding: '15px'}}
                key={'prodgr'+item.idProduct}
                >
                  <CardProduct 
                  key={'prod'+item.idProduct}
                  product={item}
                  index={index}
                  option = {props.data.type}
                  handleChange={props.handleChange}
                  handleChangeProductAmount={props.handleChangeProductAmount}
                  handleChangeProductAssignment={props.handleChangeProductAssignment}
                  />
                </Grid>
            ))}
         </Grid>
         </Grid>
        
           
       
        
         <Grid item>
           <br></br>           
           <Grid dir="rtl">            
            <h3>Incluir en la simulación</h3>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={props.data.includeExpenses} onChange={props.handleIncludeExpensesChange} />}
                  label="Gasto (%Costo administrativo)"
                />
                <FormControlLabel
                  control={<Checkbox checked={props.data.includeRetention} onChange={props.handleIncludeRetentionChange} />}
                  label="Retención IR SUNAT 5%"
                />
              </FormGroup>              
            </FormControl>  
          </Grid>         
           

          <Grid container  sx={{ 
          flexDirection: 'row',
          justifyContent: 'space-between'}}>
            <Button variant="outlined" sx={{width: '200px'}} 
              onClick={props.handlePrevStep}
              > 
               <NavigateBeforeOutlinedIcon/> Anterior
               
            </Button>
            <Button variant="contained" sx={{width: '200px'}} 
              onClick={props.simulate}
              > Simular
                <NavigateNextOutlinedIcon/>
            </Button>
          </Grid>
        </Grid>
    </Grid>
  )
  
}


export default BonosForm
