
import MainLayout from "../../../../src/layout/MainLayout";
import CustomizedTables from "../../../../src/components/CustomizedTables";
import {identity, headCells, bodyCells, actions} from "../../../../src/components/data/cuentas";
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Cookies from 'universal-cookie';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {SimulatorService} from '../../../../src/business/SimulatorService';
import { 
  Button,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  Avatar,
  Link
} from '@mui/material';
import fileDownload from 'js-file-download';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Item } from "framer-motion/types/components/Reorder/Item";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


const StyledTableCell = styled(TableCell)(({ theme} :any ) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));


const cookies = new Cookies();

const Inventario = () => {
 
  let link = '';

  let role=cookies.get('rol');
  switch(role)
  {
      case '1': link='/backoffice/inventario/';
          break;
      case '2': link='/asesores/inventario/';
          break;
      default:
          link = '/inversor/inventario/';
  }

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  
  const getProducts= async () => { 

    const instance = SimulatorService.getInstance();
    setLoading(true);
    await instance.getProducts()
    .then((data:any) => {
        if(data){
          
          setData(data);
          setLoading(false);
        }   
        
    })
    .catch((err:any) => {
      console.log(err);
      setLoading(false);
    });

    
  }
  function base64ToFile(base64: any) {
    return fetch(base64)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], '', { type: '' }));;
    ;
  }

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  

 

  

  useEffect(() => {
    getProducts();
  }, []);

  


   const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  


  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'left' }}>

      </Box>
      <br></br>
     
        <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
    >
      <TableContainer >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
              <TableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="center">Cupón Anual</StyledTableCell>
                  <StyledTableCell align="center">Plazo / Fecha de vencimiento</StyledTableCell>
                  <StyledTableCell align="center">Frecuencia de pago</StyledTableCell>
                  <StyledTableCell align="center">Meses de pago</StyledTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {data.map((row:any) => (
                  
                  <TableRow key={row.id}>
                  <StyledTableCell align="center">
                  <Link href={link+row.idProduct}>
                    <img src={process.env.PUBLIC_URL + row.image}  width="120" height="auto" /><br></br>
                    {row.title}
                  </Link>
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.annual} %</StyledTableCell>
                  <StyledTableCell align="center">{moment(row.expired).format("YYYY-MM-DD")}</StyledTableCell>
                  <StyledTableCell align="center">{row.period}</StyledTableCell>
                  <StyledTableCell align="center">{row.paymentMonths}</StyledTableCell>
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
      
    </Box>
  
  
    </MainLayout>
  )
}


export default Inventario
