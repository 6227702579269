import { api } from "../api";
import ActionType from "../utils/customTypes";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class PaymentService extends api {

    private static classInstance?: PaymentService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
     
    public static getInstance() {
        let token = cookies.get('stkc');
        if (!this.classInstance) {
            this.classInstance = new PaymentService(token!==undefined?token:'', 'GET');
        }
        
        return this.classInstance;
    }   
    //#section GET
   
    public getMyPayments = (data:any) => this.instance.post('/payments/get-my-payments',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getPayments = (data:any) => this.instance.post('/payments/get-payments',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    

    //#end section GET
    
    




}