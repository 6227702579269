import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, TextField, Grid } from '@mui/material';
import Index from 'pages';
import InputAdornment from '@mui/material/InputAdornment';

export default function CardProduct(props:any) {
  const product = props.product;
  const index = props.index;
  return (
      <>
      {product.comingSoon == false && (
       <Card>
        <CardMedia
          component="img"
          height="100"
          image={product.image}
          alt={product.title}
          sx={{ borderBottom:'1px solid #efefed',backgroundSize: 'contain', objectFit: 'contain' }}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {product.title}
          </Typography>
          
          <Typography variant="body2" color="text.secondary">
            Cupón Anual : {product.annual} %
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Pago {product.period} : {product.bonnus} %
          </Typography>
          <br></br>
        
            <Grid container sx={{justifyContent:'space-between', alignItems: 'center', textAlign: 'right'}}>
            
              <Typography variant="body2" color="text.primary">
                Monto de inversión :
              </Typography>
              {props.option == 0 ? 
              <TextField size="small" color="primary" variant='outlined' 
                placeholder='Ingrese monto USD' value={product.investedAmount }
                onChange={(e) => props.handleChange(e)}
                onBlur={(e) => props.handleChangeProductAmount(e)}
                name={`products[${index.toString()}].investedAmount`}
                inputProps={{
                  'idproduct':product.idProduct
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">USD</InputAdornment>,
                }}
                sx={{background: '#fff', textAlign: 'right'}}
                /> : <Typography variant="body2" color="text.primary" sx={{fontWeight:'bold'}}>
                 USD {product.investedAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Typography>}
            </Grid>
            <Grid container sx={{justifyContent:'space-between', alignItems: 'center'}}>
              <Typography variant="body2" color="text.primary">
                Asignación de Cartera:
              </Typography>
              {props.option == 1 ? 
              <TextField size="small" color="primary" variant='outlined' 
                placeholder='Ingrese porcentaje' value={product.assignment }
                onChange={(e) => props.handleChange(e)}
                onBlur={(e) => props.handleChangeProductAssignment(e)}
                name={`products[${index.toString()}].assignment`}
                inputProps={{
                  'idproduct':product.idProduct
                  }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                sx={{background: '#fff'}}
                /> : <Typography variant="body2" color="text.primary" sx={{fontWeight:'bold'}}>
                {product.assignment}%
              </Typography>}
            </Grid>
            <Grid container sx={{justifyContent:'space-between'}}>
              <Typography variant="body2" color="text.primary">
                Fecha de vencimiento:
              </Typography>
              <Typography variant="body2" color="text.primary" sx={{fontWeight:'bold'}}>
                {product.expired}
              </Typography>
            </Grid>
            <Grid container sx={{justifyContent:'space-between', alignItems: 'center'}}>
              <Typography variant="body2" color="text.primary">
                % Costo administrativo:
              </Typography>
              <TextField size="small" color="primary" variant='outlined' 
                type="number"
                placeholder='Ingrese porcentaje' value={product.administrativeCost }
                onChange={(e) => props.handleChange(e)}
                name={`products[${index.toString()}].administrativeCost`}
                inputProps={{
                  'idproduct':product.idProduct
                  }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                sx={{background: '#fff'}}
                />
            </Grid>
        </CardContent>
        </Card>
        )}
        {product.comingSoon == true && (
        <Card >
        <CardMedia
          component="img"
          height="100"
          image={product.image}
          alt={product.title}
          sx={{ borderBottom:'1px solid #efefed',backgroundSize: 'contain', objectFit: 'contain' }}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {product.title}
          </Typography>
          
          <Typography variant="body2" color="text.secondary">
            Cupón Anual : - %
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Pago  : - %
          </Typography>
          <br></br>
        
            <Grid container sx={{justifyContent:'space-between', alignItems: 'center', textAlign: 'right'}}>
            
              <Typography variant="body2" color="text.primary">
                Monto de inversión :
              </Typography>
              {props.option == 0 ? 
              <TextField size="small" color="primary" variant='outlined' 
                disabled
                placeholder='Ingrese monto USD' value={product.investedAmount }
                onChange={(e) => props.handleChange(e)}
                onBlur={(e) => props.handleChangeProductAmount(e)}
                name={`products[${index.toString()}].investedAmount`}
                inputProps={{
                  'idproduct':product.idProduct
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">USD</InputAdornment>,
                }}
                sx={{background: '#fff', textAlign: 'right'}}
                /> : <Typography variant="body2" color="text.primary" sx={{fontWeight:'bold'}}>
                 -
              </Typography>}
            </Grid>
            <Grid container sx={{justifyContent:'space-between', alignItems: 'center'}}>
              <Typography variant="body2" color="text.primary">
                Asignación de Cartera:
              </Typography>
              {props.option == 1 ? 
              <TextField size="small" color="primary" variant='outlined' 
                disabled
                placeholder='Ingrese porcentaje' value={product.assignment }
                onChange={(e) => props.handleChange(e)}
                onBlur={(e) => props.handleChangeProductAssignment(e)}
                name={`products[${index.toString()}].assignment`}
                inputProps={{
                  'idproduct':product.idProduct
                  }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                sx={{background: '#fff'}}
                /> : <Typography variant="body2" color="text.primary" sx={{fontWeight:'bold'}}>
                -
              </Typography>}
            </Grid>
            <Grid container sx={{justifyContent:'space-between'}}>
              <Typography variant="body2" color="text.primary">
                Fecha de vencimiento:
              </Typography>
              <Typography variant="body2" color="text.primary" sx={{fontWeight:'bold'}}>
                -
              </Typography>
            </Grid>
        </CardContent>
      </Card>
  )}
  </>
    
  );
}