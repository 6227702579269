import logo from './logo.svg';
import './App.css';
import Roots from '../src/rutas/roots.tsx'
import { ThemeProvider } from "@mui/material/styles";
import theme from "../src/themes/theme";
import { ToastContainer, toast } from 'react-toastify';
import { Provider } from 'react-redux';
import { store } from '../src/store';
import 'react-toastify/dist/ReactToastify.min.css';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
        <Roots />
      </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
