
export interface Data {
    code:string;
    names: string;
    lastName: string;
    secondLastName: string;
    docType: string;
    docNumber: string;
    role:string;
    commission:number;
    approvedAccounts:number;
    status:string
  }
 
  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  interface BodyCell {
    id: string;
  }

  interface Identity{
    id: string
  }

  interface Action {
    disable: boolean;
    type: string;
    tooltip: string;
  }
  
  export const headCells: readonly HeadCell[] = [
    {
      id: 'code',
      numeric: false,
      disablePadding: false,
      label: 'Código',
    },
    {
      id: 'names',
      numeric: false,
      disablePadding: false,
      label: 'Nombres',
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: 'Primer Apellido',
    },
    {
      id: 'secondLastName',
      numeric: false,
      disablePadding: false,
      label: 'Segundo Apellido',
    },
    {
      id: 'docType',
      numeric: false,
      disablePadding: false,
      label: 'Tipo de documento',
    },
    {
      id: 'docNumber',
      numeric: false,
      disablePadding: false,
      label: 'Nro. de documento',
    },
    {
      id: 'role',
      numeric: false,
      disablePadding: false,
      label: 'Rol',
    },
    {
      id: 'commission',
      numeric: false,
      disablePadding: false,
      label: '% comisión',
    },
    {
      id: 'approvedAccounts',
      numeric: false,
      disablePadding: false,
      label: 'Cuentas aprobadas',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Estado',
    },
  ];

  export const bodyCells: readonly BodyCell[] = [
    {
      id: 'code',
    },
    {
      id: 'names',
    },
    {
      id: 'lastName',
    },
    {
      id: 'secondLastName',
    },
    {
      id: 'docType',
    },
    {
      id: 'docNumber',
    },
    {
      id: 'role',
    },
    {
      id: 'commission',
    },
    {
      id: 'approvedAccounts',
    },
    {
      id: 'status',
    },
  ];

  export const identity: Identity = 
  {
    id: 'idUser',
  }
;

export const actions: readonly Action[] = [
  {
    disable: false,
    type: 'edit',
    tooltip: 'Editar usuario'
  },
  {
    disable: false,
    type: 'desactivate',
    tooltip: 'Desactivar usuario'
  }
];


  
  