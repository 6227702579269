
import MainLayout from "../../../layout/MainLayout";
import ProcedureCard from "../../../components/cards/ProcedureCard";
import VerticalTabs from "../../../components/tramites/ProcedureTab";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {  toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import {ProcedureService} from '../../../../src/business/ProcedureService';
import {DocumentService} from '../../../../src/business/DocumentService';
import {UserService} from '../../../../src/business/UserService';
import {ListService} from '../../../../src/business/ListService';
import moment from 'moment';
import fileDownload from 'js-file-download';
import {
  CircularProgress, 
  Grid
} from '@mui/material';
import jsPDF, { GState } from "jspdf";
import autoTable from 'jspdf-autotable';
// third party
import * as Yup from 'yup';
import { Item } from "framer-motion/types/components/Reorder/Item";

const today = moment().format('dd/mm/YYYY HH:mm');

let doc = new jsPDF('portrait','pt','a4');
doc.setFontSize(11);

const initialList: any = [
  {
    datePayment: null,
    amount: 0
  }  
];
const initialSchedule: any =   {
    items: [],
    totalAvailable: 0
  }  
;


const initialValues: any = {
    idProcedure: 0,
    code: '',
    account: '',
    investorNames: '',
    consultant: '',
    procedureType: '',
    product: '',
    createdAt: null,
    updatedAt: null,
    idStatus: 0,
    dsa:false,
    abc:false,
    statement:false,
    actionButton: 0,
    investor: {
      names: '',
      lastName: '',
      secondLastName: '',
      gender: '',
      email: '',
      idDocType: 0,
      docType: '',
      docNumber: null,
      idLanguage: 1,
      language: '',
      birthday: today,
      ocupation: '',
      idBirthCountry: 111,
      birthCountry: '',
      nationality: '',
      cellphone: '',
      idResidenceCountry: 0,
      residenceCountry: '',
      idDepartment: -1,
      department: '',
      idProvince: -1,
      province: '',
      idDistrict: -1,
      district: '',
      city: '',
      postalCode: '',
      idJurisdictionCountry: 111,
      jurisdictionCountry: '',
      jurisdictions: [
      ]
    },
    additionalInfo: [
        ],
    finantialContribution: {
      idContributionCurrency: 1,
      contributionCurrency: '',
      amount: '',
      administrativeCost: 0.0,
      idFrecuency: 1,
      idSerialNumber: 0,
      serialNumber: '',
      dueDate: null,
      endYears: 0,
      coupon: 0,
      paymentDay: 1,
      idInvestorType: 0,
      investorType: '',
      originWealth1: false,
      originWealth2: false,
      originWealth3: false,
      originWealth4: false,
      originWealth5: false,
      originWealth6: false,
      originWealth7: false,
      originWealth8: false,
      originWealth9: false,
      originWealth10: false,
      originWealth11:false,
      specification: null,
      idBank: 0,
      bank: '',
      cci: '',
      bankAccountNumber: '',
      bankAccountTitular: '',
      bankAccountComments: ''
    },
    documents: [
      
    ]
 
};

const ProcedureSchema = Yup.object().shape({
  idDocType: Yup.string().required("*Campo obligatorio"),
  docNumber: Yup.string().required("*Campo obligatorio"),
  names: Yup.string().required("*Campo obligatorio"),
  lastName: Yup.string().required("*Campo obligatorio"),
  secondLastName: Yup.string().required("*Campo obligatorio"),
});

const totalFields = 47;

function calculateAdvance(procedure:any)
{
  let total = 0;

  const allWithClass = Array.from(
    document.querySelectorAll('div.advance')
  );
  

  allWithClass.forEach(element => {
   
    if(element.querySelectorAll("input").length > 0)
    {
      if(element.querySelectorAll("input")[0].value!==null &&element.querySelectorAll("input")[0].value!=="" )
      {
        total++;
      }
    }
    if(element.querySelectorAll("select").length > 0)
    {
      if(element.querySelectorAll("select")[0].value!==null &&element.querySelectorAll("select")[0].value!=="" )
      {
        total++;
      }
    }
    
  });
  let questions = procedure.additionalInfo;
  questions.forEach((q:any) => {
    if((q.idTypeItem==2 || q.idTypeItem==4) && q.answer!="")
    {
      total++
    }
  })
  return total * 100 / 47;
}


const Tramite = () => {
  let params = useParams();
  let id = params.tramitesId;
  const [procedure, setProcedure] = useState(initialValues);
  const [isFetching, setIsFetching] = React.useState(true);
  const [countries, setCountries] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [docType, setDocType] = useState(0);
  const [index, setIndex] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [checkUser, setCheckUser] = useState(false);
  const [scheduleList, setScheduleList] = useState(initialList);
  const [open, setOpen] = React.useState(false);
  const [scheduleProcedure, setScheduleProcedure] = useState(initialSchedule);
   
  
  const getTramite = async (id:string) => {

    const tramiteInstance = ProcedureService.getInstance();
                        
    await tramiteInstance.getProcedure({idProcedure:parseInt(id)})
    .then((data:any) => {
        if(data){
            
            setProcedure(data);
                 
            /*setPercentage(calculateAdvance(procedure));*/
                      
        }  
    })
    .catch((err) => {
        console.log(err);
    });
    setIsFetching(false);
  } 

  const getUbigeo = async (id:number, level:string) => {

    const ubigeoInstance = ListService.getInstance();
                        
    await ubigeoInstance.getUbigeo({idParent:id})
    .then((data:any) => {
        if(data){
          switch(level)
          {
            case 'countries':
              setCountries(data);
              break;
            case 'departments':
              setDepartments(data);
              break;
            case 'provinces':
              setProvinces(data);
              break;
            case 'districts':
              setDistricts(data);
              break;
          }            
        }  
    })
    .catch((err) => {
        console.log(err);
    });
 
    
  }

  const getInvestor = async (idDocType:number, docNumber:string) => {

    const userInstance = UserService.getInstance();
                        
    await userInstance.getInvestor({idDoctype: idDocType, docNumber: docNumber})
    .then((data:any) => {
        if(data){

          let copy = {...procedure};
          let newData = {...copy.investor,
            ['idDocType']: idDocType,
            ['docNumber']: docNumber,
            ['names']: data.names,
            ['lastName']: data.lastName,
            ['secondLastName']: data.secondLastName,
            ['gender']: data.gender,
            ['email']: data.email,
            ['docType']: data.docType,
            ['idLanguage']: data.idLanguage,
            ['language']: data.language,
            ['birthday']: data.birthday,
            ['ocupation']: data.ocupation,
            ['idBirthCountry']: data.idBirthCountry,
            ['birthCountry']: data.birthCountry,
            ['nationality']: data.nationality,
            ['cellphone']: data.cellphone,
            ['idResidenceCountry']: data.idResidenceCountry,
            ['residenceCountry']: data.residenceCountry,
            ['idDepartment']: data.idDepartment,
            ['department']: data.department,
            ['idProvince']: data.idProvince,
            ['province']: data.province,
            ['idDistrict']: data.idDistrict,
            ['district']: data.district,
            ['city']: data.city,
            ['postalCode']: data.postalCode
          };
          copy.investor = newData;
          
          setProcedure(copy);
            
            setCheckUser(true);                      
        }  
    })
    .catch((err) => {
        console.log(err);
    });
    setIsFetching(false);
  } 

  const getPaymentSchedule = async (id:number, amount:number, investmentDate: string, approxPaymentDay: number, administrativeCost:number) => {

    const tramiteInstance = ProcedureService.getInstance();
                        
    await tramiteInstance.getPaymentSchedule({idProcedure:id, amount: amount, investmentDate: investmentDate, approxPaymentDay: approxPaymentDay, administrativeCost: administrativeCost})
    .then((data:any) => {
        if(data){            
          setScheduleList(data);  
          setOpen(true); 
        }  
    })
    .catch((err) => {
        console.log(err);
    });
  } 

  const getScheduleProcedure = async (id:number) => {

    const tramiteInstance = ProcedureService.getInstance();
                        
    await tramiteInstance.getScheduleProcedure({idProcedure:id})
    .then((data:any) => {
        if(data){            
          setScheduleProcedure(data);  
          
        }  
    })
    .catch((err) => {
        console.log(err);
    });
  } 

  const updatePaymentSchedule = async (id:number, amount:number, investmentDate: string) => {

    const tramiteInstance = ProcedureService.getInstance();
                        
    await tramiteInstance.updatePaymentSchedule({idProcedure:id, amount: amount, investmentDate: investmentDate, payments: scheduleList})
    .then((data:any) => {
          getScheduleProcedure(id);         
          setOpen(false); 
          let copy = {...procedure};
          let newData = {...copy.finantialContribution,
            ['generated']: true
          };
          
          copy.finantialContribution = newData;
          setProcedure(copy);
        
    })
    .catch((err) => {
        console.log(err);
    });
  } 

  const updateProcedure = async () => {

    const updateInstance = ProcedureService.getInstance();
                        
    await updateInstance.UpdateProcedure(procedure)
    .then((data:any) => {
        getTramite(procedure.idProcedure);
    })
    .catch((err) => {
        console.log(err);
    });
 
    
  }

  const sendProcedure = async (status:number) => {

    const sendInstance = ProcedureService.getInstance();
                        
    await sendInstance.UpdateStatusProcedure({idProcedure: procedure.idProcedure, idStatus: status})
    .then((data:any) => {
        window.location.href='/backoffice/tramites';
    })
    .catch((err) => {
        console.log(err);
    });
  }

  useEffect(() => {
    getTramite(id!==undefined ? id : '0');
    getUbigeo(0, 'countries');
    getScheduleProcedure(id!==undefined ? parseInt(id) : 0);
    
  }, [])

  useEffect(() => {
    if(procedure.investor.idDocType>0 && procedure.investor.docNumber !==null)
    {
      setCheckUser(true);
    } 
  }, [procedure.investor.idDocType])

  useEffect(() => {
    getUbigeo(parseInt(procedure.investor.idResidenceCountry), 'departments');
    setProvinces([]);
    setDistricts([]);
    let copy = {...procedure};
    let newData = {...copy.investor,
      ['idDistrict']: procedure.investor.idResidenceCountry,
      ['idProvince']: procedure.investor.idResidenceCountry,
      ['idDepartment']: procedure.investor.idResidenceCountry
    };
    copy.investor = newData;
    setProcedure(copy);
    console.log('cambio pais');
  }, [procedure.investor.idResidenceCountry])

  useEffect(() => {
    getUbigeo(parseInt(procedure.investor.idDepartment), 'provinces');
    setDistricts([]);
    let copy = {...procedure};
    let newData = {...copy.investor,
      ['idDistrict']: null,
      ['idProvince']: null
    };
    
    copy.investor = newData;
    setProcedure(copy);
    console.log('cambio cambio provincia')
  }, [procedure.investor.idDepartment])

  useEffect(() => {
    getUbigeo(parseInt(procedure.investor.idProvince), 'districts');
    let copy = {...procedure};
    let newData = {...copy.investor,
      ['idDistrict']: null
    };
    copy.investor = newData;
    setProcedure(copy);
    console.log('cambio distrito')
  }, [procedure.investor.idProvince])

  useEffect(() => {
    let copy = {...procedure};
   
    setProcedure(copy);
    console.log('cambio distrito')
  }, [procedure.investor.idDistrict])

  const searchClient = () => {
    let copy = {...procedure};    
    copy.investor = initialValues.investor;
    setProcedure(copy);
    getInvestor(procedure.investor.idDocType, procedure.investor.docNumber);
  }

  const handleChange= (event: any) => {
    
    const { name, value} = event.target;
    let isInvestor = false;
    let isFinantial = false;
    let newName = '';
    let newData;
    let copy = {...procedure};

    if(name.includes('investor')){
      isInvestor = true;
      newName = name.replace("investor.", "");
    }
    else{
      if(name.includes('finantial')){
        isFinantial = true;
        newName = name.replace("finantial.", "");
      }
    }
    
    if(isInvestor==true)
    {
      switch(event.target.type)
      {
        case 'select-one':
          const { selectedIndex} = event.target;
            if(name=='investor.idBirthCountry'){
              let nation = event.target[selectedIndex].getAttribute('data-nationality');
              
              newData = {...copy.investor,
                [newName]: parseInt(value),
                'nationality': nation
              };
              
            }
            else{
              if(name=='investor.gender')
              {
                newData = {...copy.investor,
                  [newName]: value
                };
              }
              else
              {
                newData = {...copy.investor,
                  [newName]: parseInt(value)
                };
              }
              
                
            }
          break;
        case 'checkbox':
          newData = {...copy.investor,
              [newName]: event.target.checked
          };
         
          break;
        default:
          if(newName==='birthday' && value==='')
          {
            newData = {...copy.investor,
              [newName]: null
            }
          }
          else{
            newData = {...copy.investor,
              [newName]: value
          }
         
        };
      }
      copy.investor = newData;
      setProcedure(copy);
      
    }
    else
    {
      if(event.target.hasAttribute('questionindex')){
        let questionindex = event.target.getAttribute('questionindex');
        let checked = '0';

        if(event.target.type==='checkbox')
        {
          checked = event.target.checked == true ? '1' : '0';
        }
        
        newData = {
              ...copy.additionalInfo[questionindex],
              answer: event.target.type==='checkbox' ? checked : value
          };
        copy.additionalInfo[questionindex] = newData;

        let valorCheck = checked == '1' ? true : false;
        switch(copy.additionalInfo[questionindex].idQuestion)
        {
          case 27:
            copy.finantialContribution.originWealth1 = valorCheck;
            break;
          case 28:
            copy.finantialContribution.originWealth2 = valorCheck;
            break;
          case 29:
            copy.finantialContribution.originWealth3 = valorCheck;
            break;
          case 30:
            copy.finantialContribution.originWealth4 = valorCheck;
            break;
          case 31:
            copy.finantialContribution.originWealth5 = valorCheck;
            break;
          case 32:
            copy.finantialContribution.originWealth6 = valorCheck;
            break;
          case 33:
            copy.finantialContribution.originWealth7 = valorCheck;
            break;
          case 34:
            copy.finantialContribution.originWealth8 = valorCheck;
            break;
          case 35:
            copy.finantialContribution.originWealth9 = valorCheck;
            break;
          case 36:
            copy.finantialContribution.originWealth10 = valorCheck;
            break;
          case 37:
            copy.finantialContribution.originWealth11 = valorCheck;
            break;
          case 38:
            copy.finantialContribution.specification = value;
            break;
        }
        
        setProcedure(copy);
      }
      else{
        if(isFinantial==true)
        {
          let val;
          if(event.target.type==='select-one' && value > 0)
          {
            val = parseInt(value);
          }
          else{
            if(newName=='amount' || newName=='administrativeCost'){
              val = parseFloat(value);
            }
            else{
              val = value;
            }
            
          }
          newData = {...copy.finantialContribution,
            [newName]: event.target.type==='checkbox' ? event.target.checked: val
          };
          copy.finantialContribution = newData;
          setProcedure(copy);
      
        }
        else{
          console.log('entro checkbox')
          setProcedure({
            ...procedure,
            [name]: event.target.type==='checkbox' ? event.target.checked : value
          });
        }
        
      }  
    }
    /*setPercentage(calculateAdvance(procedure))*/;
    
  };


  const handleChangePayment= (event: any) => {
    
    const { name, value} = event.target;
    let newData;
    let copy = [...scheduleList];

    
      if(event.target.hasAttribute('datePayment')){
        let dateIndex = event.target.getAttribute('datePayment');
  
        newData = {
              ...copy[dateIndex],
              datePayment: value
          };
        copy[dateIndex] = newData;

        setScheduleList(copy);
      
    
  }
};

  const handleSave= (event: any) => {
   
    ProcedureSchema
      .isValid(procedure.investor)
      .then(function(valid) {
        console.log(procedure.investor)
        if(valid==true)
        {
          updateProcedure();
        }
        else{
          toast.error('Ingresar los datos obligatorios *',{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            });
        }
        
      });
    
    
  };

  const handleClickShedule = (id:number, amount:number, investmentDate:string, approxPaymentDay:number, administrativeCost:number) => {
    getPaymentSchedule(id, amount, investmentDate,approxPaymentDay,administrativeCost);
  }

  const handleSaveShedule = (id:number, amount:number, investmentDate:string) => {
    updatePaymentSchedule(id, amount, investmentDate);
 
  }

  const handleClose= () => {
    setScheduleList([]); 
    setOpen(false);
 
  }

  function base64ToFile(base64: any) {
    return fetch(base64)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], '', { type: '' }));;
    ;
  }

  const downloadDocument = async (path:any, name:any) => { 

    const documentInstance = DocumentService.getInstance();
    
    await documentInstance.downloadDocument({folder: path, hash:name})
    .then((response:any) => {
        base64ToFile(response).then((file) => {
            
            fileDownload(file, name);
          });
          })
    .catch((err:any) => {
      console.log(err);
    
    });

    
  }

  const handleSend= (status:number) => {
    sendProcedure(status);
  };

    const handleFileChange = (e: any) => {
      const { value, name} = e.target;
      let index = e.target.getAttribute('data-index');
      let typeDoc = e.target.getAttribute('data-type');

      let currentFile = e.target.files[0];
    
      setDocType(typeDoc);
      setIndex(index);
      setSelectedFile(e.target.files[0]);

      let copy = {...procedure};
      let newData = {
          ...copy.documents[index],
          name: e.target.files[0].name
      };
      copy.documents[index] = newData;
      setProcedure(copy);
      
    };

    useEffect(() => {
        if(selectedFile!==undefined)
        {
          const uploadFile = async (id:number, idType:number) => {
            // Create an object of formData
          
          const formData = new FormData();
          formData.append('file', selectedFile!);
          formData.append('idProcedure', id.toString());
          formData.append('idDocumentType', idType.toString());
          let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)stkc\s*\=\s*([^;]*).*$)|^.*$/, "$1");
          
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
              'sessionToken':cookieValue
            },
          };
             
            const ubigeos = await axios.post(process.env.REACT_APP_BASE_URL+'procedures/update-procedure-document', formData, config).then(response => {
              if(response.status !== 200)
              {
                if(response.data.messages)
                {
                  toast.error(response.data.messages[0].description,{
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 0,
                    });
                }
               
              }
              else{
                if(response.data.messages)
                {
                    if(response.data.messages[0].code==='0')
                    {
                        toast.success('Archivo actualizado con éxito.',{
                            position: "top-right",
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: 0,
                            });
                        let copy = {...procedure};
                        let newData = {
                            ...copy.documents[index],
                            url: response.data.result
                        };
                        copy.documents[index] = newData;
                        setProcedure(copy);
                        
                    }
                    else{
                        toast.error(response.data.messages[0].description,{
                            position: "top-right",
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: 0,
                            });
                    }
                 
                }
                else{
                    toast.error('Hubo un problema.',{
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: 0,
                        });
                }
                
            }
             
            });
          }
          uploadFile(procedure.idProcedure,docType);
            
        }        
        
      }, [selectedFile])
  //generate pdf
  const addFooters = (docel:any) => {
    const pageCount = docel.internal.getNumberOfPages()
    
  
    docel.setFont('helvetica', 'italic')
    docel.setFontSize(8);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + '/logocolorh.png';
    
    for (var i = 1; i <= pageCount; i++) {
      docel.setPage(i);
      if(i==1)
      {
        docel.addImage(img, 'PNG',30,30, 140, 40, undefined,'FAST');
      }
      else{
        docel.addImage(img, 'PNG',30,30, 100, 30, undefined,'FAST');
      }
      
     
      docel.text('Redditus Capital © Todos los derechos reservados | Página ' + String(i) + ' de ' + String(pageCount), docel.internal.pageSize.width / 2, docel.internal.pageSize.height - 30, {
        align: 'center'
      })
      docel.saveGraphicsState();
      docel.setFontSize(100);
      let gstate = doc.GState({opacity: 0.1});
      
      docel.setGState(gstate);
      docel.setTextColor(200, 150, 92);
      docel.text('Redditus Capital', docel.internal.pageSize.height - 100, docel.internal.pageSize.width -400, {align: 'center', baseline: 'middle',angle: 30});
      docel.setTextColor(0, 0, 0);
      docel.restoreGraphicsState();
    }
  }

  const handleDownloadPdf = (event:any) => {
    event.preventDefault();
   
    doc = new jsPDF('landscape','pt','a4');

    autoTable(doc, ({
      styles: { overflow: "linebreak",fillColor: [255, 255, 255], fontSize: 14, fontStyle: 'bold' },
      columnStyles: { 0: { halign: 'center', fillColor: [255, 255, 255] } }, 
      margin: { top: 100 },
      body: [
        ['Cronograma']
      ],
     
    }))
    
    autoTable(doc, {
      styles: { overflow: "linebreak" },
      body: [
        ['Código de Trámite', procedure.code],
        ['Cliente',procedure.investorNames],
        ['Producto',procedure.product],
        ['Asesor', procedure.consultant],
        ['Aporte', 'USD '+  procedure.finantialContribution.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")],
        ['Cupón Neto (%) ', procedure.finantialContribution.coupon],
      ],
    });
    let bonos = [];
    for(let i=0; i<scheduleProcedure.items.length; i++)
    {
      let item = scheduleProcedure.items[i];
      bonos.push({
        datePayment: moment(item.datePayment).format("DD/MM/YYYY"),
        net: item.net.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        retention: item.retention.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        paymentCoupon: item.paymentCoupon.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      });
    }

      autoTable(doc, ({
        pageBreak: 'avoid',
        headStyles: {fillColor: [0,58,78]},
        styles: { overflow: "linebreak" },
        margin: { top: 100 },
        body: bonos,
        columnStyles: { 0: { halign: 'left' } }, // Cells in first column centered and green
        columns: [
          { header: 'Fecha de pago', dataKey: 'datePayment' },
          { header: 'Ingreso neto', dataKey: 'net' },
          { header: 'Retención IR SUNAT 5%', dataKey: 'retention' },
          { header: 'Pago de cupón', dataKey: 'paymentCoupon' }
        ],
      }));

      

      
      addFooters(doc);
      doc.save("cronograma.pdf");
  }
  // If the page is not yet generated, this will be displayed
  // initially until getStaticProps() finishes running
  if (isFetching) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <MainLayout>
  
    <ProcedureCard code={procedure.code} 
      account={procedure.account}
      investorNames={procedure.investorNames}
      consultant={procedure.consultant}
      product={procedure.product}
      updatedAt={procedure.updatedAt}
      id={procedure.idProcedure}
      />
    
        <VerticalTabs data={procedure} 
          isFetching={isFetching} 
          countries={countries}
          departments={departments}
          provinces={provinces}
          districts={districts}
          handleChange={handleChange}
          handleClose={handleClose}
          handleSave={handleSave}
          handleFileChange={handleFileChange}
          handleSend={handleSend}
          handleDownload={downloadDocument}
          percentage={percentage}
          checkUser={checkUser}
          searchClient={searchClient}
          scheduleList={scheduleList}
          handleClickShedule={handleClickShedule}
          handleChangePayment={handleChangePayment}
          handleSaveShedule={handleSaveShedule}
          handleDownloadPdf={handleDownloadPdf}
          open={open}
          scheduleProcedure={scheduleProcedure}
        />
    </MainLayout>
  )
}




export default Tramite
