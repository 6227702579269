import { api } from "../api";
import ActionType from "../utils/customTypes";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class SimulatorService extends api {

    private static classInstance?: SimulatorService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
     
    public static getInstance() {
        let token = cookies.get('stkc');
        if (!this.classInstance) {
            this.classInstance = new SimulatorService(token!==undefined?token:'', 'GET');
        }
        
        return this.classInstance;
    }   
    //#section GET
    public getProducts = () => this.instance.post('/simulator/get-products').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getSimulation = (request: any) => this.instance.post('/simulator/get-simulation', request).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    
    //#end section GET



}