import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {  toast } from 'react-toastify';
import {
    Box,
    Button,
    Typography,
    Link

} from '@mui/material';

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
  
export interface DocumentFormProps {
    data: [
            {
                name: string;
                url: string;
                idDocumentType: number;
                documentType: string;                           
            }
        ];

    paramsForm: {
        idProcedure: number;
        editable: boolean;
      };

     handleFileChange:any;
     handleDownload:any;
  }


  const DocumentsForm = (props: DocumentFormProps) => {
    const { data: documentProps , paramsForm} = props;
    const documents = documentProps;

    const isFormDisabled = () =>
        !paramsForm.editable;
    const disableForm = isFormDisabled();

    
    
  
    return (
      <>
      
                {documents.map((document:any, index:number) => {
                    return (                    
                        <Box
                            key={`box${document.idDocumentType}`}
                            sx={{
                                display: 'grid',
                                columnGap: 4,
                                rowGap: 1,
                                gridTemplateColumns: 'repeat(4, 1fr)',
                                mt: '20px',
                                mb: '20px',
                                justifyContent: 'center',
                                
                            }}
                        >
                             <Typography variant="h5" key={`tydoc${document.idDocumentType}`}>
                                {document.documentType}
                            </Typography>
                            {disableForm===false &&<Button
                                variant="outlined"
                                component="label"
                                key={`btn${document.idDocumentType}`}
                                >
                                <FileUploadOutlinedIcon/> Subir archivo
                                <input
                                    type="file"
                                    hidden
                                    onChange={props.handleFileChange}
                                    data-index ={index}
                                    data-type={document.idDocumentType}
                                    key={`input${document.idDocumentType}`}
                                />
                            </Button>  }
                            {document.url!=='' && document.url!=null ? 
                                <Button onClick={() => props.handleDownload(document.url, document.name) }>
                                    <CloudDownloadOutlinedIcon/>
                                </Button>
                             : <></>}
                            <Typography  sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                            }} key={`doc${document.idDocumentType}`}>
                                {document.name}
                            </Typography>

                            
                        </Box>
                  
                    )}
                )}
             
        
        </> 
    );
  };
  
  export default DocumentsForm;
  