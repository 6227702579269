import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';

// material-ui

import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from '../../components/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from '../../menu-items';
import { drawerWidth } from '../../store/constant';
import { SET_MENU } from '../../store/actions';
import {AuthService} from '../../business/AuthService';

// assets
import { ChevronRight } from '@mui/icons-material';

const cookies = new Cookies();

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({children}) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    
    let names=cookies.get('names');
    let role=cookies.get('rol');
    let position=cookies.get('position');
    let roles=cookies.get('roles');
    
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    const handleChangeRol = async (event,idRole) => { 
        event.preventDefault();
        console.log('hancleChangeRol is triggering')
        const authInstance =  AuthService.getInstance();
                            
        await authInstance.changeRole({idRole: idRole})
        .then((data) => {
            if(data){
                cookies.remove('stkc', { path: '/' });
                cookies.remove('rol', { path: '/' });
                cookies.remove('names', { path: '/' });
                cookies.remove('position', { path: '/' });
                cookies.remove('roles', { path: '/' });
                cookies.set('stkc', data.sessionToken, {'path': '/'});
                cookies.set('rol', data.role, {'path': '/'});
                cookies.set('names', data.names, {'path': '/'});
                cookies.set('position', data.position, {'path': '/'});
                cookies.set('roles', JSON.stringify({list: data.roles}), {'path': '/'});
                window.location.href='/redirect';
            }   
            
        })
        .catch((err) => {
            console.log(err);
        });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    

    return (
        <Box sx={{ display: 'flex', bgcolor: theme.palette.primary.dark }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.primary.dark,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} roles={roles.list} handleChangeRol={handleChangeRol}  />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} names={names} role={role} position={position} roles={roles.list} handleChangeRol={handleChangeRol}/>

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened} >
                {/* breadcrumb */}
                <Breadcrumbs separator={ChevronRight} navigation={navigation} icon title rightAlign />
                {children }
            </Main>
            
        </Box>
    );
};

export default MainLayout;
