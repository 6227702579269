import * as React from 'react';
import { useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ExportToExcel from "../../src/components/excel/ExportToExcel";
import {IconButton ,
  MenuItem,
  Menu,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Tooltip,
  Checkbox,
  CircularProgress, 
  Grid
}

from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import Link from '@mui/material/Link';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';


const StyledTableCell = styled(TableCell)(({ theme }:any) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.head}:last-of-type`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    minWidth: '20px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



interface EnhancedTableProps {
  headCells:any;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  canEdit: boolean;
  multiselect: boolean;
  hasActions: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
      { props.canEdit===true &&  props.multiselect===true && <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </StyledTableCell>
      }
        {props.headCells.map((headCell:any) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        {props.hasActions===true &&
        <StyledTableCell
            key='accionescell'
            align='center'
           
          >
           Acciones
          </StyledTableCell>}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  handleDelete: any;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{        
        minHeight:'0px!important',
        p:'0px',
        ...(numSelected > 0 && {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          pt: '10px',
          pb: '10px',
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 && (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} seleccionados
        </Typography>
      
      )}
      {numSelected > 0 && (
        <Tooltip title="Eliminar">
           <IconButton onClick={(e) => props.handleDelete()}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default function EnhancedTable(props:any) {
  const rows = props.rows;
  const heads = props.heads;
  const bodyCells = props.bodyCells;
  const identity = props.identity;
  const actions = props.actions;
  const selected = props.selected;
  const multiselect = props.multiselect;
  
  const editLink = props.editLink;
  const canEdit = props.canEdit;
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>(heads[0].id);
  
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n:any) => n[identity.id]);
      props.handleSelected(newSelecteds);
      return;
    }
    props.handleSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected,id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    props.handleSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id:number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
      setPage(0);
    }, [props.loading]);

  if (props.loading===true) {
      return (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center">
          <CircularProgress />
        </Grid>
      );
    }
   
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
      {canEdit===true && multiselect===true && <EnhancedTableToolbar numSelected={selected.length} handleDelete={props.handleDelete}/>}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              headCells={heads}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              canEdit={canEdit}
              multiselect={multiselect}
              hasActions={actions.length > 0 ? true : false}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row:any, index:number) => {
                  const isItemSelected = isSelected(row[identity.id]);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row[identity.id])}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[identity.id]}
                      selected={isItemSelected}
                    >
                      {
                        canEdit==true &&  multiselect===true && (
                          <TableCell padding="checkbox">
                            <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                          
                        </TableCell>
                       
                        )
                      }
                     
                      {bodyCells.map((bodyCell:any, index:number) => (
                        index ===0 ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          {row[bodyCell.id]}
                        </TableCell>)
                        : <TableCell align="center">{row[bodyCell.id]}</TableCell>
                        ))
                      
                    }
                    {actions.length > 0 &&
                     <TableCell
                          component="th"
                          id='acciones'
                          scope="row"
                        >
                        <Box sx={{
                              display: 'flex',
                              justifyContent:'space-between'
                          }}>

                          {actions.map((action:any, index:number) => {
                            
                             switch(action.type)
                             {
                               case 'edit': return (
                                  
                                  <Link href={editLink+row[identity.id]}>
                                    <Tooltip title={action.tooltip}>
                                    <IconButton>
                                      <EditIcon />
                                      </IconButton>
                                      </Tooltip>
                                    </Link>
                                    
                                
                               );
                               case 'view': return (
                                  
                                <Link href={editLink+row[identity.id]}>
                                  <Tooltip title={action.tooltip}>
                                  <IconButton>
                                    <SearchOutlinedIcon />
                                    </IconButton>
                                    </Tooltip>
                                  </Link>
                                  
                              
                             );
                               
                               case 'delete': return(
                                  
                                  <Tooltip title={action.tooltip}>
                                    <IconButton onClick={(e) => props.handleDelete(row[identity.id])}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>                                   
                                
                              );
                              case 'downloadContract': return(
                                  <Link href={row.contractFileUrl===null ? '#' : row.contractFileUrl}>
                                  <Tooltip title={action.tooltip}>
                                    <IconButton>
                                      <FileDownloadOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                  </Link>                                  
                                
                              );
                              case 'downloadVoucher': return(
                                <Link href={row.path===null ? '#' : row.path}>
                                <Tooltip title={action.tooltip}>
                                  <IconButton>
                                    <FileDownloadOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                                </Link>                                  
                              
                            );
                              case 'newProduct': return(
                                <>
                                <Tooltip title={action.tooltip}>
                                  <IconButton onClick={(e) => handleClickMenu(e)}
                                     aria-controls={open ? 'account-menu' : undefined}
                                     aria-haspopup="true"
                                     aria-expanded={open ? 'true' : undefined}
                                  >
                                   <AddCircleOutlineIcon />
                                  
                                        
                                    </IconButton>
                                </Tooltip>  
                                <Menu
                                anchorEl={anchorEl}
                                id="new-procedure"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    '&:before': {
                                      content: '""',
                                      display: 'block',
                                      position: 'absolute',
                                      top: 0,
                                      right: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: 'background.paper',
                                      transform: 'translateY(-50%) rotate(45deg)',
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                              >
                                {
                                  props.products.map((item:any)=>
                                  (
                                    <MenuItem onClick={() => props.handleInsert(item.idProduct, row.idAccount) }>
                                      {item.name}
                                    </MenuItem>
                                  )
                                  )
                                }
                                  
                                </Menu>   
                              
                                </>                          
                                
                              );
                             }
                            
                          })}
                         </Box>
                          
                      </TableCell>
                    }
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          
        </TableContainer>
        <Box sx={{
                display: 'flex',
                justifyContent:'space-between'
            }}>

        
        {props.export ===true && <ExportToExcel apiData={props.apiData} fileName={props.fileName} />}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Box>
      </Paper>
      
    </Box>
  );
}