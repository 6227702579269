import React from 'react';
import moment from 'moment';
import {
    TextField,
    Box,
    FormHelperText,
    Button,
    FormGroup
} from '@mui/material';
import parsers from '../../utils/parsers';
import SearchIcon from '@mui/icons-material/Search';

const genders = [
    {
        id: null,
        name: 'Seleccione',
    },
    {
      id: 'F',
      name: 'Femenino',
    },
    {
      id: 'M',
      name: 'Masculino',
    }
  ];

  const docTypes = [
    {
        id: null,
        name: 'Seleccione',
    },
    {
      id: '1',
      name: 'DNI',
    },
    {
      id: '2',
      name: 'RUC',
    }
  ];

  const languages = [
    {
        id: null,
        name: 'Seleccione',
    },
    {
      id: '1',
      name: 'Español',
    },
    {
      id: '2',
      name: 'Inglés',
    }
  ];
 
  
export interface DataInvestorFormProps {
    data: {
        idStatus:number;
        investor: {
            names: string;
            lastName: string;
            secondLastName: string;
            gender: string;
            email: string;
            idDocType: number;
            docType: string;
            docNumber: string;
            idLanguage: number;
            language: string;
            birthday: string;
            ocupation: string;
            idBirthCountry: number;
            birthCountry: string;
            nationality: string;
            cellphone: string;
            idResidenceCountry: number;
            residenceCountry: string;
            idDepartment: number;
            department: string;
            idProvince: number;
            province: string;
            idDistrict: number;
            district: string;
            city: string;
            postalCode: string;
            idJurisdictionCountry: number;
            jurisdictionCountry: string;
            statement:true;
            jurisdictions: [
                {
                code: string;
                name: string
                }
            ]
        }
        
        
    };

    paramsForm: {
        idProcedure: number;
        editable: boolean;
        checkUser : boolean;
        rol: number;
      };

    countries:[
        {
          id: number;
          name: string;
          nationality: string;
        }
      ];
    departments:[
        {
            id: number;
            name: string;
            nationality: string;
        }
    ];
    provinces:[
        {
          id: number;
          name: string;
          nationality: string;
        }
    ];
    districts:[
        {
          id: number;
          name: string;
          nationality: string;
        }
      ];
            
    handleChange: any,
    errors: any,
    searchClient: any
    
  }

  const DataInvestorForm = (props: DataInvestorFormProps) => {
    const { data: investorProps , paramsForm, countries, departments, provinces, districts} = props;
    const investor = props.data.investor;
  
    const isFormDisabled = () =>{
        console.log('checkuser', paramsForm.checkUser)
        if(paramsForm.editable!==true || paramsForm.checkUser!==true)
        {
            return true;
        };
    }
        
    const disableForm = isFormDisabled();
  

  
    return (
      <>
        <Box
            sx={{
                display: 'grid',
                columnGap: 4,
                rowGap: 1,
                gridTemplateColumns: 'repeat(4, 1fr)',
                paddingBottom: '15px'
            }}
            >
             <TextField
                select
                InputLabelProps={{ shrink: true }} 
                label="Tipo de documento*"
                name="investor.idDocType"
                value={investor.idDocType}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                disabled={!paramsForm.editable}
                onChange={props.handleChange}
                className="advance"
                >
                {docTypes.map((option:any) => (
                    <option key={option.id} value={option.id}>
                    {option.name}
                    </option>
                ))}
                </TextField>
                { props.errors && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {props.errors.idDocType}
                                </FormHelperText>
                            )}
            <TextField
                InputLabelProps={{ shrink: true }} 
                label="Número de documento*"
                name="investor.docNumber"
                value={investor.docNumber}
                size="small"
                variant="standard"
                disabled={!paramsForm.editable}
                onChange={props.handleChange}
                className="advance"
                />
                {paramsForm.rol !== 3 && props.data.idStatus !== 3 && paramsForm.editable === true && (
                    <Button
                        variant="contained"
                        key='btnsearch1'
                        onClick={props.searchClient}
                        sx={{backgroundColor: '#C8965C'}}
                        >
                        <SearchIcon/> Buscar cliente
                        
                    </Button>
                )}
            </Box>
        <Box
            sx={{
                display: 'grid',
                columnGap: 4,
                rowGap: 1,
                gridTemplateColumns: 'repeat(4, 1fr)',
            }}
            >
            <TextField
                InputLabelProps={{ shrink: true }} 
                label="Nombres*"
                name="investor.names"
                value={investor.names}
                size="small"
                variant="standard"
                disabled={disableForm}
                onChange={props.handleChange}
                className="advance"
                />
            <TextField
                InputLabelProps={{ shrink: true }} 
                label="Primer apellido*"
                name="investor.lastName"
                value={investor.lastName}
                size="small"
                variant="standard"
                disabled={disableForm}
                onChange={props.handleChange}
                className="advance"
                />
            <TextField
                InputLabelProps={{ shrink: true }} 
                label="Segundo apellido*"
                name="investor.secondLastName"
                value={investor.secondLastName}
                size="small"
                variant="standard"
                disabled={disableForm}
                onChange={props.handleChange}
                className="advance"
                />
            <TextField
                select
                InputLabelProps={{ shrink: true }} 
                label="Género"
                name="investor.gender"
                value={investor.gender}
                onChange={props.handleChange}
                disabled={disableForm}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                className="advance"
                >
                {genders.map((option:any) => (
                    <option key={option.id} value={option.id}>
                    {option.name}
                    </option>
                ))}
                </TextField>
            <TextField
                InputLabelProps={{ shrink: true }} 
                label="Email"
                name="investor.email"
                value={investor.email}
                size="small"
                variant="standard"
                disabled={disableForm}
                onChange={props.handleChange}
                className="advance"
                />
            <TextField
                select
                InputLabelProps={{ shrink: true }} 
                label="Idioma"
                name="investor.idLanguage"
                value={investor.idLanguage}
                onChange={props.handleChange}
                disabled={disableForm}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                className="advance"
                >
                {languages.map((option:any) => (
                    <option key={option.id} value={option.id}>
                    {option.name}
                    </option>
                ))}
                </TextField>
                <TextField
                    InputLabelProps={{ shrink: true }} 
                    id="birthday"
                    name="investor.birthday"
                    size="small"
                    variant="standard"
                    type="date"
                    label="Fecha de nacimiento"
                    value={parsers.formatDateAlternative(
                        moment(investor.birthday),
                    )}
                    disabled={disableForm}
                    onChange={props.handleChange}
                    className="advance"
                />
                
            <TextField
                InputLabelProps={{ shrink: true }} 
                label="Ocupación"
                name="investor.ocupation"
                value={investor.ocupation}
                size="small"
                variant="standard"
                disabled={disableForm}
                onChange={props.handleChange}
                className="advance"
            />
            <TextField
                select
                label="País de nacimiento"
                name="investor.idBirthCountry"
                value={investor.idBirthCountry}
                onChange={props.handleChange}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                disabled={disableForm}
                InputLabelProps={{ shrink: true }}
                className="advance"
                >
                {countries && countries.length > 0 ? (countries || []).map((option:any) => (
                    <option disabled={option.id===-1} key={option.id} value={option.id} data-nationality={option.nationality}>
                    {option.name}
                    </option>
                    ))
                    :
                    (<option disabled value={-1}>
                    Seleccione un país
                    </option>)
                }
                
                </TextField>
            <TextField
                InputLabelProps={{ shrink: true }} 
                label="Nacionalidad"
                name="investor.nationality"
                value={investor.nationality}
                size="small"
                variant="standard"
                onChange={props.handleChange}
                className="advance"
                disabled={disableForm}
            />
            <TextField
                InputLabelProps={{ shrink: true }} 
                label="Celular"
                name="investor.cellphone"
                value={investor.cellphone}
                size="small"
                variant="standard"
                onChange={props.handleChange}
                disabled={disableForm}
                className="advance"
            />
            <TextField
                select
                label="País de residencia"
                name="investor.idResidenceCountry"
                value={investor.idResidenceCountry}
                onChange={props.handleChange}
                disabled={disableForm}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                InputLabelProps={{ shrink: true }}
                className="advance"
                >
                {countries.map((option) => (
                    <option key={option.id} value={option.id}>
                    {option.name}
                    </option>
                ))}
                </TextField>
            <TextField
                select
                label="Departamento"
                name="investor.idDepartment"
                value={investor.idDepartment}
                onChange={props.handleChange}
                disabled={disableForm}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                InputLabelProps={{ shrink: true }}
                className="advance"
                >
                {departments.map((option) => (
                    <option key={option.id} value={option.id}>
                    {option.name}
                    </option>
                ))}
                </TextField>
                <TextField
                select
                label="Provincia"
                name="investor.idProvince"
                value={investor.idProvince}
                onChange={props.handleChange}
                disabled={disableForm}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                InputLabelProps={{ shrink: true }}
                className="advance"
                >
                {provinces.map((option) => (
                    <option key={option.id} value={option.id}>
                    {option.name}
                    </option>
                ))}
                </TextField>
            <TextField
                select
                label="Distrito"
                name="investor.idDistrict"
                value={investor.idDistrict}
                onChange={props.handleChange}
                disabled={disableForm}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                InputLabelProps={{ shrink: true }}
                className="advance"
                >
                {districts.map((option) => (
                    <option key={option.id} value={option.id}>
                    {option.name}
                    </option>
                ))}
                </TextField>
            <TextField
                InputLabelProps={{ shrink: true }} 
                label="Ciudad"
                name="investor.city"
                value={investor.city}
                size="small"
                variant="standard"
                onChange={props.handleChange}
                disabled={disableForm}
                className="advance"
            />
            <TextField
                InputLabelProps={{ shrink: true }} 
                label="Código postal"
                name="investor.postalCode"
                value={investor.postalCode}
                size="small"
                variant="standard"
                onChange={props.handleChange}
                disabled={disableForm}
                className="advance"
            />
            {/* <TextField
                select
                label="Juridicción"
                name="investor.idJurisdictionCountry"
                value={investor.idJurisdictionCountry}
                onChange={props.handleChange}
                disabled={disableForm}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                >
                {countries.map((option) => (
                    <option key={option.id} value={option.id}>
                    {option.name}
                    </option>
                ))}
                </TextField> */}
           
       </Box>
        
      </>
    );
  };
  
  export default DataInvestorForm;
  