import {useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import {
    Grid,
    LinearProgress,
    linearProgressClasses,
    Divider,
    ListItemText,
    ListItem,
    FormControlLabel,
    Checkbox,
    Button
} from '@mui/material';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import SendIcon from '@mui/icons-material/Send';
import GavelIcon from '@mui/icons-material/Gavel';

import DataInvestorForm from './DataInvestorForm';
import AddInvestorForm from './AddInvestorForm';
import FinantialForm from './FinantialForm';
import DocumentsForm from './DocumentsForm';
// styles
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 30,
    borderColor:'#000',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#fff'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main
    }
}));

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

function LinearProgressWithLabel({ value, ...others }:any) {
    const theme = useTheme();

    return (
        <Grid container direction="column" spacing={1} sx={{ mt: 1.5, padding:'15px' }} >
            <Grid item>
                <Grid container justifyContent="space-between">
                    
                    <Grid item>
                        <Typography variant="h6" color="inherit">{`${Math.round(value)}%`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <BorderLinearProgress variant="determinate" value={value} {...others} />
            </Grid>
        </Grid>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs(props:any) {
  const procedure = props.data;
  const [value, setValue] = useState(3);
  console.log(props.checkUser, 'proceduretabs')
  
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


  return (
      <>
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 'auto' }}
    >
        
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChangeTab}
        aria-label="Edición de trámite"
        sx={{ borderRight: 1, borderColor: 'divider', minWidth: '200px' }}
      >
        {procedure.actionButton===1 && <Button
                    variant="contained"
                    key='btnsend'
                    onClick={(e)=>props.handleSend(2)}
                    sx={{justifyContent: "space-between"}}
                    >
                     Enviar a revisión <SendIcon/>
                    
                </Button>  }
        {procedure.actionButton===2 && <Button
                    variant="contained"
                    key='btnsend'
                    onClick={(e)=>props.handleSend(3)}
                    sx={{justifyContent: "space-between"}}
                    >
                     Aprobar  <GavelIcon />
                    
                </Button>  }
        {procedure.actionButton===0 && <Typography
                    
                    >
                     
                    
                </Typography>  }
          <LinearProgressWithLabel value={props.percentage} />
          <Divider/>
        <Tab key='TAB1' label="Cliente" {...a11yProps(3)}/>
        <Tab key='TAB2' label="Inversión" {...a11yProps(4)} />
        <Tab key='TAB4' label="Documentos" {...a11yProps(5)} />
      </Tabs>
      <TabPanel key='TABPANEL1' value={value} index={0}></TabPanel>
      <TabPanel key='TABPANEL2' value={value} index={1}></TabPanel>
      <TabPanel key='TABPANEL3' value={value} index={2}></TabPanel>
      <TabPanel key='TABPANEL4' value={value} index={3} >
          <Box 
          sx={{
                display: 'flex',
                mt: '20px',
                justifyContent: 'space-between',
                flex: 1,
                flexWrap: 'wrap',
                flexDirection: 'row',
                alignItems:'center'
            }}
          >
                <Typography  variant="h3">Datos del cliente</Typography>
                {props.data.editable===true &&  <Button
                    variant="outlined"
                    key='btnsave1'
                    onClick={props.handleSave}
                    >
                    <SaveAsOutlinedIcon/> Guardar
                    
                </Button>  }
                
               
          </Box>
        
        <br></br>
        <Divider/>
        <br></br>
        <Typography variant="h4">
            < BadgeOutlinedIcon/> Datos personales
        </Typography>
       <br></br>
       <DataInvestorForm data={props.data} 
          paramsForm={{idProcedure: props.data.idProcedure, editable: props.data.editable, checkUser: props.checkUser, rol:props.data.rol}}
          countries={props.countries}
          departments={props.departments}
          provinces={props.provinces}
          districts={props.districts}
          handleChange={props.handleChange}
          searchClient={props.searchClient}
          errors={props.errors}
          
        />
       <br></br>
       <br></br>
        <Typography variant="h4">
            < QuizOutlinedIcon/> Información Adicional
        </Typography>
        <Typography > El motivo de esta sección es verificar la identidad del participe y el origen de la riqueza, a fin de evitar el lavado de dinero y actividades corruptas y, por lo tanto, problemas legales. Es extremadamente importante que conozca a su inversionista, comprenda la naturaleza de las actividades del mismo y las evaluaciones de riesgo sobre él.</Typography>
       <br></br>
       <AddInvestorForm data={props.data} 
        paramsForm={{idProcedure: props.data.idProcedure, editable: props.data.editable}}
        handleChange={props.handleChange}
        />
       
        <br></br>
        <Divider/>
        <br></br>
        <FormControlLabel
            control={
            <Checkbox size="small" checked={procedure.statement===undefined ? false : procedure.statement} onChange={props.handleChange} name='statement' disabled={!procedure.editable} />
            }
            label='Como asesor declaro a mi mejor saber y entender este cliente es un ciudadano respetuoso de la ley y cumple con las regulaciones locales e internacionales.'
        />
      </TabPanel>
      <TabPanel key='TABPANEL5' value={value} index={4}>
        <Box 
          sx={{
                display: 'flex',
                mt: '20px',
                justifyContent: 'space-between',
                flex: 1,
                flexWrap: 'wrap',
                flexDirection: 'row',
                alignItems:'center'
            }}
          >
                <Typography  variant="h3">Detalles de la inversión</Typography>
                {props.data.editable===true && <Button
                    variant="outlined"
                    key='btnsave2'
                    onClick={props.handleSave}
                    >
                    <SaveAsOutlinedIcon/> Guardar
                    
                </Button>  }                
          </Box>
        
        <Divider sx={{
                    mt: '20px'
                }}/>
        <Typography  sx={{
                    mt: '20px',
                    mb: '30px'
                }}>
            Información referente al aporte del participe.
        </Typography>
       
       <FinantialForm data={props.data} 
          paramsForm={{idProcedure: props.data.idProcedure, editable: props.data.editable, rol:props.data.rol}}
          handleChange={props.handleChange}
          handleClose={props.handleClose}
          scheduleList={props.scheduleList}
          handleClickShedule={props.handleClickShedule}
          handleChangePayment={props.handleChangePayment}
          handleSaveShedule={props.handleSaveShedule}
          handleDownloadPdf={props.handleDownloadPdf}
          open={props.open}
          scheduleProcedure={props.scheduleProcedure}
        />
       
      </TabPanel>
     
      <TabPanel key='TABPANEL7' value={value} index={5}>
        <Box 
          sx={{
                display: 'flex',
                mt: '20px',
                justifyContent: 'space-between',
                flex: 1,
                flexWrap: 'wrap',
                flexDirection: 'row',
                alignItems:'center'
            }}
          >
                <Typography  variant="h3">Documentos</Typography>
                {props.data.editable===true && <Button
                    variant="outlined"
                    key='btnsave1'
                    onClick={props.handleSave}
                    >
                    <SaveAsOutlinedIcon/> Guardar
                    
                </Button>  }
                
          </Box>
        <Divider sx={{
                    mt: '20px'
                }}/>
            <Typography sx={{
                    mt: '20px',
                    mb: '20px'
                }}>
                Cargar los documentos del trámite.
            </Typography>
            <DocumentsForm data={props.data.documents} paramsForm={{idProcedure: props.data.idProcedure, editable: props.data.editable}} 
              handleFileChange={props.handleFileChange}
              handleDownload={props.handleDownload}
              />
       
      </TabPanel>
      
    </Box>
    </>
  );
}