
export interface Data {
    code:string;
    investor: string;
    product: string;
    createdAt: string;
    approvalDate: string;
    quotas: number;
    annualContribution: number;
    period: string;
    consultant: string;
  }
  
 export function createData(
     code:string,
    investor: string,
    product: string,
    createdAt: string,
    approvalDate: string,
    quotas: number,
    annualContribution: number,
    period: string,
    consultant: string,
  ): Data {
    return {
        code,
      investor,
      product,
      createdAt,
      approvalDate,
      quotas,
      annualContribution,
      period,
      consultant,
    };
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  interface BodyCell {
    id: string;
  }

  interface Identity{
    id: string
  }

  interface Action {
    disable: boolean;
    type: string;
    tooltip: string;
  }
  
  export const headCells: readonly HeadCell[] = [
    {
      id: 'code',
      numeric: false,
      disablePadding: false,
      label: 'Cuenta',
    },
    {
      id: 'investor',
      numeric: false,
      disablePadding: false,
      label: 'Inversionista',
    },
    {
      id: 'product',
      numeric: false,
      disablePadding: false,
      label: 'Producto',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Fecha de creación',
    },
    {
      id: 'approvalDate',
      numeric: false,
      disablePadding: false,
      label: 'Fecha de aprobación',
    },
    {
      id: 'quotas',
      numeric: false,
      disablePadding: false,
      label: 'Cupones',
    },
    {
        id: 'annualContribution',
        numeric: false,
        disablePadding: false,
        label: 'Aporte anual USD',
    },
    {
        id: 'period',
        numeric: false,
        disablePadding: false,
        label: 'Periodo de pago',
    },
    {
        id: 'consultant',
        numeric: false,
        disablePadding: false,
        label: 'Asesor',
    }
  ];

  export const bodyCells: readonly BodyCell[] = [
    {
      id: 'code',
    },
    {
      id: 'investor',
    },
    {
      id: 'product',
    },
    {
      id: 'createdAt',
    },
    {
      id: 'approvalDate',
    },
    {
      id: 'quotas',
    },
    {
        id: 'annualContribution',
    },
    {
        id: 'period',
    },
    {
        id: 'consultant',
    }
  ];

  export const identity: Identity = 
    {
      id: 'idProcedure',
    }
  ;

  

  export const actions: readonly Action[] = [
    {
      disable: false,
      type: 'view',
      tooltip: 'Ver trámite'
    },
    {
      disable: false,
      type: 'downloadContract',
      tooltip: 'Ver contrato'
    }
  ];

  
  