import React, { PureComponent } from 'react';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer, Tooltip } from 'recharts';



const COLORS = ['#03516c'
  ,'#00C49F'
  ,'#C8965C'
  ,'#FF8042'
  ,'#1a7e9a'
  ,'#00dcce'
  ,'#ff6c67'
  ,'#ffbb43'
  ,'#d99e37'
  ,'#c33e39'
  ,'#256e87'
  ,'#9B9B9B'
  ,'#000000'
  ,'#7BAEF6'
  ,'#611F69'
  ,'#3C6298'
  ,'#a6a6a6'
  ,'#a6a6a6'
  ,'#a6a6a6'
];

const formatMoney = (amount:any) => {
    return amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }:any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
  return (
    <>
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {` ${(percent * 100).toFixed(0)}% `}
    </text>

    </>
  );
};

export default function ActiveShapePieChart(props:any) {
    const data = props.data;
    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry:any, index:any) => (
              <Cell key={`cell-${index}`} fill={COLORS[entry.idProduct-1]} name={entry.name} />
            ))}
          </Pie>
          <Tooltip formatter={(value:any) => {
                return `USD: ${formatMoney(value)}`;
            }} />
          <Legend verticalAlign="top" height={36}/>
        </PieChart>
      </ResponsiveContainer>
    );
  
}
