import React, { useState, useEffect } from 'react';
import { Typography, Button, Box } from '@mui/material';
import PeriodsBySchedule from './PeriodsBySchedule';
import { AccountService } from 'business/AccountService';
import { CertificatePDFGenerator } from './CertificatePDFGenerator';
import DescriptionIcon from '@mui/icons-material/Description';

interface CertificateComponentProps {
  idTramit: number;
}

const CertificateComponent: React.FC<CertificateComponentProps> = ({ idTramit }) => {
  const [period, setSelectedPeriod] = useState<string | null>(null);
  const [certificateData, setCertificateData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (period) {
      AccountService.getInstance().getCertificadeRetencion({ idTramit, period })
        .then(response => {
          console.log("API response:", response);
          if (response) {
            console.log("Certificate data fetched:", response);
            setCertificateData(response);
            setError(null);
          } else {
            console.error("No data in response:", response);
            setError("No se encontraron datos del certificado.");
          }
        })
        .catch(error => {
          console.error("Error al obtener datos del certificado:", error);
          setError("Hubo un error al obtener los datos del certificado.");
        });
    }
  }, [period]);

  const generateAndDownloadCertificate = () => {
    if (certificateData) {
      CertificatePDFGenerator.generateAndDownload(certificateData);
    } else {
      console.error("Certificate data is not available.");
    }
  };

  const handlePeriodSelect = (period: string) => {
    setSelectedPeriod(period);
  };

  return (
    <>
      <Typography variant="h5">
        Certificado de Retención
      </Typography>
      <br></br>
      <Box
                sx={{
                    display: 'grid',
                    columnGap: 3,
                    rowGap: 1,
                    gridTemplateColumns: 'repeat(3, 1fr)',
                }}
                >
            
        <PeriodsBySchedule
          id={idTramit}
          period={period || ''}
          onSelectPeriod={handlePeriodSelect}
        />

        <Button
          variant="outlined"
          onClick={generateAndDownloadCertificate}
          disabled={!period || !certificateData}
        >
          <DescriptionIcon /> Descargar Certificado
        </Button>
      </Box>
    </>
  );
};

export default CertificateComponent;
