
import MainLayout from "../../../layout/MainLayout";
import CustomizedTables from "../../../components/CustomizedTables";
import {identity, headCells, bodyCells, actions} from "../../../components/data/comisiones";
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Cookies from 'universal-cookie';
import Box from '@mui/material/Box';
import {CommissionService} from '../../../business/CommissionService';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { 
  Tabs,
  Tab,
  Typography
} from '@mui/material';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const StyledTableCell = styled(TableCell)(({ theme} :any ) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

const cookies = new Cookies();

const formatMoney = (amount:any) => {
  return amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Comisiones = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [xlsData, setXlsData] = useState([]);
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [commissions, setCommissions] = useState([]);
  const [historial, setHistorial] = useState([]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


    const getCommissions = async (idStatus:boolean) => { 
    
      const commissionInstance =  CommissionService.getInstance();
                          
      await commissionInstance.getMyCommissions({idStatus: idStatus})
      .then((data:any) => {
          if(data){
            data = data.map((row:any) => ({...row, 
              dischargeDate: moment(row.dischargeDate).format("DD/MM/YYYY"),
              paymentDate: moment(row.paymentDate).format("DD/MM/YYYY"),
              amount: formatMoney(row.amount),
              commission: formatMoney(row.commission)
            }));

            if(idStatus==false)
            {
                setCommissions(data);
            }
            else{
                setHistorial(data);
            }
            
          }   
          
      })
      .catch((err) => {
        console.log(err);
      });
    }
  
  useEffect(() => {
    getCommissions(false);
    getCommissions(true);
  }, []);
 

  const handleSelected = (ids: any) => {
    setSelected(ids);
  }

  const handleDelete = (ids: any) => {
    //
  }
  const handleCreateProcedure = () => {

  }

  return (
    <MainLayout>
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flexDirection:'column', whiteSpace: 'pre-line' }}
            >
            <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="maininfo"
            >
                <Tab sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}} label="Pagos Pendientes" {...a11yProps(0)}  />
                <Tab sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}} label="Historial de Pagos" {...a11yProps(1)}  />
                
            </Tabs>
            <TabPanel value={value} index={0} >
                <CustomizedTables rows={commissions} 
                    heads={headCells} 
                    bodyCells={bodyCells} 
                    identity={identity} 
                    actions={actions} 
                    editLink='' 
                    canEdit={false}
                    selected={selected}
                    handleDelete={handleDelete}
                    handleSelected={handleSelected}
                    handleInsert={handleCreateProcedure}
                    apiData={xlsData} 
                    fileName='Comisiones'
                    export={false}
                    multiselect={false}
                    loading={loading}
                    products={commissions}
                />
            </TabPanel>
            <TabPanel value={value} index={1} >
                <CustomizedTables rows={historial} 
                    heads={headCells} 
                    bodyCells={bodyCells} 
                    identity={identity} 
                    actions={actions} 
                    editLink=''
                    canEdit={false}
                    selected={selected}
                    handleDelete={handleDelete}
                    handleSelected={handleSelected}
                    handleInsert={handleCreateProcedure}
                    apiData={xlsData} 
                    fileName='Comisiones'
                    export={false}
                    multiselect={false}
                    loading={loading}
                    products={historial}
                />
            </TabPanel>
        </Box>
  
    </MainLayout>
  )
}


export default Comisiones
