enum color  {
    paper = "#ffffff",

    primaryLight = "#edede5",
    primaryMain="#04536e",
    //primaryMain = "#2196f3",
    primaryDark = "#1e88e5",
    primary200 = "#90caf9",
    primary800 = "#1565c0",

    secondaryLight = "#ede7f6",
    secondaryMain = "#1c1e66",
    //secondaryDark = "#2a2d93",
    //dorado
    secondaryDark = "#C8965C",
    secondary200 = "#b39ddb",
    secondary800 = "#4527a0",

    successLight = "#b9f6ca",
    success200 = "#69f0ae",
    successMain = "#00e676",
    successDark = "#00c853",

    errorLight = "#ef9a9a",
    errorMain = "#f44336",
    errorDark = "#c62828",

    orangeLight = "#fbe9e7",
    orangeMain = "#ffab91",
    orangeDark = "#d84315",

    warningLight = "#fff8e1",
    warningMain = "#ffe57f",
    warningDark = "#ffc107",

    grey50 =  "#fafafa",
    grey100 = "#f5f5f5",
    grey200 = "#eeeeee",
    grey300 = "#e0e0e0",
    grey500 = "#9e9e9e",
    grey600 = "#757575",
    grey700 = "#616161",
    grey900 = "#edede5",
    darkBackground = "#003A4E", // level 3
    darkPaper = "#111936", // level 4

    darkLevel1 = "#29314f", // level 1
    darkLevel2 = "#212946", // level 2

    darkPrimaryLight = "#e3f2fd",
    darkPrimaryMain = "#2196f3",
    //darkPrimaryDark = "#1a223f",
    darkPrimaryDark = "#003A4E",
    darkPrimary200 = "#90caf9",
    darkPrimary800 = "#1565c0",

    darkSecondaryLight = "#d1c4e9",
    darkSecondaryMain = "#7c4dff",
    darkSecondaryDark = "#651fff",
    darkSecondary200 = "#b39ddb",
    darkSecondary800 = "#6200ea",

    darkTextTitle = "#d7dcec",
    darkTextPrimary = "#bdc8f0",
    darkTextSecondary = "#8492c4",
}
export default color;