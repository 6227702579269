
import TextField from '@mui/material/TextField';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { 
  Button,
  Grid,
  Typography
} from '@mui/material';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import LinearProgressWithLabel from '../LinearProgressWithLabel';



const AmountForm = (props:any) => {
  

  return (
    <Grid container  sx={{ 
        backgroundColor: '#fff', padding: '15px', borderRadius: '15px',
        flexDirection: 'column',
        textAlign: 'center'}}>

          <Grid item sx={{ minHeight: '300px'}}>
            <LinearProgressWithLabel value={props.percentage} oldvalue={0} text ={props.percentage + "%"}/>
              <h1>Seleccione el monto a invertir</h1>
              <br></br>
              <p>Elija el monto de inversión inicial (en USD) y use este simulador
                para diversificar su inversión entre los diferentes bonos
                corporativos de alto rendimiento para planificar sus ingresos futuros.
              </p>
            <TextField id="outlined-basic" label="Monto (USD)" 
              variant="standard" type="number"
              onChange={props.handleChange}
              value={props.data.amount}
              name="amount"
            />
            
        </Grid>
        <Grid item>
          <Grid container  sx={{ 
          flexDirection: 'row',
          justifyContent: 'space-between'}}>
            <Typography> 
            </Typography>
            <Button variant="outlined" sx={{width: '200px'}} 
              onClick={props.handleNextStep}
              > Siguiente
                <NavigateNextOutlinedIcon/>
            </Button>
          </Grid>
        </Grid>
    </Grid>
  )
  
}


export default AmountForm
