
import MainLayout from "../../../../src/layout/MainLayout";
import { styled } from '@mui/material/styles';
import ProcedureDashCard from "../../../components/cards/ProcedureDashCard";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import {ProcedureService} from '../../../../src/business/ProcedureService';
import {AnnouncementService} from '../../../../src/business/AnnouncementService';
import React, { useState, useEffect, useCallback } from 'react';
import TextMobileStepper from '../../../../src/components/TextMobileStepper';
import moment from 'moment';
import parsers from '../../../../src/utils/parsers';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
 
} from "recharts";


import {
  Grid, 
  Typography, 
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  Tabs,
  Tab,
  Box
} from '@mui/material';

import Cookies from 'universal-cookie';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const data = [
  {
    name: "Jorge Chirinos",
    uv: 10,
    pv: 2400,
    amt: 2400
  },
  {
    name: "Carlos Pacheco",
    uv: 15,
    pv: 1398,
    amt: 2210
  },
  {
    name: "Sonia Diaz",
    uv: 10,
    pv: 9800,
    amt: 2290
  },
  {
    name: "Guillermo Aguirre",
    uv: 9,
    pv: 3908,
    amt: 2000
  },
  {
    name: "Jean Chirinos",
    uv: 8,
    pv: 4800,
    amt: 2181
  },
  {
    name: "Francisco Díaz",
    uv: 4,
    pv: 3800,
    amt: 2500
  },
  {
    name: "Estela del Valle",
    uv: 2,
    pv: 4300,
    amt: 2100
  }
];
const products =[{}];
/*const products = [
  {
    name: "Enero",
    product1: 2500,
    product2: 3000,
    product3: 1500,
    product4: 5000,
  },
  {
    name: "Febrero",
    product1: 1500,
    product2: 4000,
    product3: 2500,
    product4: 3500,
  },
  {
    name: "Marzo",
    product1: 1000,
    product2: 1200,
    product3: 4000,
    product4: 6000,
  },
  {
    name: "Abril",
    product1: 2500,
    product2: 3000,
    product3: 1500,
    product4: 5000,
  },
  {
    name: "Mayo",
    product1: 1300,
    product2: 2400,
    product3: 4000,
    product4: 6000,
  },
]*/

const formatMoney = (amount:any) => {
  if(amount!=null && amount!=undefined)
  {
    return amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  else{
      return '';
  }
  
}

const videos = [
  {
   _id: '5fd025a181e2c80897c14ae1',
   videoUrl: 'https://www.youtube.com/watch?v=KWYsOS4w53g'
  },
  {
    _id: '5fd025a181e2c80897c14ae1',
    videoUrl: 'https://www.youtube.com/watch?v=KWYsOS4w53g'
   },
   {
    _id: '5fd025a181e2c80897c14ae1',
    videoUrl: 'https://www.youtube.com/watch?v=KWYsOS4w53g'
   }
];

const procedure = {
    idProcedure: 0,
    code: 'T-000037',
    account: 'RC-000037',
    investorNames: 'Guillermo Diaz Fernandez',
    consultant: 'Jorge Chirinos Cuba',
    createdAt: null,
    updatedAt: "2022-05-14T09:16",
    product: "LINKLEASE FINANCE PLC",
    totalInvestment:30000,
    net:30000,
    quotas:360,
    endYears: 2,
    coupon: 10,
    startDate: "2022-05-14T09:16",
    document: ""
};

const rowse = [{
  id: 0,
  date: "2022-05-14T09:16",
  payment: 572.81,
  expenses: 13.03,
  retention: 27.99,
  retired: 0,
  available: 531.79
}]

const StyledTableCell = styled(TableCell)(({ theme} :any ) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const cookies = new Cookies();

const DashboardInversor = () => {
  const [opacity, setOpacity] = useState({
    product1: 1,
    product2: 1,
    product3: 1,
    product4: 1
  });
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalAvailable, setTotalAvailable] = useState(0);
  const [procedures, setProcedures] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [value, setValue] = React.useState(0);

  let nameUser=cookies.get('names');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getAnnouncements = async () => { 
    
    const announcementInstance =  AnnouncementService.getInstance();
                        
    await announcementInstance.getActiveAnnoucements()
    .then((data:any) => {
        if(data){
          setAnnouncements(data);
        }   
        
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const getMyActiveProcedures = async () => { 
    
    const tramiteInstance = ProcedureService.getInstance();
    setLoading(true);
    
                        
    await tramiteInstance.getMyActiveProcedures()
    .then((data:any) => {
        if(data){
          
          setProcedures(data);
          setLoading(false);
        }   
        
    })
    .catch((err:any) => {
      console.log(err);
      setLoading(false);
    });
  }


  useEffect(() => {
    getMyActiveProcedures();
    getAnnouncements();

  }, []);

  const handleMouseEnter = useCallback(
    (o:any) => {
      const { dataKey } = o;

      setOpacity({ ...opacity, [dataKey]: 0.5 });
    },
    [opacity, setOpacity]
  );



  
  return (
    <MainLayout> 
     
        <Grid container  >
          <Grid item xs={12} sx={{ 
                  padding: '15px'
              }}>
             <Grid container  sx={{ 
                backgroundColor: '#fff', padding: '15px', borderRadius: '15px',
                flexDirection: 'column',
                alignItems: 'space-between'}}>
              <h1>¡Bienvenido {nameUser}!</h1>
              <TextMobileStepper items={announcements} />
              
               
              </Grid>
          </Grid>

          <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flexDirection:'column', whiteSpace: 'pre-line' }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="maininfo"
      >
        {procedures.map((elm:any, index:number) => {
          return (<Tab sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}} label="" {...a11yProps(index)} icon={<img src={process.env.PUBLIC_URL + '/' + elm.image} alt={elm.name} width="200" height="auto" />} />
          )
        })}
        
      </Tabs>
      {procedures.map((procedure:any, index:number) => {
          return (
      <TabPanel value={value} index={index} >
      <Grid xs={12} sx={{ 
                padding: '15px'
            }}>
               
            <Grid container  sx={{  
                backgroundColor: '#fff', padding: '15px', flexDirection: 'column', 
                textAlign: 'center'}}>
                   <h3>Información de la cuenta</h3>
                   
              <ProcedureDashCard code={procedure.code} 
                  account={procedure.account}
                  investorNames={procedure.investor}
                  consultant={procedure.consultant}
                  product={procedure.product}
                  updatedAt={procedure.createdAt}
                  id={procedure.idProcedure}
                  totalInvestment={procedure.net}
                  net ={procedure.amount}
                  quotas ={procedure.quotas}
                  endYears ={procedure.years}
                  coupon ={procedure.annualCoupon}
                  startDate ={procedure.startAt}
                  document ={procedure.document}
                />
              <br></br>
                
                <br></br>
                <br></br>
                <TableContainer >
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Fecha</StyledTableCell>
                        <StyledTableCell align="right">Ingreso neto (USD)</StyledTableCell>
                        <StyledTableCell align="right">Retención SUNAT 5% (USD)</StyledTableCell>
                        <StyledTableCell align="right">Pago de cupón (USD)</StyledTableCell>
                        <StyledTableCell align="right">Retiro (USD)</StyledTableCell>
                        <StyledTableCell align="right">Total disponible (USD)</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {procedure.payments.map((row:any) => (
                        
                        <TableRow key={row.idTimeline}>
                          <StyledTableCell align="right">
                            {row.datePayment != null && row.datePayment != undefined ? parsers.formatDateAlternative(
                        moment(row.datePayment),
                    ) : ''}
                          </StyledTableCell>
                          <StyledTableCell align="right">{formatMoney(row.net)}</StyledTableCell>
                          <StyledTableCell align="right">{formatMoney(row.retention)} </StyledTableCell>
                          <StyledTableCell align="right">{formatMoney(row.paymentCoupon)}</StyledTableCell>
                          <StyledTableCell align="right">{row.status == 1 ? formatMoney(row.totalAvailable) : ''}</StyledTableCell>
                          <StyledTableCell align="right">{formatMoney(row.totalAvailable)}</StyledTableCell>
                        </TableRow>
                      ))}
                       <TableRow key="10">
                          <StyledTableCell align="right" colSpan={5}>Importe cupones acumulados USD</StyledTableCell>
                          <StyledTableCell align="right">{formatMoney(procedure.totalAvailable)}</StyledTableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
          </Grid>
      </TabPanel>
          )})}
     
     </Box>
     <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flexDirection:'column', whiteSpace: 'pre-line', p:'15px' }}
    >
      <br></br>
               <h3>Historial de pagos</h3>
               
              <ResponsiveContainer width="95%" height={300}>
              <AreaChart
                height={400}
                data={products}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="product1"
                  stackId="1"
                  stroke="#8884d8"
                  fill="#8884d8"
                  name="LINKLEASE FINANCE PLC"
                />
                <Area
                  type="monotone"
                  dataKey="product2"
                  stackId="1"
                  stroke="#82ca9d"
                  fill="#82ca9d"
                  name="PARDUS FIXED INCOME BOND"
                />
                
              </AreaChart>
                </ResponsiveContainer>

      
         
          
          </Box>
        </Grid>
        </MainLayout>
  )
}


export default DashboardInversor
