
import MainLayout from "../../../layout/MainLayout";
import CustomizedTables from "../../../components/CustomizedTables";
import {identity, headCells, bodyCells, actions} from "../../../components/data/asesores";
import React, { useState, useEffect } from 'react';
import {UserService} from '../../../business/UserService';
import Cookies from 'universal-cookie';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { 
  Button,
  Tooltip,
  Link 
} from '@mui/material';

const statusList = [
  {
    id: '1',
    name: 'Activos',
  },
  {
    id: '0',
    name: 'Desactivados',
  }
];

const rolesList = [
  {
    id: 1,
    name: 'Administrador',
  },
  {
    id: 2,
    name: 'Asesor',
  },
  {
    id: 3,
    name: 'Inversionista',
  }
];

const filtersInitial = {
  search:null, 
  idRole: 2, 
  idStatus: 1
}

const cookies = new Cookies();

const Usuarios = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilterStatus] = useState(filtersInitial);
  const [xlsData, setXlsData] = useState([]);
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  let link = '';
  let canEdit = false;

  let role=cookies.get('rol');
    switch(role)
    {
        case '1': link='/backoffice/usuarios/';
              canEdit = true;
            break;
        
        default:
            window.location.href='/redirect';
    }
  
  const getUsuarios = async () => { 
    const getInstance = UserService.getInstance();
    setLoading(true);
                        
    await getInstance.getUsers(filter)
    .then((data:any) => {
        if(data){
          let  clonexlsData = data.map((col:any, index:number) => { return {
            "Nro.": index + 1,
            "Código": col.code,
            "Rol": col.role,
            "Tipo de documento": col.docType,
            "Número de documento": col.docNumber,
            "Nombres": col.names,
            "Primer Apellido": col.lastName,
            "Segundo Apellido":col.secondLastName,
            "Porcentaje de comisión":col.commission,
            "Cuentas aprobadas":col.approvedAccounts,
            "Email":col.email,
            "Celular":col.cellphone,
            "Estado":col.status
          }});
          setXlsData(clonexlsData);
          setData(data);
          setLoading(false);
        }   
        
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });

  }

  useEffect(() => {
    getUsuarios();
  }, []);


  const handleSearch= (event: any) => {
    getUsuarios();
   
    
  };

  const handleSelected = (ids: any) => {
    setSelected(ids);
  }

  const handleDelete = () => {
   //
    
  };

  const handleChange = (event:any) => {
    const {name, value} = event.target
    setFilterStatus({
      ...filter,
      [name]: name==='idRole' || name==='idStatus' ? parseInt(value) : value
    });
     
   };


  return (
    <MainLayout>    
     <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>

     <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'left' }}>

      <TextField
              select
              label="Estado"
              name="idStatus"
              onChange={handleChange}
              SelectProps={{
                  native: true,
              }}
              variant="outlined"
              size="small"
              value={filter.idStatus}
              sx={{
                pl:'8px',
                pr:'8px'
              }}
              >
              {statusList.map((option:any) => (
                  <option key={option.id} value={option.id}>
                  {option.name}
                  </option>
              ))}
              </TextField>
      <TextField
              select
              label="Rol"
              name="idRole"
              value={filter.idRole}
              onChange={handleChange}
              SelectProps={{
                  native: true,
              }}
              
              variant="outlined"
              size="small"
              sx={{
                pl:'8px',
                pr:'8px'
              }}
              >
              {rolesList.map((option:any) => (
                  <option key={option.id} value={option.id}>
                  {option.name}
                  </option>
              ))}
              </TextField>

      <TextField
              label="Nro documento / Nombres"
              name="search"
              value={filter.search}
              onChange={handleChange}
              variant="outlined"
              size="small"
              sx={{
                pl:'8px',
                pr:'8px'
              }}
              >
            
              </TextField>
      <Button 
              variant="contained"
              onClick={handleSearch}
        >
          <ManageSearchIcon/>Buscar</Button>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'right' }}>

        <Tooltip title="Agregar">
            <Button variant="outlined" 
            onClick={() => window.location.href='/backoffice/usuarios/nuevo'}
           
            >
                <AddIcon /> Nuevo Usuario
            </Button>
        </Tooltip>
      </Box>
      </Box>
      
      <br></br>
      <CustomizedTables 
        rows={data} 
        heads={headCells} 
        bodyCells={bodyCells} 
        identity={identity} 
        actions={actions} 
        editLink={link} 
        canEdit={canEdit}
        selected={selected}
        handleDelete={handleDelete}
        handleSelected={handleSelected}
        apiData={xlsData} 
        fileName='Usuarios'
        export={true}
        multiselect={false}
        loading={loading}
      />
  
    </MainLayout>
  )
}

export default Usuarios
