import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
      "&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)": {
        backgroundColor: "#f9d5ab"
      },
      "& .MuiLinearProgress-colorPrimary": {
        backgroundColor: "#f9d5ab"
      },
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#C8965C",
        transition: '1s ease', transitionDelay:'1s'
      },
     
    }
  });

function LinearProgressWithLabel(props: LinearProgressProps & { value: number, oldvalue: number, text: string }) {
    const [progress, setProgress] = React.useState(props.oldvalue);
    const classes = useStyles();

    React.useEffect(() => {
        if(progress <=props.value)
        {
            setProgress(props.value);
        }
            
        
       
    
       
      }, [progress]);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate"  {...props} 
        classes={{
            root: classes.root
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{props.text}</Typography>
      </Box>
    </Box>
  );
}

export default LinearProgressWithLabel;