import { api } from "../../src/api";
import ActionType from "../../src/utils/customTypes";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class ListService extends api {

    private static classInstance?: ListService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
     
    public static getInstance() {
        let token = cookies.get('stkc');
        if (!this.classInstance) {
            this.classInstance = new ListService(token!==undefined?token:'', 'GET');
        }
        
        return this.classInstance;
    }   
     
    public getUbigeo = (data:any) => this.instance.post('/ubigeos/get-ubigeo',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });
    
}