import { api } from "../api";
import ActionType from "../utils/customTypes";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class ReportService extends api {

    private static classInstance?: ReportService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
     
    public static getInstance() {
        let token = cookies.get('stkc');
        console.log(token,'tokenranking');
        if (!this.classInstance) {
            this.classInstance = new ReportService(token!==undefined?token:'', 'GET');
        }
        
        return this.classInstance;
    }   
     
    public getRankingConsultants = (data:any) => this.instance.post('/reports/get-ranking-consultant',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getRankingProducts = (data:any) => this.instance.post('/reports/get-ranking-products',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    

}