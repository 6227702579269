
import MainLayout from "../../../layout/MainLayout";
import CustomizedTables from "../../../components/CustomizedTables";
import {identity, headCells, bodyCells, actions} from "../../../components/data/pagos";
import React, { useState, useEffect } from 'react';
import {ProcedureService} from '../../../business/ProcedureService';
import moment from 'moment';
import Cookies from 'universal-cookie';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { 
  Button,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TablePagination
} from '@mui/material';


const StyledTableCell = styled(TableCell)(({ theme} :any ) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

const filtersInitial = {
  idStatus: 0
}
const cookies = new Cookies();

const PagosInvestor = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilterStatus] = useState(filtersInitial);
  const [xlsData, setXlsData] = useState([]);
  const [selected, setSelected] = React.useState<readonly number[]>([]);

  let linkTramites = '';
  let canEdit = false;

  let role=cookies.get('rol');
    switch(role)
    {
        case '1': linkTramites='/backoffice/tramites/';
              canEdit = true;
            break;
        case '2': linkTramites='/asesores/tramites/';
              canEdit = true;
            break;
        default:
            linkTramites = '/inversor/tramites/';
            canEdit = false;
    }
  
  const getLibrary = async () => { 

    const accountInstance = ProcedureService.getInstance();
    setLoading(true);
    await accountInstance.getMyPayments(filter)
    .then((data:any) => {
        if(data){
          data = data.map((row:any) => ({...row, 
              createdAt: moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
              approvalDate: row.approvalDate !== null ? moment(row.approvalDate).format("DD/MM/YYYY HH:mm") : '',
            }))
          
          let  clonexlsData = data.map((col:any, index:number) => { return {
            "Fecha de solicitud": index + 1,
            "Monto": col.code,
            "Fecha de transferencia": col.investor
            
          }});
          setXlsData(clonexlsData);
       
          setData(data);
          setLoading(false);
        }   
        
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });

    
  }

  

  useEffect(() => {
    getLibrary();
  }, []);

  const handleDelete = () => {
    
  };

  const handleSelected = () => {
    
  };

  const handleCreateProcedure = () => {
    
  };
 


  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'left' }}>

      </Box>
      <br></br>
      <CustomizedTables rows={data} 
        heads={headCells} 
        bodyCells={bodyCells} 
        identity={identity} 
        actions={actions} 
        editLink={linkTramites} 
        canEdit={canEdit}
        selected={selected}
        handleDelete={handleDelete}
        handleSelected={handleSelected}
        handleInsert={handleCreateProcedure}
        apiData={xlsData} 
        fileName='pagos'
        export={true}
        multiselect={false}
        loading={loading}
      />
  
    </MainLayout>
  )
}


export default PagosInvestor
