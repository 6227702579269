import { api } from "../api";
import ActionType from "../utils/customTypes";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class DocumentService extends api {

    private static classInstance?: DocumentService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
     
    public static getInstance() {
        let token = cookies.get('stkc');
        if (!this.classInstance) {
            this.classInstance = new DocumentService(token!==undefined?token:'', 'GET');
        }
        
        return this.classInstance;
    }   
    
   
    public downloadDocument = (data:any) => this.instance.post('/documents/download-document',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });
    




}