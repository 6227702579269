
import MainLayout from "../../../../src/layout/MainLayout";
import CustomizedTables from "../../../../src/components/CustomizedTables";
import {identity, headCells, bodyCells, actions} from "../../../../src/components/data/cuentas";
import React, { useState, useEffect } from 'react';
import {DocumentService} from '../../../../src/business/DocumentService';
import {LibraryService} from '../../../../src/business/LibraryService';
import moment from 'moment';
import Cookies from 'universal-cookie';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { 
  Button,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  Avatar
} from '@mui/material';
import fileDownload from 'js-file-download';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const statusList = [
  {
    id: '0',
    name: 'Todas',
  },
  {
    id: '1',
    name: 'Pendientes de aporte',
  }
];

const StyledTableCell = styled(TableCell)(({ theme} :any ) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

const filtersInitial = {
  investor:null, 
  idStatus: 0,
  consultant:null, 
}

const recursos = [
  {

  }
]
const cookies = new Cookies();

const Biblioteca = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilterStatus] = useState(filtersInitial);
  const [xlsData, setXlsData] = useState([]);
  const [selected, setSelected] = React.useState<readonly number[]>([]);

  let linkTramites = '';
  let canEdit = false;

  let role=cookies.get('rol');
    switch(role)
    {
        case '1': linkTramites='/backoffice/tramites/';
              canEdit = true;
            break;
        case '2': linkTramites='/asesores/tramites/';
              canEdit = true;
            break;
        default:
            linkTramites = '/inversor/tramites/';
            canEdit = false;
    }
  
  const getLibrary = async () => { 

    const instance = LibraryService.getInstance();
    setLoading(true);
    await instance.getLibrary()
    .then((data:any) => {
        if(data){
          
          setData(data);
          setLoading(false);
        }   
        
    })
    .catch((err:any) => {
      console.log(err);
      setLoading(false);
    });

    
  }
  function base64ToFile(base64: any) {
    return fetch(base64)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], '', { type: '' }));;
    ;
  }

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  

  const downloadDocument = async (path:any, name:any) => { 

    const documentInstance = DocumentService.getInstance();
    setLoading(true);
    await documentInstance.downloadDocument({folder: path, hash:name})
    .then((response:any) => {
        base64ToFile(response).then((file) => {
            
            fileDownload(file, name);
          });
          })
    .catch((err:any) => {
      console.log(err);
      setLoading(false);
    });

    
  }

  

  useEffect(() => {
    getLibrary();
  }, []);

  const handleDownload = (path: any, filename:any) => {
    downloadDocument(path, filename)
  }


   const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  


  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'left' }}>

      </Box>
      <br></br>
     
        <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' , height: '400px'}}
      >
        <Tab sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}} label="" {...a11yProps(0)} icon={<img src={process.env.PUBLIC_URL + '/logocolorh.png'} alt="Redditus Capital" width="200" height="auto" />} />
        <Tab sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}} icon={<img src={process.env.PUBLIC_URL + '/pardus.jpg'} alt="Pardus Fixed Income Bond" width="200" height="auto" />} label="" {...a11yProps(1)} />
        <Tab sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}} icon={<img src={process.env.PUBLIC_URL + '/woodville.jpg'} alt="Woodville Ate Loan Note" width="200" height="auto" />} label="" {...a11yProps(2)} />
        <Tab sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}} icon={<img src={process.env.PUBLIC_URL + '/linklease.jpg'} alt="Linklease Finance PLC" width="200" height="auto" />} label="" {...a11yProps(3)} />
        <Tab  label="" sx={{display:'none'}} {...a11yProps(4)} />
        <Tab sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}} icon={<img src={process.env.PUBLIC_URL + '/godwin.jpg'} alt="Godwin-Series 1" width="200" height="auto" />} label="Series 1" {...a11yProps(5)} />
        <Tab sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}} icon={<img src={process.env.PUBLIC_URL + '/godwin.jpg'} alt="Godwin-Series 2" width="200" height="auto" />} label="Series 2" {...a11yProps(6)} />
        <Tab sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}} icon={<img src={process.env.PUBLIC_URL + '/ceminvest.jpg'} alt="Ceminvest" width="200" height="auto" />} label="" {...a11yProps(7)} />
        <Tab sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}} icon={<img src={process.env.PUBLIC_URL + '/propifi.jpg'} alt="Propifi" width="200" height="auto" />} label="" {...a11yProps(8)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <h1>Redditus Capital</h1>
        <TableContainer >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
              <TableRow>
                  <StyledTableCell align="left">Recurso</StyledTableCell>
                  <StyledTableCell align="center">Descargar</StyledTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {data.filter((item:any) => item.idProduct == 0 ).map((row:any) => (
                  
                  <TableRow key={row.id}>
                  <StyledTableCell align="left">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">
                      <Button onClick={() => handleDownload(row.path, row.fileName) }>
                          <CloudDownloadOutlinedIcon/>
                      </Button>
                  </StyledTableCell>
                  
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <h1>Pardus Fixed Income Bond</h1>
      <TableContainer >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
              <TableRow>
                  <StyledTableCell align="left">Recurso</StyledTableCell>
                  <StyledTableCell align="center">Descargar</StyledTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {data.filter((item:any) => item.idProduct == 1 ).map((row:any) => (
                  
                  <TableRow key={row.id}>
                  <StyledTableCell align="left">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">
                      <Button onClick={() => handleDownload(row.path, row.fileName) }>
                          <CloudDownloadOutlinedIcon/>
                      </Button>
                  </StyledTableCell>
                  
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <h1>Woodville Ate Loan Note</h1>
      <TableContainer >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
              <TableRow>
                  <StyledTableCell align="left">Recurso</StyledTableCell>
                  <StyledTableCell align="center">Descargar</StyledTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {data.filter((item:any) => item.idProduct == 2 ).map((row:any) => (
                  
                  <TableRow key={row.id}>
                  <StyledTableCell align="left">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">
                      <Button onClick={() => handleDownload(row.path, row.fileName) }>
                          <CloudDownloadOutlinedIcon/>
                      </Button>
                  </StyledTableCell>
                  
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <h1>Linklease Finance PLC</h1>
      <TableContainer >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
              <TableRow>
                  <StyledTableCell align="left">Recurso</StyledTableCell>
                  <StyledTableCell align="center">Descargar</StyledTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {data.filter((item:any) => item.idProduct == 3 ).map((row:any) => (
                  
                  <TableRow key={row.id}>
                  <StyledTableCell align="left">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">
                      <Button onClick={() => handleDownload(row.path, row.fileName) }>
                          <CloudDownloadOutlinedIcon/>
                      </Button>
                  </StyledTableCell>
                  
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
      </TabPanel>
      
      <TabPanel value={value} index={5}>
        <h1>Godwin-Series 1</h1>
      <TableContainer >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
              <TableRow>
                  <StyledTableCell align="left">Recurso</StyledTableCell>
                  <StyledTableCell align="center">Descargar</StyledTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {data.filter((item:any) => item.idProduct == 5 ).map((row:any) => (
                  
                  <TableRow key={row.id}>
                  <StyledTableCell align="left">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">
                      <Button onClick={() => handleDownload(row.path, row.fileName) }>
                          <CloudDownloadOutlinedIcon/>
                      </Button>
                  </StyledTableCell>
                  
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <h1>Godwin-Series 2</h1>
      <TableContainer >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
              <TableRow>
                  <StyledTableCell align="left">Recurso</StyledTableCell>
                  <StyledTableCell align="center">Descargar</StyledTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {data.filter((item:any) => item.idProduct == 6 ).map((row:any) => (
                  
                  <TableRow key={row.id}>
                  <StyledTableCell align="left">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">
                      <Button onClick={() => handleDownload(row.path, row.fileName) }>
                          <CloudDownloadOutlinedIcon/>
                      </Button>
                  </StyledTableCell>
                  
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <h1>Ceminvest</h1>
      <TableContainer >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
              <TableRow>
                  <StyledTableCell align="left">Recurso</StyledTableCell>
                  <StyledTableCell align="center">Descargar</StyledTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {data.filter((item:any) => item.idProduct == 7 ).map((row:any) => (
                  
                  <TableRow key={row.id}>
                  <StyledTableCell align="left">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">
                      <Button onClick={() => handleDownload(row.path, row.fileName) }>
                          <CloudDownloadOutlinedIcon/>
                      </Button>
                  </StyledTableCell>
                  
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={8}>
        <h1>Propifi</h1>
      <TableContainer >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
              <TableRow>
                  <StyledTableCell align="left">Recurso</StyledTableCell>
                  <StyledTableCell align="center">Descargar</StyledTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {data.filter((item:any) => item.idProduct == 8 ).map((row:any) => (
                  
                  <TableRow key={row.id}>
                  <StyledTableCell align="left">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">
                      <Button onClick={() => handleDownload(row.path, row.fileName) }>
                          <CloudDownloadOutlinedIcon/>
                      </Button>
                  </StyledTableCell>
                  
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
      </TabPanel>
    </Box>
  
  
    </MainLayout>
  )
}


export default Biblioteca
