import { api } from "../api";
import ActionType from "../utils/customTypes";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class ProductService extends api {

    private static classInstance?: ProductService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
     
    public static getInstance() {
        let token = cookies.get('stkc');
        if (!this.classInstance) {
            this.classInstance = new ProductService(token!==undefined?token:'', 'GET');
        }
        
        return this.classInstance;
    }   
     
    public getProductsList = () => this.instance.post('/products/get-products-list').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getProductDetails = (data:any) => this.instance.post('/products/get-product-details', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    

}