
export interface Data {
    idProcedure:number;
    code: string;
    investor: string;
    product: string;
    status: string;
    consultant: string;
    createdAt: string;
  }
  
 export function createData(
    idProcedure:number,
    code: string,
    investor: string,
    product: string,
    status: string,
    consultant: string,
    createdAt: string,
  ): Data {
    return {
      idProcedure,
      code,
      investor,
      product,
      status,
      consultant,
      createdAt,
    };
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  interface BodyCell {
    id: string;
  }

  interface Identity{
    id: string
  }

  interface Action {
    disable: boolean;
    type: string;
    tooltip: string;
  }
  
  export const headCells: readonly HeadCell[] = [
    
    {
      id: 'code',
      numeric: false,
      disablePadding: false,
      label: 'Trámite',
    },
    {
      id: 'investor',
      numeric: false,
      disablePadding: false,
      label: 'Inversionista',
    },
    {
      id: 'product',
      numeric: false,
      disablePadding: false,
      label: 'Producto',
    },   
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Estado',
    },
    {
      id: 'consultant',
      numeric: false,
      disablePadding: false,
      label: 'Asesor',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Fecha de actualización',
    },
  ];

  export const bodyCells: readonly BodyCell[] = [
    {
      id: 'code',
    },
    {
      id: 'investor',
    },
    {
      id: 'product',
    },
    {
      id: 'status',
    },
    {
      id: 'consultant',
    },
    {
      id: 'createdAt',
    },
  ];

  export const identity: Identity = 
  {
    id: 'idProcedure',
  }
;

export const actions: readonly Action[] = [
  {
    disable: false,
    type: 'view',
    tooltip: 'Ver trámite'
  }
];


  
  