import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Login from '../../src/pages/login';
import Redirect from '../../src/pages/redirect';
import Tramites from '../../src/pages/backoffice/tramites';
import Tramite from '../../src/pages/backoffice/tramites/tramite';
import Cuentas from '../../src/pages/backoffice/cuentas/index';
import CuentasInvestor from '../../src/pages/backoffice/cuentas/CuentasInvestor';
import Usuarios from '../pages/backoffice/usuarios';
import Usuario from '../pages/backoffice/usuarios/usuario';
import NuevoUsuario from '../pages/backoffice/usuarios/NuevoUsuario';
import Dashboard from '../pages/backoffice/dashboard';
import DashboardAsesor from '../pages/backoffice/dashboard/DashboardAsesor';
import Index from '../pages/index';
import SimuladorAsesor from '../pages/backoffice/simulador';
import DashboardInversor from 'pages/backoffice/dashboard/DashboardInversor';
import PagosInvestor from '../pages/backoffice/pagos/PagosInvestor';
import PagosAdmin from '../pages/backoffice/pagos/PagosAdmin';
import Biblioteca from 'pages/backoffice/biblioteca';
import Inventario from 'pages/backoffice/inventario';
import ProductoDetalle from 'pages/backoffice/inventario/productodetalle';
import Comisiones from '../../src/pages/backoffice/comisiones/index';
import ComisionesAdmin from '../../src/pages/backoffice/comisiones/ComisionesAdmin';
import Comision from '../../src/pages/backoffice/comisiones/comision';
function Roots(props: any) {
  
    
  
    return (
      <div>        
        <BrowserRouter>
        <Routes>
            <Route path="/login" element={<Login />}>
            </Route>
            
            <Route path="/backoffice/tramites" element={<Tramites />}>
            </Route>
            <Route path="/backoffice/dashboard" element={<Dashboard />}>
            </Route>
            <Route path="/backoffice/tramites/:tramitesId" element={<Tramite />}>
            </Route>
            <Route path="/redirect" element={<Redirect />}>
            </Route>
            <Route path="/backoffice/cuentas" element={<Cuentas />}>
            </Route>
            <Route path="/backoffice/usuarios" element={<Usuarios />}>
            </Route>
            <Route path="/backoffice/usuarios/nuevo" element={<NuevoUsuario />}>
            </Route>
            <Route path="/backoffice/usuarios/:usuarioId" element={<Usuario />}>
            </Route>
            <Route path="/backoffice/simulador" element={<SimuladorAsesor />}>
            </Route>
            <Route path="/backoffice/biblioteca" element={<Biblioteca />}>
            </Route>
            <Route path="/backoffice/inventario" element={<Inventario />}>
            </Route>
            <Route path="/backoffice/inventario/:productId" element={<ProductoDetalle />}>
            </Route>
            <Route path="/backoffice/retiros" element={<PagosAdmin />}>
            </Route>
            <Route path="/backoffice/retiros/nuevo" element={<NuevoUsuario />}>
            </Route>
            <Route path="/backoffice/retiros/:retiroId" element={<Usuario />}>
            </Route>
            <Route path="/backoffice/comisiones" element={<ComisionesAdmin />}>
            </Route>
            <Route path="/backoffice/comisiones/:comisionId" element={<Comision />}>
            </Route>
            

            <Route path="/asesores/tramites" element={<Tramites />}>
            </Route>
            <Route path="/asesores/tramites/:tramitesId" element={<Tramite />}>
            </Route>
            <Route path="/asesores/cuentas" element={<Cuentas />}>
            </Route>
            <Route path="/asesores/dashboard" element={<DashboardAsesor />}>
            </Route>
            <Route path="/asesores/retiros" element={<PagosInvestor />}>
            </Route>
            <Route path="/asesores/simulador" element={<SimuladorAsesor />}>
            </Route>
            <Route path="/asesores/biblioteca" element={<Biblioteca />}>
            </Route>
            <Route path="/asesores/inventario" element={<Inventario />}>
            </Route>
            <Route path="/asesores/inventario/:productId" element={<ProductoDetalle />}>
            </Route>
            <Route path="/asesores/comisiones" element={<Comisiones />}>
            </Route>
            <Route path="/inversor/cuentas" element={<CuentasInvestor />}>
            </Route>
            <Route path="/inversor/dashboard" element={<DashboardInversor />}>
            </Route>
            <Route path="/inversor/retiros" element={<PagosInvestor />}>
            </Route>
            <Route path="/inversor/cuentas/:tramitesId" element={<Tramite />}>
            </Route>
            <Route path="/inversor/inventario" element={<Inventario />}>
            </Route>
            <Route path="/inversor/inventario/:productId" element={<ProductoDetalle />}>
            </Route>
            <Route path="/" element={<Index />}>
            </Route>
           
        </Routes>
    </BrowserRouter>
      </div>
    );
  }

  export default Roots;