
import TextField from '@mui/material/TextField';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { 
  Button,
  Grid
} from '@mui/material';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import LinearProgressWithLabel from '../LinearProgressWithLabel';

const endYearsList = [
    {
      id: '1',
      name: '1 año'
    },
    {
      id: '2',
      name: '2 años'
    },
    {
        id: '3',
        name: '3 años'
    },
    {
      id: '4',
      name: '4 años'
    },
    {
      id: '5',
      name: '5 años'
    }
  ];


const TimeForm = (props:any) => {
  

  return (
    <Grid container  sx={{ 
        backgroundColor: '#fff', padding: '15px', borderRadius: '15px',
        flexDirection: 'column',
        textAlign: 'center'}}>
          <Grid item sx={{ minHeight: '300px'}}>
          <LinearProgressWithLabel value={props.percentage} oldvalue={0} text ={props.percentage + "%"}/>
            <h1>Tiempo de simulación</h1>
            <br></br>
            <p>Seleccione el tiempo de la proyección.</p>
            <TextField
                    select
                    label="Tiempo"
                    value={props.data.years}
                    name="years"
                    onChange={props.handleChange}
                    SelectProps={{
                        native: true,
                    }}
                    variant="standard"
                    size="small"
                    >
                    {endYearsList.map((option) => (
                        <option key={option.id} value={option.id}>
                        {option.name}
                        </option>
                    ))}
                    </TextField>
        </Grid>
        <Grid item>
          <Grid container  sx={{ 
          flexDirection: 'row',
          justifyContent: 'space-between'}}>
            <Button variant="outlined" sx={{width: '200px'}} 
              onClick={props.handlePrevStep}
              > 
               <NavigateBeforeOutlinedIcon/> Anterior
               
            </Button>
            <Button variant="outlined" sx={{width: '200px'}} 
              onClick={props.handleNextStep}
              > Siguiente
                <NavigateNextOutlinedIcon/>
            </Button>
          </Grid>
        </Grid>
    </Grid>
  )
  
}


export default TimeForm
