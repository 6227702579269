
import TextField from '@mui/material/TextField';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { 
  Button,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Typography,
  Link
} from '@mui/material';
import {
  ResponsiveContainer,
 
} from "recharts";
import { useCurrentPng } from "recharts-to-png";

import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import CardProduct from "../simulador/CardProduct";
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import LinearProgressWithLabel from '../LinearProgressWithLabel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ActiveShapePieChart from "../../components/ActiveShapePieChart";
import MixedBarChart from "../../components/MixedBarChart";
import ReplayIcon from '@mui/icons-material/Replay';
import ReactDOMServer from "react-dom/server";
import jsPDF, { GState } from "jspdf";
import ComponentToPrint from "./ComponentToPrint";
import autoTable from 'jspdf-autotable';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


let doc = new jsPDF('portrait','pt','a4');
doc.setFontSize(11);


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const months = ['Ene','Feb','Mar','Abr','May','Jun', 'Jul','Ago','Sep','Oct','Nov','Dic'];
const monthsComplete = ['Enero','Febrero','Marzo','Abril','Mayo','Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`year-tab-${index}`}
      aria-labelledby={`year-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `year-tab-${index}`,
    'aria-controls': `year-tabpanel-${index}`,
  };
}

function getParsedDate(strDate:string){

  if(strDate != null)
  {
    var strSplitDate = String(strDate).split(' ');
    var date = new Date(strSplitDate[0]);
    
    var dd = date.getDate();
    var mm = date.getMonth(); 
    let day = '';
    let formatDate = '';

    var yyyy = date.getFullYear();
    if (dd < 10) {
        day = '0' + dd.toString();
    }
    else{
      day = dd.toString();
    }
    
    formatDate = months[mm] + ' '+ yyyy;
    return formatDate;
  }
  return '';
  
}

function getDay(strDate:string){

  if(strDate != null)
  {
    var strSplitDate = String(strDate).split(' ');
    var date = new Date(strSplitDate[0]);
    
    var dd = date.getDate();
    let day = '';

    if (dd < 10) {
        day = '0' + dd.toString();
    }
    else{
      day = dd.toString();
    }
    
    return day;
  }
  return '';
  
}

function getParsedDateComplete(strDate:string){
  var strSplitDate = String(strDate).split(' ');
  var date = new Date(strSplitDate[0]);
  
  var dd = date.getDate();
  var mm = date.getMonth(); 
  let day = '';
  let min = date.getMinutes();
  let formatDate = '';
  let minutes = '';

  var yyyy = date.getFullYear();
  if (dd < 10) {
      day = '0' + dd.toString();
  }
  else{
    day = dd.toString();
  }

  if (min < 10) {
    minutes = '0' + min.toString();
  }
  else{
    minutes = min.toString();
  }
  
  formatDate = day+ ' de '+ monthsComplete[mm] + ' de '+ yyyy + ', ' + date.getHours() + ':' + minutes + 'hrs';
  return formatDate;
}

const ResultForm = (props:any) => {
  const data = props.data;
  
  const [getPng, { ref }] = useCurrentPng();
  const [value, setValue] = useState(0);
  

  const handleTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const formatMoney = (amount:any) => {
    return amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const addFooters = (docel:any) => {
    const pageCount = docel.internal.getNumberOfPages()
    
  
    docel.setFont('helvetica', 'italic')
    docel.setFontSize(8);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + '/logocolorh.png';
    
    for (var i = 1; i <= pageCount; i++) {
      docel.setPage(i);
      if(i==1)
      {
        docel.addImage(img, 'PNG',30,30, 140, 40, undefined,'FAST');
      }
      else{
        docel.addImage(img, 'PNG',30,30, 100, 30, undefined,'FAST');
      }
      
      docel.text('Documento generado el ' + getParsedDateComplete(data.generatedAt), docel.internal.pageSize.width - 40, 30, {
        align: 'right'
      })
      docel.text('Redditus Capital © Todos los derechos reservados | Página ' + String(i) + ' de ' + String(pageCount), docel.internal.pageSize.width / 2, docel.internal.pageSize.height - 30, {
        align: 'center'
      })
      docel.saveGraphicsState();
      docel.setFontSize(100);
      let gstate = doc.GState({opacity: 0.1});
      
      docel.setGState(gstate);
      docel.setTextColor(200, 150, 92);
      docel.text('Redditus Capital', docel.internal.pageSize.height - 100, docel.internal.pageSize.width -400, {align: 'center', baseline: 'middle',angle: 30});
      docel.setTextColor(0, 0, 0);
      docel.restoreGraphicsState();
    }
  }

  const getImg = () => {
    
      const chartSVG = document.querySelectorAll('.recharts-wrapper > svg')[1];
      const width = chartSVG.clientWidth;
      const height = chartSVG.clientHeight;
      let svgURL = new XMLSerializer().serializeToString(chartSVG);
      let svgBlob = new Blob([svgURL], { type: "image/svg+xml;charset=utf-8" });
      let URL = window.URL || window.webkitURL || window;
      let blobURL = URL.createObjectURL(svgBlob);

      let image = new Image();
      image.onload = () => {
          let canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          let context = canvas.getContext('2d');
          console.log(context, 'context')

          if(context != null)
          {
            context.drawImage(image, 0, 0, context.canvas.width, context.canvas.height);
            let png = canvas.toDataURL('data:image/png');
                    
            createPdf(png);
           
          }
          
          
      };
      image.src = blobURL;
  }

  var gasPer = data.administrativeCost;
  var ingAnuPer = data.annualIncomePer;
  var ingAnuNet = data.totalNet;
  var ingAnuBru = data.totalGross;
  var ingBrPer = data.grossPer;

  const createPdf = (img:any) => {
    
    /*props.handlePdfGenerated(1);*/
    doc = new jsPDF('landscape','pt','a4');  

    autoTable(doc, ({
      styles: { overflow: "linebreak",fillColor: [255, 255, 255], fontSize: 14, fontStyle: 'bold' },
      columnStyles: { 0: { halign: 'center', fillColor: [255, 255, 255] } }, 
      margin: { top: 180 },
      body: [
        ['Datos de la simulación']
      ],
     
    }))

    if (data.products.length > 1)
    {
      if(data.initialCommision > 0)
      {
        autoTable(doc, {
          styles: { overflow: "linebreak" },
          body: [
            ['Capital disponible', 'USD ' + data.capital.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")],
            ['Tiempo de proyección', data.projectionTime + (data.projectionTime < 2 ? ' año' : ' años')],
            ['Comisión inicial Pardus', 'USD ' + data.initialCommision.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")],
            ['Disponible para inversión', 'USD ' + data.inversionAvailable.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")],
            ['Gasto promedio', gasPer + '%'],
            ['Ingreso Anual Neto', formatMoney(ingAnuPer) + '%'],
          ],
        });
      }
      else{
        autoTable(doc, {
          styles: { overflow: "linebreak" },
          body: [
            ['Capital disponible', 'USD ' + data.capital.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")],
            ['Tiempo de proyección', data.projectionTime + (data.projectionTime < 2 ? ' año' : ' años')],
            ['Disponible para inversión', 'USD ' + data.inversionAvailable.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")],
            ['Gasto promedio', gasPer + '%'],
            ['Ingreso Anual Neto', formatMoney(ingAnuPer) + '%'],
          ],
        });
      }
      
    }
    else{
      if(data.initialCommision> 0)
      {        
        autoTable(doc, {
          styles: { overflow: "linebreak" },
          body: [
            ['Capital disponible', 'USD ' + data.capital.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")],
            ['Tiempo de proyección', data.projectionTime + ' años'],
            ['Comisión inicial Pardus', 'USD ' + data.initialCommision.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")],
            ['Disponible para inversión', 'USD ' + data.inversionAvailable.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")],
            ['Gastos', gasPer + '%'],
            ['Ingreso Anual Neto', formatMoney(ingAnuPer) + '%'],
          ],
        });
      }
      else{
        autoTable(doc, {
          styles: { overflow: "linebreak" },
          body: [
            ['Capital disponible', 'USD ' + data.capital.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")],
            ['Tiempo de proyección', data.projectionTime + ' años'],
            ['Disponible para inversión', 'USD ' + data.inversionAvailable.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")],
            ['Gastos', gasPer + '%'],
            ['Ingreso Anual Neto', formatMoney(ingAnuPer) + '%'],
          ],
        });
      }
      
    }
    
    autoTable(doc, ({
      styles: { overflow: "linebreak",fillColor: [255, 255, 255],fontSize: 9, },
      columnStyles: { 0: { halign: 'center', fillColor: [255, 255, 255] } }, 
      
      body: [
        ['* Incluye los costos administrativos y de bancos custodios internacionales.']
      ],
     
    }));
    
    
    autoTable(doc, ({
      pageBreak: 'always',
      styles: { overflow: "linebreak",fillColor: [255, 255, 255], fontSize: 14, fontStyle: 'bold' },
      columnStyles: { 0: { halign: 'center', fillColor: [255, 255, 255] } }, 
      margin: { top: 80 },
      body: [
        ['Calendario de pago']
      ],
     
    }))
    autoTable(doc, ({
      styles: { overflow: "linebreak",fillColor: [255, 255, 255],fontSize: 12, },
      columnStyles: { 0: { halign: 'center', fillColor: [255, 255, 255] } }, 
      
      body: [
        ['Calendario de pago de cupones de interés y retorno de capital cuando vence el bono.'],
      ],
     
    }));
   
    let day = getDay(props.data.generatedAt);
    for(let i=0; i < props.data.years.length; i++)
    {
      let datosCalen = props.data.calendars.filter((obj:any) => obj.year == props.data.years[i] )[0].products;
      let ene = datosCalen.filter((obj:any) => obj.ene > 0 );
      let feb = datosCalen.filter((obj:any) => obj.feb > 0 );
      let mar = datosCalen.filter((obj:any) => obj.mar > 0 );
      let abr = datosCalen.filter((obj:any) => obj.abr > 0 );       
      let may = datosCalen.filter((obj:any) => obj.may > 0 );
      let jun = datosCalen.filter((obj:any) => obj.jun > 0 );
      let jul = datosCalen.filter((obj:any) => obj.jul > 0 );
      let ago = datosCalen.filter((obj:any) => obj.ago > 0 );
      let sep = datosCalen.filter((obj:any) => obj.sep > 0 );
      let oct = datosCalen.filter((obj:any) => obj.oct > 0 );
      let nov = datosCalen.filter((obj:any) => obj.nov > 0 );
      let dic = datosCalen.filter((obj:any) => obj.dic > 0 );

      let headers = [];
      headers.push({ header: props.data.years[i], dataKey: 'product' });

      if(ene.length > 0)
      {
        headers.push({ header: day + ' de Enero', dataKey: 'ene' });
      }
      if(feb.length > 0)
      {
        headers.push({ header:  day + ' de Febrero', dataKey: 'feb' });
      }
      if(mar.length > 0)
      {
        headers.push({ header:  day + ' de Marzo', dataKey: 'mar' });
      }
      if(abr.length > 0)
      {
        headers.push({ header:  day + ' de Abril', dataKey: 'abr' });
      }
      if(may.length > 0)
      {
        headers.push({ header:  day + ' de Mayo', dataKey: 'may' });
      }
      if(jun.length > 0)
      {
        headers.push({ header:  day + ' de Junio', dataKey: 'jun' });
      }
      if(jul.length > 0)
      {
        headers.push({ header:  day + ' de Julio', dataKey: 'jul' });
      }
      if(ago.length > 0)
      {
        headers.push({ header:  day + ' de Agosto', dataKey: 'ago' });
      }
      if(sep.length > 0)
      {
        headers.push({ header:  day + ' de Septiembre', dataKey: 'sep' });
      }
      if(oct.length > 0)
      {
        headers.push({ header:  day + ' de Octubre', dataKey: 'oct' });
      }
      if(nov.length > 0)
      {
        headers.push({ header:  day + ' de Noviembre', dataKey: 'nov' });
      }
      if(dic.length > 0)
      {
        headers.push({ header:  day + ' de Diciembre', dataKey: 'dic' });
      }
      
      
      let bodyTable = [];
      
      for (let j=0; j < datosCalen.length; j++)
      {
       
        let item = datosCalen[j];
        
        bodyTable.push({ product: item.product
          , ene: item.ene > 0 ? ('USD ' + item.ene.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.eneReturn == true ? '*' : '')) : ''
          , feb: item.feb > 0 ? ('USD ' + item.feb.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.febReturn == true ? '*' : '')) : '' 
          , mar: item.mar > 0 ? ('USD ' + item.mar.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.marReturn == true ? '*' : '')) : ''
          , abr: item.abr > 0 ? ('USD ' + item.abr.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.abrReturn == true ? '*' : '')) : ''
          , may: item.may > 0 ? ('USD ' + item.may.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.mayReturn == true ? '*' : '')) : ''
          , jun: item.jun > 0 ? ('USD ' + item.jun.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.junReturn == true ? '*' : '')) : ''
          , jul: item.jul > 0 ? ('USD ' + item.jul.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.julReturn == true ? '*' : '')) : ''
          , ago: item.ago > 0 ? ('USD ' + item.ago.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.agoReturn == true ? '*' : '')) : ''
          , sep: item.sep > 0 ? ('USD ' + item.sep.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.sepReturn == true ? '*' : '')) : ''
          , oct: item.oct > 0 ? ('USD ' + item.oct.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.octReturn == true ? '*' : '')) : ''
          , nov: item.nov > 0 ? ('USD ' + item.nov.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.novReturn == true ? '*' : '')) : ''
          , dic: item.dic > 0 ? ('USD ' + item.dic.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.dicReturn == true ? '*' : '')) : ''
        });
      
    }
        
    autoTable(doc, ({
      pageBreak: 'avoid',
      headStyles: {fillColor: [0,58,78]},
      styles: { overflow: "linebreak" },
      willDrawCell: (data) => {
        var rows = data.table.body;
                 
        if ((props.includeRetention || props.includeExpenses) && data.row.index === rows.length - 3) {
          doc.setTextColor(238, 75, 43); // Aplicar color rojo a la fila -3
        }
        if ((props.includeRetention && props.includeExpenses) && (data.row.index === rows.length - 3 || data.row.index === rows.length - 4)) {
          doc.setTextColor(238, 75, 43); // Aplicar color rojo a las filas -3 y -4
        }
          if(data.column.index > 0)
          {
            doc.setFontSize(9);
          }
          
        
      },
      margin: { top: 100 },
      body: bodyTable,
      columnStyles: { 0: { halign: 'left' } }, // Cells in first column centered and green
      columns: headers
    }))
  }

  autoTable(doc, ({
    pageBreak: 'avoid',
    styles: { overflow: "linebreak", fillColor: [255, 255, 255] },
    columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
    margin: { top: 100 },
    body:[
      ['* Retorno de capital por vencimiento del bono más el último cupón de interés']
    ]

  }));
  let resume = [];
    for(let i=0; i < props.data.calendars.length; i++)
    {
      if (props.data.calendars[i].totalNet > 0)
      {
        resume.push([ "Ingreso neto "+ props.data.calendars[i].year, "USD " + formatMoney(props.data.calendars[i].totalNet) ]);
      }
      
    }
    resume.push([ "Ingreso neto Total inversión ", "USD " + formatMoney(props.data.graphic.totalNet) ]);
    resume.push([ "Devolucion del capital total ", "USD " + formatMoney(props.data.totalReturned) ]);
     
    autoTable(doc, {
      pageBreak: 'avoid',
      styles: { overflow: "linebreak" },
      headStyles: {fillColor: [0,58,78]},
      margin: { top: 100 },
      columns: [{ header: 'Resumen', dataKey: 'name' },
          { header: '', dataKey: 'value' }],
      body: resume
    });

 

      autoTable(doc, ({
        pageBreak: 'always',
        styles: { overflow: "linebreak" },
        headStyles: {fillColor: [255,255,255],  halign:'center'},
        alternateRowStyles: {fillColor: [255,255,255]},
        margin: { top: 100 },
        willDrawCell: (data) => {
          if (data.row.index === 0) {
              let pos = data.cell.getTextPos();
              
              data.row.height = 600;
              doc.addImage(img, 'PNG', 100,  pos.y+10, 650, 350, undefined,'FAST');
                
          }
          
              
        },
        body:[
          [''],
         
        ]

      }));

      let infoprod = [];
      let prodList = [];

      for (let j=0; j < data.products.length; j++)
      {
        
        let item = data.products[j];

        infoprod.push({
          name: item.name,
          anual_coupon: formatMoney(item.annualCoupon) +'%',
          inv_amount: formatMoney(item.investedAmount),
          assignment: item.assignment +'%',
          anual_income: formatMoney(item.annualGrossIncome),
          frequency: item.paymentFrequency,
          partial_coupon: (item.partialCouponPercentage).toFixed(2) +'%',
          months: item.months,
          expired: getParsedDate(item.expirationDate)
        });

        prodList.push({
          description: item.name
        });
        prodList.push({
          description: item.abstract +  (item.factsheetLink!=null && item.factsheetLink != '' ? ('\n\nFactsheet: '+ item.factsheetLink) : '')
          + (item.couponLink!=null && item.couponLink != '' ? ('\n\nCupón: '+ item.couponLink ) : '')
          + (item.brochureLink!=null && item.brochureLink != '' ? ('\n\nBrochure: '+ item.brochureLink) : '')
        });
      }

      infoprod.push({
        name: 'Monto de la Inversión Total (USD)',
        anual_coupon: '',
        inv_amount: formatMoney(data.inversionAvailable),
        assignment: formatMoney(100) +'%',
        anual_income: '',
        frequency: '',
        partial_coupon: '',
        months: '',
        expired: '',
      });

      infoprod.push({
        name: 'Ingreso Anual Bruto (USD)',
        anual_coupon: '',
        inv_amount: '',
        assignment: '',
        anual_income: formatMoney(ingAnuBru),
        frequency: formatMoney(ingBrPer) +'% pa',
        partial_coupon: '',
        months: '',
        expired: '',
      });

      infoprod.push({
        name: 'Ingreso Anual Neto (USD)',
        anual_coupon: '',
        inv_amount: '',
        assignment: '',
        anual_income: formatMoney(ingAnuNet),
        frequency: formatMoney(ingAnuPer) +'% pa',
        partial_coupon: '',
        months: '',
        expired: '',
      });

    
      autoTable(doc, ({
        pageBreak: 'avoid',
        headStyles: {fillColor: [0,58,78]},
        styles: { overflow: "linebreak" },
        margin: { top: 100 },
        body: infoprod,
        didParseCell:(data)=>{
          var rows = data.table.body;
          if((data.row.index === rows.length - 1 || data.row.index === rows.length - 2) && data.column.index === 0){
            data.row.cells.name.colSpan=3;
          }
          if(data.row.index === rows.length - 3 && data.column.index === 0){
            data.row.cells.name.colSpan=2;
          }
        },
        columnStyles: { 0: { halign: 'left' } }, // Cells in first column centered and green
        columns: [
          { header: 'Nombre del Bono Corporativo', dataKey: 'name' },
          { header: 'Cupón de Interés Anual', dataKey: 'anual_coupon' },
          { header: 'Monto Invertido (USD)', dataKey: 'inv_amount' },
          { header: 'Asignación', dataKey: 'assignment' },
          { header: 'Ingreso Bruto Anual (USD)', dataKey: 'anual_income' },
          { header: 'Frecuencia del Pago del Cupón', dataKey: 'frequency' },
          { header: 'Porcentaje del Cupón Parcial', dataKey: 'partial_coupon' },
          { header: 'Meses de pago', dataKey: 'months' },
          { header: 'Fecha del Vencimiento del Bono', dataKey: 'expired' }
        ],
      }));

      
      autoTable(doc, ({
        pageBreak: 'always',
        headStyles: {fillColor: [0,58,78],  halign:'center'},
        alternateRowStyles: {fillColor: [200,150,92], textColor: 1},
        styles: { overflow: "linebreak" },
        margin: { top: 100 },
        body: prodList,
        
        columnStyles: { 0: { halign: 'left' } }, // Cells in first column centered and green
        columns: [
          { header: 'Información Adicional de productos', dataKey: 'description' }
        ],
      }));

      autoTable(doc, ({
        margin: { top: 90 },
        pageBreak: 'avoid',
        styles: { overflow: "linebreak",fillColor: [255, 255, 255],fontSize: 9, },
        columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, 
        willDrawCell: (data) => {
          if (data.row.index === 0) {
            
            doc.setFontSize(12);
          }
        },
        body: [
          ['Aviso legal'],
          ['En ningún caso debe tratarse esta información como una recomendación de inversión, ni como asesoramiento financiero, por tanto, será el usuario el que deberá tomar sus propias decisiones, basadas en sus conocimientos y su experiencia. Los resultados y pagos de los productos aquí presentados, replican la información de cada de uno de estos y deberán ser considerados como teóricos, es decir, una simulación de los resultados que se  producirán al aplicar la información de cada producto.'],
          ['Los productos aquí presentados son emisiones de deuda privada conocidas como Bonos o notas de préstamo. Se debe tener en cuenta que las fechas de pago pueden variar y no se deben de considerar de manera exacta. Redditus Capital  NO se hace responsable de las pérdidas o ganancias que se puedan derivar del uso de la información del presente Simulador. El único y exclusivo cometido de este simulador es informar sobre las proyecciones que, de manera únicamente teórica, se han realizado al replicar la información obtenida de cada de uno de los emisiores de los Bonos o notas de préstamo. La Marca Redditus Capital está protegida por derecho de autor debidamente reglamentada por la autoridad competente. Queda prohibida todo tipo de reproducción parcial o total de la marca o los signos distintivas de esta.'],
          ['Todos los derechos reservados. Derechos de autor.'],
          
        ],
      
      })); 
      addFooters(doc);
      doc.save("simulacion.pdf");
  }
  
  const  save = async() => {

    
    
    if(props.pdfGenerated === 0 || props.pdfGenerated === 1)
    {
      //getImg();  
      const png = await getPng();  
      createPdf(png);
    }
    

    
    /*doc.html(ReactDOMServer.renderToStaticMarkup(<ComponentToPrint data={data}/>), {
      callback: () => {
        doc.save("simulacion.pdf");
      }
    });*/
  };

  return (
    <>
    
    <Grid container  sx={{ 
        backgroundColor: '#fff', padding: '15px', borderRadius: '15px',
        flexDirection: 'column',
        textAlign: 'center'}}>
           <Grid item>
          <Grid container  sx={{ 
          flexDirection: 'row',
          justifyContent: 'space-between'}}>
            <Typography> 
            </Typography>
            <Grid item>
            <Button variant="outlined" sx={{width: '130px'}} 
              onClick={props.handleRePlay}
              > 
                <ReplayIcon/> Reiniciar
            </Button>
            &nbsp;&nbsp;
            <Button variant="outlined" sx={{width: '130px'}} 
              onClick={save}
              > 
                <PictureAsPdfIcon/> Exportar
            </Button>
            </Grid>
          </Grid>
        </Grid>

        <h2>Datos de la simulación</h2>
        <br></br>
        <Grid item md={12}>
          <Grid container  sx={{ 
          backgroundColor: '#fff', padding: '15px', borderRadius: '15px',
          flexDirection: 'row',
          textAlign: 'center'}}>
        <Grid item md={6}>
        <Grid container  sx={{ 
          justifyContent: 'space-around'}}>
            <Grid  sx={{ 
                    textAlign:'center', 
                    padding: '5px', borderRadius: '15px',width: '130px'
                }}>
                  <PaidOutlinedIcon sx={{fontSize: '40px', color: '#C8965C'}}/>
                  <Typography>
                  Capital disponible
                  </Typography>
                  <Typography sx={{fontWeight:'bold',fontSize: '15px'}}>
                  USD {data.capital.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
            </Grid>
            <Grid  sx={{ 
                    textAlign:'center', 
                    padding: '5px', borderRadius: '15px',width: '130px'
                }}>
                  <AccessTimeOutlinedIcon sx={{fontSize: '40px', color: '#C8965C'}}/>
                  <Typography>
                  Tiempo de proyección
                  </Typography>
                  <Typography sx={{fontWeight:'bold',fontSize: '15px'}}>
                  {data.projectionTime} {data.projectionTime<2? 'año' : 'años'}
                  </Typography>
            </Grid>
            {data.initialCommision > 0 && (<Grid  sx={{ 
                    textAlign:'center', 
                    padding: '5px', borderRadius: '15px',width: '130px'
                }}>
                  <CurrencyExchangeOutlinedIcon sx={{fontSize: '40px', color: '#C8965C'}}/>
                  <Typography>
                  Comisión inicial Pardus
                  </Typography>
                  <Typography sx={{fontWeight:'bold',fontSize: '15px'}}>
                  USD {data.initialCommision.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
            </Grid>)}
            {data.initialCommision > 0 && (<Grid  sx={{ 
                    textAlign:'center', 
                    padding: '5px', borderRadius: '15px',width: '130px'
                }}>
                  <PriceCheckOutlinedIcon sx={{fontSize: '40px', color: '#C8965C'}}/>
                  <Typography>
                  Disponible para inversión
                  </Typography>
                  <Typography sx={{fontWeight:'bold',fontSize: '15px'}}>
                  USD {data.inversionAvailable.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
            </Grid>)}
            <Grid  sx={{ 
                    textAlign:'center', 
                    padding: '5px', borderRadius: '15px',width: '130px'
                }}>
                  <PercentOutlinedIcon sx={{fontSize: '40px', color: '#C8965C'}}/>
                  {data.products.length > 1 ? (<Typography>
                   Gasto promedio
                  </Typography>) : (<Typography>
                   Gastos
                  </Typography>)}
                  <Typography sx={{fontWeight:'bold',fontSize: '15px'}}>
                    {formatMoney(gasPer)} %                   
                  </Typography>
            </Grid>
           
            <Grid  sx={{ 
                    textAlign:'center', 
                    padding: '5px', borderRadius: '15px',width: '130px'
                }}>
                  <TimelineOutlinedIcon sx={{fontSize: '40px', color: '#C8965C'}}/>

                 <Typography>
                  Ingreso Anual Neto
                  </Typography>
                  <Typography sx={{fontWeight:'bold',fontSize: '15px'}}>
                  {formatMoney(ingAnuPer)} %
                  </Typography>
            </Grid>
         </Grid>
         </Grid>
         <Grid item md={6} sx={{minHeight: '300px'}}>
              <ActiveShapePieChart data={data.pieChart}/>
          </Grid>
         </Grid>
         </Grid>
         
       
         <Grid item md={12}>
         <Grid container  sx={{ 
            padding: '15px', borderRadius: '15px',
            flexDirection: 'column',
            textAlign: 'left', justifyContent: 'start!important', alignItems: 'center'}}>
           
             <Typography sx={{fontSize:'11px!important',textAlign: 'left!important'}}>
             * Incluye los costos administrativos y de bancos custodios internacionales.
             </Typography>
             {/* <Typography sx={{fontSize:'11px!important',textAlign: 'left!important'}}>
             ** BODYSMART tiene un costo administrativo de 3%.
             </Typography> */}
           
         </Grid>
         </Grid>

         <Grid item md={12}>
          <Divider/>
          <br></br>
          <br></br>
         <h2>Calendario de pago</h2>
         <p>Calendario de pago de cupones de interés y retorno de capital cuando vence el bono.</p>
        <br></br>
         <Tabs
          value={value}
          onChange={handleTab}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
           {props.data.years.map((year:any, index:number) => {
              return (    
                <Tab key={'TAB'+index} label={year} {...a11yProps(index)}/>
                          )}
          )}
        </Tabs>
        {props.data.years.map((year:number, index:number) => {
          return (
            <TabPanel key={'TABPANEL'+index} value={value} index={index} >

            <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} id={'my-table'+index}>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        {months.map((month:string, index:number) => (
                          <TableCell sx={{fontWeight: 'bold'}} align="center" >{month}</TableCell>
                        ))}
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                      props.data.calendars.filter((obj:any) => obj.year == year )[0].products.map((item:any, index:number) => (
                      
                        <TableRow>
                            <TableCell align="left"   sx={{fontSize: '0.775rem', color: item.color}} >{item.product}</TableCell>
                            <TableCell align="center" sx={{fontSize: '0.775rem', color: item.color}}>{item.ene > 0 ? ('USD ' + item.ene.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.eneReturn == true ? '*' : '')) : ''}</TableCell>
                            <TableCell align="center" sx={{fontSize: '0.775rem', color: item.color}}>{item.feb > 0 ? ('USD ' + item.feb.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.febReturn == true ? '*' : '')) : ''}</TableCell>
                            <TableCell align="center" sx={{fontSize: '0.775rem', color: item.color}}>{item.mar > 0 ? ('USD ' + item.mar.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.marReturn == true ? '*' : '')) : ''}</TableCell>
                            <TableCell align="center" sx={{fontSize: '0.775rem', color: item.color}}>{item.abr > 0 ? ('USD ' + item.abr.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.abrReturn == true ? '*' : '')) : ''}</TableCell>
                            <TableCell align="center" sx={{fontSize: '0.775rem', color: item.color}}>{item.may > 0 ? ('USD ' + item.may.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.mayReturn == true ? '*' : '')) : ''}</TableCell>
                            <TableCell align="center" sx={{fontSize: '0.775rem', color: item.color}}>{item.jun > 0 ? ('USD ' + item.jun.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.junReturn == true ? '*' : '')) : ''}</TableCell>
                            <TableCell align="center" sx={{fontSize: '0.775rem', color: item.color}}>{item.jul > 0 ? ('USD ' + item.jul.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.julReturn == true ? '*' : '')) : ''}</TableCell>
                            <TableCell align="center" sx={{fontSize: '0.775rem', color: item.color}}>{item.ago > 0 ? ('USD ' + item.ago.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.agoReturn == true ? '*' : '')) : ''}</TableCell>
                            <TableCell align="center" sx={{fontSize: '0.775rem', color: item.color}}>{item.sep > 0 ? ('USD ' + item.sep.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.sepReturn == true ? '*' : '')) : ''}</TableCell>
                            <TableCell align="center" sx={{fontSize: '0.775rem', color: item.color}}>{item.oct > 0 ? ('USD ' + item.oct.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.octReturn == true ? '*' : '')) : ''}</TableCell>
                            <TableCell align="center" sx={{fontSize: '0.775rem', color: item.color}}>{item.nov > 0 ? ('USD ' + item.nov.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.novReturn == true ? '*' : '')) : ''}</TableCell>
                            <TableCell align="center" sx={{fontSize: '0.775rem', color: item.color}}>{item.dic > 0 ? ('USD ' + item.dic.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (item.dicReturn == true ? '*' : '')) : ''}</TableCell>
                         </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <Typography sx={{textAlign:'left', p:'8px'}}>
                  * Retorno de capital por vencimiento del bono más el último cupón de interés
                  </Typography>
                </TableContainer>
                <Grid container>
                      <Grid item xs={12}>
                      <h4>
                      Ingreso Neto Anual {year}: USD {formatMoney(props.data.calendars.filter((obj:any) => obj.year == year )[0].totalNet)}
                      </h4>
                      <h4>
                      Devolución de la inversión: USD {formatMoney(props.data.calendars.filter((obj:any) => obj.year == year )[0].totalReturned)}
                      </h4>
                      </Grid>
                      
                </Grid>
            </TabPanel>
          )}
        )}
         </Grid>
         <Divider/>
         <Grid item xs={12} >
            <Grid container >
                <Grid item xs={12}>
                      <h4>
                      Ingreso Neto: USD {formatMoney(props.data.graphic.totalNet)}
                      </h4>
                      
                      </Grid>
                      <Grid item xs={12}>
                      <MixedBarChart data={props.data.graphic.items} reference={ref}/>
                      </Grid>
                    </Grid>
         </Grid>
          <Divider/>
         <Grid item md={12}>
          <Grid container sx={{p:'25px'}}>
            <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} >
                    <TableHead>
                      <TableRow >
                        <TableCell sx={{fontWeight: 'bold'}}>Nombre del Bono Corporativo</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>Cupón de Interés Anual</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>Monto Invertido (USD)</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>Asignación</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>Ingreso Bruto Anual (USD)</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>Frecuencia del Pago del Cupón</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>Porcentaje del Cupón Parcial</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>Meses de pago</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>Fecha del Vencimiento del Bono</TableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.products.map((item:any, index:number) => (
                      item.investedAmount > 0 && (
                        <TableRow>
                            <TableCell align="left">{item.name}</TableCell>
                            <TableCell align="left">{formatMoney(item.annualCoupon)}%</TableCell>
                            <TableCell align="left">{formatMoney(item.investedAmount)}</TableCell>
                            <TableCell align="left">{formatMoney(item.assignment)}%</TableCell>
                            <TableCell align="left">{formatMoney(item.annualGrossIncome)}</TableCell>
                            <TableCell align="left">{item.paymentFrequency}</TableCell>
                            <TableCell align="left">{(item.partialCouponPercentage).toFixed(2)}%</TableCell>
                            <TableCell align="left">{item.months}</TableCell>
                            <TableCell align="left">{getParsedDate(item.expirationDate)}</TableCell>
                          
                         </TableRow>)
                        ))}
                        <TableRow>
                            <TableCell sx={{fontWeight: 'bold'}} align="left" colSpan={2}>Monto de la Inversión Total (USD)</TableCell>
                            <TableCell align="left">{formatMoney(data.inversionAvailable)}</TableCell>
                            <TableCell align="left">{formatMoney(100)}%</TableCell>
                            <TableCell align="left" colSpan={5}></TableCell>
                          
                         </TableRow>
                         <TableRow>
                            <TableCell sx={{fontWeight: 'bold'}} align="left" colSpan={4}>Ingreso Anual Bruto (USD)	</TableCell>
      
                            <TableCell align="left">{formatMoney(ingAnuBru)}</TableCell>
                            <TableCell align="left">{formatMoney(ingBrPer)}% pa</TableCell>
                            <TableCell align="left" colSpan={3}></TableCell>
                          
                         </TableRow>
                         <TableRow>
                            <TableCell sx={{fontWeight: 'bold'}} align="left" colSpan={4}>Ingreso Anual Neto (USD)	</TableCell>
      
                            <TableCell align="left">
                              {formatMoney(ingAnuNet)}
                            </TableCell>
                            <TableCell align="left">
                              {formatMoney(ingAnuPer)} % pa
                            </TableCell>
                            <TableCell align="left" colSpan={3}></TableCell>
                          
                         </TableRow>
                         
                         
                    </TableBody>
                  </Table>
                 
                </TableContainer>
            </Grid>
         </Grid>
          <br>
          </br>
          <hr></hr>
         <Grid item md={12}>
          <Grid container sx={{p:'25px'}}>
            <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} >
                    <TableHead>
                      <TableRow >
                        <TableCell sx={{fontWeight: 'bold'}}>Información adicional</TableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.products.map((item:any, index:number) => (
                     
                        <TableRow>
                            <TableCell >
                            <Grid container  sx={{ 
                              flexDirection: 'row',
                              justifyContent: 'space-between', alignItems: 'center'}}>
                              <Grid item>
                                <img src={item.image} style={{ height: "auto", width: "150px" }} />
                              </Grid>
                              <Grid item sx={{textAlign: 'end'}}>
                              {item.factsheetLink != '' && item.factsheetLink != null 
                                && (<><Link target="_blank" href={item.factsheetLink}>FactSheet</Link>&nbsp;&nbsp;&nbsp;</>)}
                              {item.couponLink != '' && item.couponLink != null 
                                && (<><Link target="_blank" href={item.couponLink}>Cupón</Link>&nbsp;&nbsp;&nbsp;</>)}
                              {item.brochureLink != '' && item.brochureLink != null 
                                && (<Link target="_blank" href={item.brochureLink}>Brochure</Link>)}
                              </Grid>
                              <br></br>
                              </Grid>
                              {item.abstract}</TableCell>
                            
                         </TableRow>)
                        )}
                    </TableBody>
                  </Table>
                 
                </TableContainer>
            </Grid>
         </Grid>
        <br>
        </br>
        <Grid item xs={12}>
          <h4>Aviso legal</h4>
          <Typography sx={{fontSize:'11px!important',textAlign: 'justify!important'}}>
          En ningún caso debe tratarse esta información como una recomendación de inversión, ni como asesoramiento financiero, por tanto, será el usuario el que deberá tomar sus propias decisiones, basadas en sus conocimientos y su experiencia. Los resultados y pagos de los productos aquí presentados, replican la información de cada de uno de estos y deberán ser considerados como teóricos, es decir, una simulación de los resultados que se  producirán al aplicar la información de cada producto. 
          </Typography>
          <br></br>
          <Typography sx={{fontSize:'11px!important',textAlign: 'justify!important'}}>
          Los productos aquí presentados son emisiones de deuda privada conocidas como Bonos o notas de préstamo. Se debe tener en cuenta que las fechas de pago pueden variar y no se deben de considerar de manera exacta. Redditus Capital  NO se hace responsable de las pérdidas o ganancias que se puedan derivar del uso de la información del presente Simulador. El único y exclusivo cometido de este simulador es informar sobre las proyecciones que, de manera únicamente teórica, se han realizado al replicar la información obtenida de cada de uno de los emisiores de los Bonos o notas de préstamo. La Marca Redditus Capital está protegida por derecho de autor debidamente reglamentada por la autoridad competente. Queda prohibida todo tipo de reproducción parcial o total de la marca o los signos distintivas de esta.
          </Typography>
          <br></br>
          <Typography sx={{fontSize:'11px!important',textAlign: 'justify!important'}}>
          Copyright ©️ Todos los derechos reservados. Derechos de autor.
          </Typography>
        
        </Grid>
       
        
        
    </Grid>
    </>
  )
  
}


export default ResultForm
