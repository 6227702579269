import { api } from "../api";
import ActionType from "../utils/customTypes";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class AccountService extends api {

    private static classInstance?: AccountService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
     
    public static getInstance() {
        let token = cookies.get('stkc');
        if (!this.classInstance) {
            this.classInstance = new AccountService(token!==undefined?token:'', 'GET');
        }
        
        return this.classInstance;
    }   
     
    public getAccounts = (data:any) => this.instance.post('/accounts/get-accounts',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getPeriodsBySchedule = (data:any) => this.instance.post('/accounts/get-periods-by-schedule',data).then(response => {
        if (Array.isArray(response)) { // Verifica si la respuesta es un array
            return response;
        } else {
            throw new Error("Los datos de los periodos no se recibieron en el formato esperado.");
        }
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getCertificadeRetencion = (data:any) => this.instance.post('/accounts/get-certificate-withholding',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    //public getCertificadeRetencion = (data: any) => {
    //    return this.instance.post('/accounts/get-certificate-withholding', data)
    //        .then(response => {
    //            console.log("API raw response:", response); // Log completo de la respuesta
    //            if (response && response.data) {
    //                console.log("API response data:", response.data);
    //                return response.data;
    //            } else {
    //                throw new Error("No data in response");
    //            }
    //        })
    //        .catch((error: any) => {
    //            return Promise.reject(error);
    //        });
    //};

   
}