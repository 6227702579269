// assets
import { Key ,Dashboard, Assignment, Work, AssignmentInd, Paid } from '@mui/icons-material';

// constant
const icons = {
    Assignment,
    Dashboard,
    Work,
    AssignmentInd,
    Paid
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: '',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/asesores/dashboard',
            icon: icons.Dashboard,
            breadcrumbs: false
        },
        {
            id: 'tramites',
            title: 'Trámites',
            type: 'item',
            url: '/asesores/tramites',
            icon: icons.Assignment,
        },
        {
            id: 'cuentas',
            title: 'Cuentas',
            type: 'item',
            url: '/asesores/cuentas',
            icon: icons.Work,
        },
        {
            id: 'simulador',
            title: 'Simulador',
            type: 'item',
            url: '/asesores/simulador',
            icon: icons.Paid,
        },
        {
            id: 'comisiones',
            title: 'Comisiones',
            type: 'item',
            url: '/asesores/comisiones',
            icon: icons.Assignment,
        },
        {
            id: 'biblioteca',
            title: 'Biblioteca de recursos',
            type: 'item',
            url: '/asesores/biblioteca',
            icon: icons.Assignment,
        },
        {
            id: 'inventario',
            title: 'Inventario de inversiones',
            type: 'item',
            url: '/asesores/inventario',
            icon: icons.Assignment,
        }
        
    ]
};

export default pages;
