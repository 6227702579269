
import MainLayout from "../../../layout/MainLayout";
import React, { useState, useEffect } from 'react';
import {CommissionService} from '../../../business/CommissionService';
import moment from 'moment';
import parsers from '../../../utils/parsers';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    useMediaQuery,
    TextField,
    Input,
    CircularProgress, 
    
} from '@mui/material';

import { useParams } from "react-router-dom";
import {  toast } from 'react-toastify';

// third party
import * as Yup from 'yup';
import { Formik,ErrorMessage } from 'formik';

// project imports
import AnimateButton from '../../../components/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link } from '@mui/material';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import {ListService} from '../../../../src/business/ListService';

const statusList = [
  {
    id: false,
    name: 'Pendiente',
  },
  {
    id: true,
    name: 'Cancelado',
  }
];

const initialValues: any = {
    idCommission: 0,
    consultant: null,
    client: null,
    product: null,
    dischargeDate: null,
    amount: null,
    paymentDate: null,
    commission: null,
    idStatus:false
   
};


const commissionSchema = Yup.object().shape({
  idStatus: Yup.boolean().required("*Campo obligatorio").nullable(),  
  paymentDate: Yup.string().required("*Campo obligatorio").nullable(),
  });

const Comision = () => {
  const [data, setData] = useState(initialValues);
  const [isFetching, setIsFetching] = React.useState(true);

  let params = useParams();
  let id = params.comisionId;

  const getCommission = async (id:string) => { 
    const getInstance = CommissionService.getInstance();
    setIsFetching(true);
    await getInstance.getCommission({idCommission: parseInt(id)})
    .then((data:any) => {
        if(data){
          setData(data);
          setIsFetching(false);
        }   
        
    })
    .catch((err) => {
      console.log(err);
      setIsFetching(false);
    });

  }

  useEffect(() => {
    getCommission(id!==undefined ? id : '0');
  }, []);

  useEffect(() => {
    console.log(data,'dataaa revision')
  }, [data]);


  const handleChangeData= (event: any) => {
    const { name, value} = event.target;

    let val = value;
   
    if(name==='idStatus')
    {
      val = (val === 'true');
    }
    
    setData({
        ...data,
        [name]: event.target.type==='checkbox' ? event.target.checked : val
    });
    
  };

  const updateCommission = async () => {

    const updateInstance = CommissionService.getInstance();
                      
    await updateInstance.updateCommission(data)
    .then((data:any) => {
        window.location.href = '/backoffice/comisiones';   
    })
    .catch((err) => {
      
        console.log(err);   
    })
 
    
  }

  if (isFetching) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <MainLayout>    
         <Formik
                initialValues={data}
                validationSchema={commissionSchema}                 
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    setSubmitting(true);
                    updateCommission();
                                      
                        
                       
                }}
            >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched }) => (
            <>
            <form noValidate onSubmit={handleSubmit}>
         <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>

     
            <Typography  variant="h3">Edición de comisión</Typography>
            <Button
                            variant="outlined"
                            key='btnsave2'
                            type="submit"
                            disabled={isSubmitting}
                            >
                            <SaveAsOutlinedIcon/> Guardar
                            
            </Button>     
        </Box>
     <br></br>
     <Grid container
      sx={{  bgcolor: 'background.paper', display: 'flex', height: 'auto', p:'25px' }}
    >   
        <Grid item xs={12} md={6}>
            <FormControl sx={{ mb:"15px" , minWidth:'300px'}}>
                <TextField
                    InputLabelProps={{ shrink: true }} 
                    label="Asesor"
                    name="consultant"
                    value={data.consultant}
                    inputProps={{
                        native: true,
                        readOnly: true
                    }}
                    variant="standard"
                    size="small"
                    >
                    {data.consultant}
                </TextField>
            </FormControl>      
        </Grid>
        <Grid item xs={12} md={6}> 
            <FormControl sx={{ mb:"15px" , minWidth:'300px'}}>
                <TextField
                    InputLabelProps={{ shrink: true }} 
                    label="Cliente"
                    name="client"
                    value={data.client}
                    inputProps={{
                        native: true,
                        readOnly: true
                    }}
                    variant="standard"
                    size="small"
                    >
                    {data.client}
                </TextField>
            </FormControl>          
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl sx={{ mb:"15px" , minWidth:'300px'}}>
                <TextField
                    InputLabelProps={{ shrink: true }} 
                    label="Producto"
                    name="product"
                    value={data.product}
                    inputProps={{
                        native: true,
                        readOnly: true
                    }}
                    variant="standard"
                    size="small"
                    >
                    {data.client}
                </TextField>
            </FormControl>        
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.names && errors.names)} sx={{ mb:"15px" , minWidth:'300px'}}>
                            
                <TextField
                    InputLabelProps={{ shrink: true }} 
                    label="Fecha de alta"
                    name="dischargeDate"
                    value={data.dischargeDate}
                    inputProps={{
                        native: true,
                        readOnly: true
                    }}
                    variant="standard"
                    size="small"
                    >
                    {data.dischargeDate}
                    </TextField>
                   
            </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl sx={{ mb:"15px" , minWidth:'300px'}}>
                            
                <TextField
                    InputLabelProps={{ shrink: true }} 
                    label="Monto"
                    name="amount"
                    value={data.amount}
                    inputProps={{
                        native: true,
                        readOnly: true
                    }}
                    variant="standard"
                    size="small"
                    >
                    {data.amount}
                    </TextField>
            </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl sx={{ mb:"15px" , minWidth:'300px'}}>
                            
                <TextField
                    InputLabelProps={{ shrink: true }} 
                    label="Comisión"
                    name="commission"
                    value={data.commission}
                    inputProps={{
                        native: true,
                        readOnly: true
                    }}
                    variant="standard"
                    size="small"
                    
                    >
                    {data.commission}
                    </TextField>
                  
            </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.paymentDate && errors.paymentDate)} sx={{ mb:"15px" , minWidth:'300px'}}>
            
           
                <TextField
                    InputLabelProps={{ shrink: true }} 
                    id="paymentDate"
                    name="paymentDate"
                    size="small"
                    variant="standard"
                    type="date"
                    label="Fecha de pago"
                    value={parsers.formatDateAlternative(
                        moment(data.paymentDate),
                    )}
                    onChange={e => {handleChange(e); handleChangeData(e)}}
                />
              <ErrorMessage
                        component="div"
                        name="paymentDate"
                        className="invalid-feedback"
                        />
         </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
            <FormControl error={Boolean(touched.idStatus && errors.idStatus)} sx={{ mb:"15px" , minWidth:'300px'}}>
              
              <TextField
                InputLabelProps={{ shrink: true }} 
                select
                label="Estado"
                name="idStatus"
                value={data.idStatus}
                onChange={e => {handleChange(e); handleChangeData(e)}}
                SelectProps={{
                    native: true,
                }}
                variant="standard"
                size="small"
                >
                {statusList.map((option:any) => (
                    <option key={option.id} value={option.id}>
                    {option.name}
                    </option>
                ))}
                </TextField>
                <ErrorMessage
                        component="div"
                        name="idStatus"
                        className="invalid-feedback"
                        />
         </FormControl>
        </Grid>
    </Grid>
                       
                    
               
            
        </form>
        </>
         )}
        </Formik>
    </MainLayout>
  )
}

export default Comision
